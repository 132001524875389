import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import PropTypes from "prop-types";
import { Icon, Progress, Loader, Typography, Notification } from "@cea/ui-kit";

import styles from "../reports-list.module.css";
import { ON_QA_FOLDER_DOWNLOAD_PROGRESS } from "../../../../queries/qaServices";

const DownloadNotification = ({ folderId, removeActiveDownload, isActive }) => {
  const { data: downloadProgress, loading: progressLoading } = useSubscription(
    ON_QA_FOLDER_DOWNLOAD_PROGRESS,
    {
      variables: { folderId },
      shouldResubscribe: true,
    }
  );

  useEffect(() => {
    const notificationKey = `download_${folderId}`;

    const { progress, link } = downloadProgress?.onQAFolderDownloadProgress || {
      progress: 0,
      link: "",
    };

    const downloadPercentage = parseFloat(progress);

    const isDownloadComplete = downloadPercentage === 100;

    const messageNode = isDownloadComplete ? (
      <div className={styles["download-complete"]}>
        <Icon name="check_outline" size={24} color="#52c41a" />
        Ready For Download!
      </div>
    ) : (
      <div className={styles["download-progress"]}>
        <Loader />
        <Typography> Getting Files Ready.. </Typography>
      </div>
    );

    if (isActive) {
      Notification.open({
        key: notificationKey,
        message: messageNode,
        description: (
          <>
            <Progress
              percent={downloadPercentage}
              status={!isDownloadComplete ? "active" : "success"}
            />
            {isDownloadComplete && (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  removeActiveDownload(folderId);
                }}
              >
                Download Complete - Click here to download the file
              </a>
            )}
          </>
        ),
        duration: isDownloadComplete ? 0 : null,
        placement: "bottom-right",
      });
    }

    if (isDownloadComplete) {
      window.open(link, "_blank");
      removeActiveDownload(folderId);
    }
  }, [downloadProgress, progressLoading, folderId]);

  return null; // This component does not render anything itself
};

DownloadNotification.defaultProps = {
  folderId: "",
  isActive: false,
  removeActiveDownload: () => {},
};

DownloadNotification.propTypes = {
  folderId: PropTypes.string,
  removeActiveDownload: PropTypes.func,
  isActive: PropTypes.bool,
};

export default DownloadNotification;
