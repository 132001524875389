import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  Button,
  Column,
  Form,
  Icon,
  Modal,
  Notification,
  Row,
  Upload,
} from "@cea/ui-kit";

import { getNotification, imageUploader } from "../../../utils";
import { CREATE_PV_PFT_FILE } from "../../../queries/miServices";

const ReportUploadForm = ({ formInstance }) => (
  <Form
    form={formInstance}
    initialValues={{}}
    layout="vertical"
    requiredMark={true}
  >
    <Row>
      <Column span={24}>
        <Form.Item
          name="link"
          label="Upload File"
          rules={[{ required: true, message: "File is required" }]}
          valuePropName="fileList"
          getValueFromEvent={(event) => event?.fileList}
        >
          <Upload.Dragger
            name="link"
            beforeUpload={(_, __) => false}
            multiple
            listType="text"
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: (
                <Icon
                  name="delete"
                  size={14}
                  onClick={(e) => console.log(e, "custom removeIcon event")}
                />
              ),
            }}
          >
            <div className="flex items-center justify-center w-full mb-4">
              <Icon name="upload" color="var(--primary-theme)" size={24} />
            </div>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Column>
    </Row>
  </Form>
);

export const PVPFTReportUploadModal = ({
  isVisible,
  handleOk,
  handleCancel,
  parentId,
}) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [createPVPFTFile] = useMutation(CREATE_PV_PFT_FILE);

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      setLoading(false);
    }
  }, [isVisible]);

  const onFormSubmit = async () => {
    try {
      setLoading(true);

      const { link } = form.getFieldsValue();
      const uploadedFiles = [];
      let parentName = null;
      let parentIdToUse = parentId;

      for (const item of link) {
        const { newFile } = await imageUploader({ file: item });

        const { data } = await createPVPFTFile({
          variables: {
            parentId: parentIdToUse,
            link: newFile.name,
            name: item.name,
          },
          notifyOnNetworkStatusChange: true,
        });

        const { id } = data.createPVPFTFile;

        if (!parentName) {
          parentName = data.createPVPFTFile.parentName;
        }

        if (!parentIdToUse) {
          parentIdToUse = data.createCRMIFile.parentId;
        }

        uploadedFiles.push({
          link: newFile.name,
          name: item.name,
          parentId: parentIdToUse,
          id,
        });
      }

      setLoading(false);
      form.resetFields();
      handleOk(uploadedFiles, {
        parentName,
        parentId: parentIdToUse,
      });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });

      handleCancel();
    }
  };

  return (
    <Modal
      title="Upload Report"
      visible={isVisible}
      onOk={onFormSubmit}
      onCancel={handleCancel}
      centered
      width={720}
      footer={[
        <Button
          key="submit"
          loading={loading}
          type="primary"
          onClick={onFormSubmit}
          className="ml-auto"
          icon={<Icon name="plus" size={14} color="#fff" />}
        >
          Save
        </Button>,
      ]}
    >
      <ReportUploadForm formInstance={form} />
    </Modal>
  );
};

ReportUploadForm.defaultProps = {
  formInstance: {
    setFieldsValue: () => {},
  },
  summaryReport: false,
};

ReportUploadForm.propTypes = {
  formInstance: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }),
  summaryReport: PropTypes.bool,
};

PVPFTReportUploadModal.defaultProps = {
  isVisible: false,
  handleOk: () => {},
  handleCancel: () => {},
  parentId: null,
};

PVPFTReportUploadModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};

export default PVPFTReportUploadModal;
