import PropTypes from "prop-types";

const Save = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3207 9.21797L26.7877 2.68493C26.4863 2.38359 26.1167 2.16261 25.7149 2.04208V1.93359H3.21491C2.50375 1.93359 1.9292 2.50815 1.9292 3.21931V32.7907C1.9292 33.5019 2.50375 34.0765 3.21491 34.0765H32.7863C33.4975 34.0765 34.0721 33.5019 34.0721 32.7907V11.034C34.0721 10.351 33.8029 9.70011 33.3207 9.21797ZM12.8578 4.82645H23.1435V9.00502H12.8578V4.82645ZM31.1792 31.1836H4.82206V4.82645H10.2863V10.2907C10.2863 11.0019 10.8609 11.5765 11.5721 11.5765H24.4292C25.1404 11.5765 25.7149 11.0019 25.7149 10.2907V5.70234L31.1792 11.1666V31.1836ZM18.0006 15.1925C14.8064 15.1925 12.2149 17.784 12.2149 20.9782C12.2149 24.1724 14.8064 26.764 18.0006 26.764C21.1948 26.764 23.7863 24.1724 23.7863 20.9782C23.7863 17.784 21.1948 15.1925 18.0006 15.1925ZM18.0006 24.1925C16.2247 24.1925 14.7863 22.7541 14.7863 20.9782C14.7863 19.2023 16.2247 17.764 18.0006 17.764C19.7765 17.764 21.2149 19.2023 21.2149 20.9782C21.2149 22.7541 19.7765 24.1925 18.0006 24.1925Z"
      fill={fill}
    />
  </svg>
);

Save.defaultProps = {
  size: 64,
  fill: "black",
};

Save.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Save;
