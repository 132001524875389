import PropTypes from "prop-types";

const SubMenuArrowUp = ({ size = 14, fill = "none" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 4.35912L2.91451 6.94461C2.52399 7.33514 1.96997 7.41428 1.67707 7.12139C1.38418 6.82849 1.46333 6.27448 1.85385 5.88395L4.68228 3.05552C4.92557 2.81223 5.23231 2.68979 5.49985 2.70287C5.76744 2.68969 6.07432 2.81213 6.3177 3.05551L9.14613 5.88394C9.53665 6.27446 9.6158 6.82848 9.32291 7.12137C9.03001 7.41427 8.47599 7.33512 8.08547 6.9446L5.5 4.35912Z"
      fill={fill}
      fillOpacity="0.85"
    />
  </svg>
);

SubMenuArrowUp.defaultProps = {
  size: 14,
  fill: "black",
};

SubMenuArrowUp.prototype = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default SubMenuArrowUp;
