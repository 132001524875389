import React, { useState, useEffect, useCallback, useContext } from "react";
import { debounce } from "lodash-es";
import { useApolloClient } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  ManagementTable,
  Breadcrumb,
  Notification,
  Icon,
} from "@cea/ui-kit";

import { useQuery } from "../../hooks";
import Appshell from "../../components/Appshell";
import AuthContext from "../../contexts/AuthContext";
import DeleteModal from "../../components/DeleteModal";
import { getNotification } from "../../utils";
import PageSearch from "../../components/PageSearch/PageSearch";
import OrganizationContext from "../../contexts/OrganizationContext";
import {
  GET_ORGANIZATIONS,
  DELETE_ORGANIZATION,
} from "../../queries/organizations";
import { addQueryParam } from "../../utils/addQueryParams";

import styles from "./organizations.module.css";

const sortString = ({ value1, value2, key }) => {
  switch (key) {
    case "string":
      return ("" + value1).localeCompare(value2);

    case "number":
      return value1 - value2;

    default:
      return value1 - value2;
  }
};

const Organizations = () => {
  const [loading, setLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    data: [],
    total: 0,
  });
  const [paginationState, setPaginationState] = useState({
    limit: 5,
    offset: 0,
    currentPage: 1,
  });
  const [deleteModalState, setDeleteModalState] = useState({
    isOpen: false,
    variables: {},
    resource: null,
  });

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const { user } = useContext(AuthContext);
  const { updateOrganizationDetails } = useContext(OrganizationContext);

  const onDeleteOrganization = (data) => {
    if (data.deleteOrganization?.id) {
      closeModal();

      fetchOrganizations({
        offset: (paginationState.currentPage - 1) * paginationState.limit,
      });
    }
  };

  const columns = [
    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        sortString({
          value1: a.name,
          value2: b.name,
          key: "string",
        }),
      sortDirections: ["descend", "ascend"],
      render: (value, record) => (
        <Link to={`/organizations/${record.id}`}>
          <div className="text-[#000000a6] hover:text-black">{value}</div>
        </Link>
      ),
    },
    {
      title: "Created On",
      dataIndex: "createDate",
      key: "createDate",
      sorter: (a, b) =>
        sortString({
          value1: a.createDate,
          value2: b.createDate,
          key: "string",
        }),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (value, record) => (
        <div key={record.id}>
          <Link to={`/organizations/${value}`} key={record.id}>
            <div
              className="standard-flex gap-1 text-center"
              onClick={() => {
                updateOrganizationDetails(record);
              }}
            >
              View Organization
              <Icon name="arrow_lined_right" size={14} color="#1890ff" />
            </div>
          </Link>
          {user.isSuperAdmin || user.isSalesAdmin ? (
            <div
              className="text-red-500 hover:text-red-400 cursor-pointer mt-2"
              onClick={() => {
                setDeleteModalState({
                  isOpen: true,
                  variables: {
                    id: record?.id,
                  },
                  resource: "Organization",
                });
              }}
            >
              Delete
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const fetchOrganizations = async (variables = paginationState) => {
    setLoading(true);

    const currentPage = query.get("page") || 1;

    try {
      const { data } = await apolloClient.query({
        query: GET_ORGANIZATIONS,
        variables: {
          ...paginationState,
          ...variables,
          offset: (currentPage - 1) * paginationState.limit,
        },
        fetchPolicy: "network-only",
      });

      const { organizations: organizationsList, total } = data.organizations;

      setOrganizationData({ data: organizationsList, total });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateOrganizationDetails(null);
  }, []);

  useEffect(() => {
    const text = query.get("searchText") || "";

    fetchOrganizations({
      offset: (paginationState.currentPage - 1) * paginationState.limit,
      where: { name: text },
    });
  }, [paginationState.currentPage]);

  useEffect(() => {
    const currentPage = Number(query.get("page"));

    setPaginationState((prevState) => ({
      ...prevState,
      currentPage: currentPage || 1,
    }));
  }, [query.get("page")]);

  const updatePaginationState = (updatedState, currentPage) => {
    setPaginationState(updatedState);

    const text = query.get("searchText") || "";
    const { pathname, search } = location;

    const paramsObj = { page: currentPage, searchText: text };

    navigate(addQueryParam(pathname + search, paramsObj));
  };

  const onPaginationChange = (page) => {
    if (paginationState.currentPage !== page) {
      updatePaginationState(
        {
          ...paginationState,
          offset: (page - 1) * paginationState.limit,
          currentPage: page,
        },
        page
      );
    }
  };

  const debouncer = useCallback(
    debounce((searchValue) => fetchOrganizations(searchValue), 750),
    []
  );

  const onSearchChange = (e) => {
    const { value } = e.target;

    const whereClause = {
      where: {
        name: value ? value.toLowerCase() : "",
      },
    };

    const { pathname, search } = location;

    const paramsObj = {
      page: 1,
      searchText: value.toLowerCase(),
    };

    navigate(addQueryParam(pathname + search, paramsObj));

    debouncer(whereClause);
  };

  const closeModal = () => {
    setDeleteModalState({
      isOpen: false,
      variables: {},
      resource: null,
    });
  };

  return (
    <Appshell>
      <PageSearch isSearchEnabled={true} onSearchChange={onSearchChange} />

      <div className="page-main-container">
        <div className={styles.actions}>
          <Breadcrumb>
            <Breadcrumb.Item>Organizations</Breadcrumb.Item>
          </Breadcrumb>

          <Link to="/organizations/create">
            <Button
              className="gap-1"
              type="primary"
              icon={<Icon name="plus" size={14} color="#fff" />}
            >
              Create Organization
            </Button>
          </Link>
        </div>

        <ManagementTable
          loading={loading}
          columns={columns}
          data={organizationData.data}
          pageSize={paginationState.limit}
          listSize={organizationData.total}
          currentPage={paginationState.currentPage}
          onPageChange={onPaginationChange}
          bordered
          showSorterTooltip={false}
          isPaginated
        />
      </div>
      <DeleteModal
        isVisible={deleteModalState.isOpen}
        resource={deleteModalState.resource}
        variables={deleteModalState.variables}
        handleCancel={closeModal}
        handleOk={onDeleteOrganization}
        mutationName={DELETE_ORGANIZATION}
      />
    </Appshell>
  );
};

export default Organizations;

Organizations.defaultProps = {};

Organizations.propTypes = {};
