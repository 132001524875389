import React, { useState, useEffect, useCallback, useContext } from "react";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Grid, Icon } from "@cea/ui-kit";

import Logo from "../Logo";
import ThemeContext from "../../contexts/ThemeContext";
import { appshellUtils } from "../../utils";

import styles from "./sidebar.module.css";

const { Sider } = Layout;
const { Item, ItemGroup } = Menu;
const { useBreakpoint } = Grid;

const breakpointsArray = ["xxl", "xl", "lg", "md", "sm", "xs"];

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState([]);

  const { collapsed, setCollapsed } = useContext(ThemeContext);

  const location = useLocation();
  const breakpoints = useBreakpoint();

  const onCollapse = useCallback(
    () => setCollapsed((prevState) => !prevState),
    []
  );

  useEffect(() => {
    const currentBreakpoint = breakpointsArray.find(
      (size) => breakpoints[size] === true
    );

    if (currentBreakpoint === "sm" || currentBreakpoint === "xs") {
      setCollapsed(true);
    }
  }, [breakpoints]);

  useEffect(() => {
    setOpenKeys([appshellUtils.getOpenSidebarKeys(location.pathname)]);
  }, [location.pathname]);

  return (
    <Sider
      className={cx(styles.sider, {
        [styles.collapsed]: !collapsed,
      })}
      collapsible
      collapsed={collapsed}
      collapsedWidth={50}
      trigger={null}
      width={250}
    >
      <Menu
        className={styles["styled-menu"]}
        mode="inline"
        selectedKeys={[location.pathname || "/"]}
        openKeys={openKeys}
        onOpenChange={(openKeys) => {
          setOpenKeys((prevState) => [
            ...prevState,
            ...openKeys.filter(Boolean),
          ]);
        }}
      >
        <Item key="sidebar-head" className={styles["menu-item"]}>
          <div className={styles["sidebar-head"]} onClick={onCollapse}>
            {collapsed ? (
              <Icon size={18} name="menu_unfold" />
            ) : (
              <Icon size={18} name="menu_fold" />
            )}
            {!collapsed ? <Logo isFull size={105} /> : null}
          </div>
        </Item>
        <ItemGroup
          className={styles["menu-group-header"]}
          title={!collapsed ? "Overview" : ""}
        >
          <Item
            className={styles["menu-item"]}
            key="/"
            icon={collapsed ? <Icon name="dashboard" size={18} /> : null}
          >
            <Link to="/">Dashboard</Link>
          </Item>
          <Item
            className={styles["menu-item"]}
            key="/user"
            icon={collapsed ? <Icon name="user" size={18} /> : null}
          >
            <Link to="/user">Account Details</Link>
          </Item>
        </ItemGroup>
        <ItemGroup
          className={styles["menu-group-header"]}
          title={!collapsed ? "Manage" : ""}
        >
          <Item
            className={styles["menu-item"]}
            key="organizations-list"
            icon={collapsed ? <Icon name="briefcase" size={18} /> : null}
          >
            <Link to="/organizations">Organizations</Link>
          </Item>
          <Item
            className={styles["menu-item"]}
            key="service-list"
            icon={collapsed ? <Icon name="bulb" size={18} /> : null}
          >
            <Link to="/services">Services</Link>
          </Item>
        </ItemGroup>
        <ItemGroup
          className={styles["menu-group-header"]}
          title={!collapsed ? "Resources" : ""}
        >
          <Item
            className={styles["menu-item"]}
            key="/contact-team"
            icon={collapsed ? <Icon name="contact" size={18} /> : null}
          >
            <Link to="/contact-team">Contact Team</Link>
          </Item>
          <Item
            className={styles["menu-item"]}
            key="/version-changelog"
            icon={collapsed ? <Icon name="logs" size={18} /> : null}
          >
            <Link to="/version-changelog">Version Change Logs</Link>
          </Item>
        </ItemGroup>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
