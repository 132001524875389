import { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  Form,
  List,
  Modal,
  Avatar,
  Button,
  Select,
  Divider,
  Notification,
} from "@cea/ui-kit";
import { useApolloClient, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { debounce } from "lodash-es";

import {
  ADD_PROJECT_MANAGER,
  FETCH_PROJECT_MANAGERS,
  REMOVE_PROJECT_MANAGER,
} from "../../../queries/qaServices";
import { GET_CEA_MEMBERS } from "../../../queries/user";
import { colorsMap, getNotification } from "../../../utils";
import OrganizationContext from "../../../contexts/OrganizationContext";

import styles from "./pm-list-modal.module.css";

const PMListModal = ({
  isVisible,
  handleCancel,
  data,
  onAddPM,
  onRemovePM,
}) => {
  const [ceaMembers, setCeaMembers] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [form] = Form.useForm();
  const { serviceId } = useParams();
  const apolloClient = useApolloClient();
  const { service, organization } = useContext(OrganizationContext);

  const [addUser] = useMutation(ADD_PROJECT_MANAGER);
  const [removeUser] = useMutation(REMOVE_PROJECT_MANAGER);

  const fetchCeaMembers = async (variables = {}) => {
    try {
      const { data: fetchData } = await apolloClient.query({
        query: GET_CEA_MEMBERS,
        variables,
      });

      const usersAsOptions = fetchData.getCeaMembers.map((user) => ({
        value: user.id,
        label: `${user.name} - ${user.email}`,
      }));

      setCeaMembers(usersAsOptions);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const onRemove = async (serviceToPMId) => {
    try {
      setRemoveLoading(true);

      const selectedSubscription = organization.subscriptions.find(
        (item) => item.service.id === serviceId
      );

      await removeUser({
        variables: {
          id: serviceToPMId,
          service: service.id,
        },
        refetchQueries: [
          {
            query: FETCH_PROJECT_MANAGERS,
            variables: {
              where: { subscription: selectedSubscription.id },
            },
          },
        ],
      });

      onRemovePM(serviceToPMId);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setRemoveLoading(false);
    }
  };

  const onFormSubmit = async (values) => {
    try {
      setAddLoading(true);

      const selectedSubscription = organization.subscriptions.find(
        (item) => item.service.id === serviceId
      );

      const response = await addUser({
        variables: {
          user: values.user.value,
          service: service.id,
          subscription: selectedSubscription.id,
        },
      });

      const { serviceToPM } = response.data.addProjectManager;

      onAddPM(serviceToPM);

      form.resetFields();
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setAddLoading(false);
    }
  };

  const debouncer = useCallback(
    debounce((fn, searchValue) => fn(searchValue), 750),
    []
  );

  const onSearch = (value) => {
    debouncer(fetchCeaMembers, { name: value });
  };

  return (
    <Modal
      title="Project Managers"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => {
          const { user, id } = item;

          const initials = user.name.split(" ").map((item) => item[0]);

          return (
            <List.Item
              actions={[
                <div
                  key={user.id}
                  className={classnames(styles["list-remove-action"], {
                    [styles["remove-loading"]]: removeLoading,
                  })}
                  onClick={() => {
                    if (!removeLoading) {
                      onRemove(id);
                    }
                  }}
                >
                  Remove
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: colorsMap[initials[0].toLowerCase()],
                    }}
                  >
                    {initials}
                  </Avatar>
                }
                title={<div>{user.name}</div>}
                description={<div>{user.email}</div>}
              />
            </List.Item>
          );
        }}
      />
      <Divider />
      <div>
        <Form layout="inline" onFinish={onFormSubmit} form={form}>
          <Form.Item
            name="user"
            className={styles["invite-form-item-name"]}
            rules={[{ required: true, message: "Please select a user!" }]}
          >
            <Select
              placeholder="Select a user"
              labelInValue
              showSearch
              onSearch={onSearch}
              filterOption={false}
              options={ceaMembers}
            />
          </Form.Item>
          <Form.Item className={styles["invite-form-item-submit"]}>
            <Button
              htmlType="submit"
              type="link"
              className={classnames(styles["list-add-action"])}
              loading={addLoading}
              disabled={addLoading}
            >
              + Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

PMListModal.defaultProps = {
  data: [],
  isVisible: false,
  handleOk: () => {},
  handleCancel: () => {},
  onAddPM: () => {},
  onRemovePM: () => {},
};

PMListModal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  onAddPM: PropTypes.func,
  onRemovePM: PropTypes.func,
};

export default PMListModal;
