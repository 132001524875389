import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import { Loader, Notification } from "@cea/ui-kit";
import { useApolloClient, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import Appshell from "../Appshell";
import { getNotification } from "../../utils";
import { loginRequest } from "../../msalConfig";
import { callMsGraph } from "../../utils/MSGraph";
import AuthContext from "../../contexts/AuthContext";
import { FETCH_USER, O_AUTH_LOGIN } from "../../queries/user";

export const Auth = (props) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const [loadingUserInfo, setLoadingUserInfo] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const { instance: msalInstance } = useMsal();
  const [oAuthLogin] = useMutation(O_AUTH_LOGIN);

  const fetchUser = async () => {
    const userToken = localStorage.getItem("admin-token");

    const isValid = userToken
      ? await verifyToken(userToken.split(" ")[1])
      : false;

    if (isValid) {
      const userDetails = await fetchUserInfo();

      if (!userDetails) {
        navigate("/login");
      } else {
        updateUserAndToken({ user: userDetails, token: userToken });
      }
    } else {
      localStorage.removeItem("admin-token");
    }
  };

  const verifyToken = async (token) => {
    const { exp } = await jwtDecode(token, process.env.REACT_APP_JWT_SECRET);

    if (new Date().getTime() > exp * 1000) {
      return false;
    }

    return true;
  };

  const fetchUserInfo = async () => {
    try {
      setLoadingUserInfo(true);

      const { data } = await apolloClient.query({
        query: FETCH_USER,
      });

      setLoadingUserInfo(null);

      return data.me;
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
      setLoadingUserInfo(null);

      return null;
    }
  };

  const updateToken = (token) => {
    const { pathname, search } = location;

    setAuthToken(token);

    const path = pathname === "/login" ? "/" : pathname + search;

    localStorage.setItem("admin-token", authToken);
    navigate(path);
  };

  const updateUser = (user) => {
    setUser(user);
  };

  const updateUserAndToken = ({ user, token }) => {
    const { pathname, search } = location;

    localStorage.removeItem("admin-token");

    setUser(user);
    setAuthToken(token);

    const path = pathname === "/login" ? "/" : pathname + search;

    localStorage.setItem("admin-token", token);
    setLoadingUserInfo(false);
    navigate(path);
  };

  const logout = () => {
    setUser(null);
    setAuthToken("");

    localStorage.removeItem("admin-token");
    navigate("/login");
  };

  const handleLogin = async () => {
    try {
      const { accessToken } = await msalInstance.loginPopup(loginRequest);
      const userData = await callMsGraph(accessToken);
      const { displayName: name, mail: email } = userData;

      const { data } = await oAuthLogin({
        variables: { name, email: email.toLowerCase(), accessToken },
        notifyOnNetworkStatusChange: true,
      });

      const { user, token } = data.oAuthLogin;

      updateUserAndToken({ user, token });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({ message });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  if (loadingUserInfo) {
    return (
      <Appshell>
        <div className="p-8">
          <Loader tip="Loading" />
        </div>
      </Appshell>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
        user,
        updateToken,
        updateUser,
        loadingUserInfo,
        updateUserAndToken,
        logout,
        handleLogin,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

Auth.defaultProps = {
  children: null,
  location: {},
};

Auth.propTypes = {
  children: PropTypes.node,
};

export default Auth;
