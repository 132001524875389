import PropTypes from "prop-types";

import styles from "./date-label.module.css";

const DateLabel = ({ date, username }) => (
  <div className={styles["item-container"]}>
    {date || null} <br />
    {username ? `By ${username}` : null}
  </div>
);

DateLabel.defaultProps = {
  date: null,
  username: null,
};

DateLabel.propTypes = {
  date: PropTypes.string,
  username: PropTypes.string,
};

export default DateLabel;
