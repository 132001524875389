import PropTypes from "prop-types";

const ArrowUp = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3046 19.3465L18.9694 3.97818C18.8488 3.83911 18.6998 3.72759 18.5324 3.65115C18.365 3.57471 18.1831 3.53516 17.9991 3.53516C17.815 3.53516 17.6331 3.57471 17.4657 3.65115C17.2983 3.72759 17.1493 3.83911 17.0287 3.97818L3.69749 19.3465C3.6572 19.3928 3.63108 19.4497 3.62224 19.5105C3.61339 19.5712 3.6222 19.6332 3.6476 19.6891C3.673 19.745 3.71393 19.7924 3.76552 19.8257C3.81711 19.859 3.87717 19.8767 3.93856 19.8768H7.19302C7.37784 19.8768 7.55463 19.7965 7.67918 19.6559L16.4743 9.5188V32.1434C16.4743 32.3201 16.6189 32.4648 16.7957 32.4648H19.2064C19.3832 32.4648 19.5278 32.3201 19.5278 32.1434V9.5188L28.3229 19.6559C28.4435 19.7965 28.6203 19.8768 28.8091 19.8768H32.0636C32.3368 19.8768 32.4854 19.5554 32.3046 19.3465Z"
      fill={fill}
    />
  </svg>
);

ArrowUp.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowUp.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowUp;
