import PropTypes from "prop-types";

const Dashboard = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0717 3.21484H3.85746C3.50389 3.21484 3.2146 3.50413 3.2146 3.8577V16.072C3.2146 16.4256 3.50389 16.7148 3.85746 16.7148H16.0717C16.4253 16.7148 16.7146 16.4256 16.7146 16.072V3.8577C16.7146 3.50413 16.4253 3.21484 16.0717 3.21484ZM13.9825 13.9827H5.94674V5.94699H13.9825V13.9827ZM32.1432 3.21484H19.9289C19.5753 3.21484 19.286 3.50413 19.286 3.8577V16.072C19.286 16.4256 19.5753 16.7148 19.9289 16.7148H32.1432C32.4967 16.7148 32.786 16.4256 32.786 16.072V3.8577C32.786 3.50413 32.4967 3.21484 32.1432 3.21484ZM30.0539 13.9827H22.0182V5.94699H30.0539V13.9827ZM16.0717 19.2863H3.85746C3.50389 19.2863 3.2146 19.5756 3.2146 19.9291V32.1434C3.2146 32.497 3.50389 32.7863 3.85746 32.7863H16.0717C16.4253 32.7863 16.7146 32.497 16.7146 32.1434V19.9291C16.7146 19.5756 16.4253 19.2863 16.0717 19.2863ZM13.9825 30.0541H5.94674V22.0184H13.9825V30.0541ZM32.1432 19.2863H19.9289C19.5753 19.2863 19.286 19.5756 19.286 19.9291V32.1434C19.286 32.497 19.5753 32.7863 19.9289 32.7863H32.1432C32.4967 32.7863 32.786 32.497 32.786 32.1434V19.9291C32.786 19.5756 32.4967 19.2863 32.1432 19.2863ZM30.0539 30.0541H22.0182V22.0184H30.0539V30.0541Z"
      fill={fill}
    />
  </svg>
);

Dashboard.defaultProps = {
  size: 64,
  fill: "black",
};

Dashboard.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Dashboard;
