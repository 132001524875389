import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import useBlocker from "./useBlocker";

export default function useCallbackPrompt(when) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      const nextFullLocation =
        nextLocation.location.pathname + nextLocation.location.search;
      const currentFullLocation = location.pathname + location.search;

      if (!confirmedNavigation && nextFullLocation !== currentFullLocation) {
        setShowPrompt(true);
        setLastLocation(nextLocation);

        return false;
      }

      return true;
    },
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname + lastLocation.location?.search);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
