import PropTypes from "prop-types";

const ArrowRight = ({ size = 64, fill = "none" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3441 17.0266L17.1646 3.85201C17.0481 3.75156 16.8994 3.69531 16.7427 3.69531H13.1869C12.8896 3.69531 12.753 4.06496 12.978 4.25781L27.0485 16.4721H3.53603C3.35924 16.4721 3.2146 16.6167 3.2146 16.7935V19.2042C3.2146 19.381 3.35924 19.5257 3.53603 19.5257H27.0445L12.974 31.74C12.749 31.9368 12.8856 32.3025 13.1829 32.3025H16.8592C16.9356 32.3025 17.0119 32.2743 17.0682 32.2221L32.3441 18.9712C32.4832 18.8503 32.5947 18.7009 32.6711 18.5331C32.7475 18.3654 32.7871 18.1832 32.7871 17.9989C32.7871 17.8146 32.7475 17.6324 32.6711 17.4646C32.5947 17.2969 32.4832 17.1475 32.3441 17.0266Z"
      fill={fill}
    />
  </svg>
);

ArrowRight.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowRight.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowRight;
