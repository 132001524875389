import { useState } from "react";
import PropTypes from "prop-types";
import { Popconfirm as AntPopconfirm } from "antd";

const Confirm = (props) => {
  const { children, onCancel, onConfirm, visible } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onCancelPress = () => {
    onCancel();
  };

  const onConfirmPress = async () => {
    setConfirmLoading(true);
    const response = await onConfirm();

    if (response) {
      setConfirmLoading(false);
    } else {
      setConfirmLoading(false);
    }
  };

  return (
    <AntPopconfirm
      visible={visible}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={onCancelPress}
      onConfirm={onConfirmPress}
      {...props}
    >
      {children}
    </AntPopconfirm>
  );
};

Confirm.defaultProps = {
  children: null,
  okPromise: null,
  onCancel: null,
  onConfirm: null,
};

Confirm.propTypes = {
  children: PropTypes.node,
  okPromise: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  visible: PropTypes.bool.isRequired,
};

export default Confirm;
