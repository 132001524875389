import PropTypes from "prop-types";

const ArrowSolidRight = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M25.1648 17.3501L11.7773 5.8048C11.2781 5.37589 10.5469 5.76261 10.5469 6.45519V29.5458C10.5469 30.2384 11.2781 30.6251 11.7773 30.1962L25.1648 18.6509C25.548 18.3204 25.548 17.6806 25.1648 17.3501Z"
      fill={fill}
    />
  </svg>
);

ArrowSolidRight.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowSolidRight.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowSolidRight;
