import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Modal, Form, Input, Notification, Select } from "@cea/ui-kit";

import { getNotification } from "../../../../utils";
import {
  CREATE_QA_CLIENT_FOLDER,
  CREATE_QA_FOLDER,
  CREATE_QA_SUMMARY_FOLDER,
} from "../../../../queries/qaServices";
import getServiceLineName from "../../utils/getServiceName";

const serviceLineOptions = [
  { label: "Inline Production Monitoring", value: "ipm" },
  { label: "Pre-Shipment Inspection", value: "psi" },
  { label: "Production Tracking", value: "productionTracking" },
  { label: "Factory Audit", value: "factoryAudit" },
];

const summaryServiceLineOptions = [
  { label: "Container Loading", value: "containerLoading" },
  { label: "Lab Testing", value: "labTesting" },
  { label: "PSR", value: "psr" },
  { label: "Other Project Files", value: "otherProjectFiles" },
];

const CreateFolderModal = ({
  isVisible,
  handleCancel,
  projectId,
  parentId,
  handleOk,
  summaryFolder,
  clientFolder,
  clientId,
}) => {
  const [createQAFolder, { loading: createFolderLoading }] =
    useMutation(CREATE_QA_FOLDER);
  const [createQASummaryFolder, { loading: createSummaryFolderLoading }] =
    useMutation(CREATE_QA_SUMMARY_FOLDER);
  const [createQAClientFolder, { loading: createClientFolderLoading }] =
    useMutation(CREATE_QA_CLIENT_FOLDER);

  const [form] = Form.useForm();
  const { subProjectId, serviceLine } = useParams();
  const [queryParams] = useSearchParams();

  const serviceLines = queryParams.get("serviceLine") || null;

  const onFolderCreate = async () => {
    const { name, serviceLine } = form.getFieldsValue();

    const folderServiceName = [];

    if (summaryFolder) {
      const serviceName = summaryServiceLineOptions.find(
        (item) => item.label === serviceLine
      );

      folderServiceName.push("summary", serviceName.value);
    } else {
      folderServiceName.push(...serviceLine);
    }

    try {
      if (summaryFolder) {
        const { data } = await createQASummaryFolder({
          variables: {
            projectId: +projectId,
            serviceLine: folderServiceName,
            parentId,
            name,
          },
          notifyOnNetworkStatusChange: true,
        });

        const { id, parentName, parentId: rootId } = data.createQASummaryFolder;

        form.resetFields();
        handleOk(
          { name, id, serviceLine: folderServiceName },
          { parentName, parentId: rootId }
        );
      } else if (clientFolder) {
        const { data } = await createQAClientFolder({
          variables: {
            parentId,
            name,
            clientId,
          },
          notifyOnNetworkStatusChange: true,
        });

        const { id, parentName, parentId: rootId } = data.createQAClientFolder;

        form.resetFields();
        handleOk(
          { name, id, serviceLine: folderServiceName },
          { parentName, parentId: rootId }
        );
      } else {
        const { data } = await createQAFolder({
          variables: {
            projectId: +projectId,
            serviceLine: folderServiceName,
            parentId,
            name,
            subProject: +subProjectId || null,
          },
          notifyOnNetworkStatusChange: true,
        });

        const { id, parentName, parentId: rootId } = data.createQAFolder;

        form.resetFields();
        handleOk(
          { name, id, serviceLine: folderServiceName },
          { parentName, parentId: rootId }
        );
      }

      Notification.success({
        message: `New folder with name : ${name} created.`,
      });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      handleCancel();
    }
  };

  const getSerivceLineOptions = () =>
    summaryFolder ? summaryServiceLineOptions : serviceLineOptions;

  const getInitialServiceLines = () => {
    if (summaryFolder) {
      return getServiceLineName(serviceLine);
    } else {
      if (serviceLines) {
        const selectedServicesParams = serviceLines.split(",");
        const selectedServicesList = [];

        serviceLineOptions.forEach((item) => {
          if (selectedServicesParams.includes(item.value)) {
            selectedServicesList.push(item.label);
          }
        });

        return selectedServicesList;
      }

      return [];
    }
  };

  return (
    <Modal
      title="Create new folder"
      visible={isVisible}
      onOk={onFolderCreate}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          key="submit"
          loading={
            createFolderLoading ||
            createSummaryFolderLoading ||
            createClientFolderLoading
          }
          type="primary"
          onClick={onFolderCreate}
          className="ml-auto"
        >
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          serviceLine: getInitialServiceLines(),
        }}
        onFinish={onFolderCreate}
        autoComplete="off"
      >
        <Form.Item
          label="Folder name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name of the folder",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service"
          name="serviceLine"
          rules={[
            {
              required: true,
              message: "Please select at least 1 service",
            },
          ]}
        >
          <Select
            placeholder="Select Service"
            mode={summaryFolder ? null : "multiple"}
            options={getSerivceLineOptions()}
            className="min-w-[35%]"
            disabled={summaryFolder || clientFolder}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateFolderModal.defaultProps = {
  isVisible: false,
  handleCancel: () => {},
  parentId: null,
  projectId: null,
  handleOk: () => {},
  summaryFolder: false,
  clientFolder: false,
  clientId: null,
};

CreateFolderModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  projectId: PropTypes.string,
  handleOk: PropTypes.func.isRequired,
  summaryFolder: PropTypes.bool,
  clientFolder: PropTypes.bool,
  clientId: PropTypes.string,
};

export default CreateFolderModal;
