import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Card,
  Form,
  Icon,
  Input,
  Avatar,
  Button,
  Column,
  Loader,
  Upload,
  Breadcrumb,
  Notification,
} from "@cea/ui-kit";
import { useMutation } from "@apollo/client";

import Appshell from "../../components/Appshell";
import AuthContext from "../../contexts/AuthContext";
import { UPDATE_USER } from "../../queries/user";
import { getFilePath, getNotification, imageUploader } from "../../utils";

import styles from "./user-details.module.css";

const UserDetails = () => {
  const [imagePath, setImagePath] = useState(null);

  const [form] = Form.useForm();
  const [passwordChangeForm] = Form.useForm();
  const { user } = useContext(AuthContext);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [changePassword, { loading: changePasswordLoading }] =
    useMutation(UPDATE_USER);

  const onFinish = async ({ name, avatar }) => {
    const data = {};

    if (user.name !== name) {
      data.name = name;
    }

    // eslint-disable-next-line eqeqeq
    if (user.avatar != avatar) {
      data.avatar = avatar;
    }

    if (Object.keys(data).length) {
      try {
        await updateUser({
          variables: {
            data: {
              id: user.id,
              ...data,
            },
          },
        });
      } catch (error) {
        const message = getNotification(error);

        Notification.error({ message });
      }
    }
  };

  const onPasswordChange = async ({ newPassword }) => {
    try {
      await changePassword({
        variables: {
          data: { id: user.id, password: newPassword },
        },
      });

      passwordChangeForm.resetFields();
    } catch (error) {
      const message = getNotification(error);

      Notification.error({ message });
    }
  };

  const onImageUpload = async (info) => {
    const { updatedFileName, newFile } = await imageUploader(info);
    const filePath = getFilePath(updatedFileName);

    form.setFieldsValue({
      avatar: [newFile],
    });

    setImagePath(filePath);
  };

  return (
    <Appshell>
      <div className="page-main-container">
        <div className="breadcrumb-holder">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Your Profile</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {(() => {
          if (!user) {
            return (
              <div className="p-8">
                <Loader tip="Loading" />
              </div>
            );
          }

          return (
            <div className="mt-4">
              <Card>
                <Card.Meta
                  title="Details"
                  description="Manage Profile Details"
                />
                <div className="mt-8">
                  <Form
                    form={form}
                    initialValues={{
                      name: user.name,
                      email: user.email,
                      organization: user.organization?.name,
                      logo: user.logo,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={true}
                  >
                    <Row>
                      <Column span={2}>
                        <Avatar
                          style={{
                            width: "4rem",
                            height: "4rem",
                            background: "#7265e6",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: "2rem",
                          }}
                          src={imagePath}
                        >
                          {form.getFieldValue("name") && imagePath
                            ? form.getFieldValue("name")[0]
                            : null}
                        </Avatar>
                      </Column>
                      <Column>
                        <Form.Item
                          name="avatar"
                          label="Change Profile Photo"
                          valuePropName="fileList"
                          getValueFromEvent={(event) => [event?.file]}
                        >
                          <Upload
                            name="avatar"
                            beforeUpload={(_, __) => false}
                            onChange={onImageUpload}
                            multiple={false}
                            listType="picture"
                            showUploadList={false}
                          >
                            <Button className={styles["upload-button"]}>
                              <Icon
                                name="plus"
                                color="rgba(0,0,0,.5)"
                                size={12}
                                className="mr-1"
                              />
                              Change
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Column>
                    </Row>
                    <Row gutter={45}>
                      <Column span={8}>
                        <Form.Item
                          name="name"
                          label="User name"
                          rules={[
                            {
                              required: true,
                              message: "User name can't be empty",
                            },
                          ]}
                        >
                          <Input placeholder="Enter your name..." />
                        </Form.Item>
                      </Column>
                      <Column span={8}>
                        <Form.Item
                          name="email"
                          label="User Email"
                          rules={[
                            {
                              required: true,
                              message: "User email can't be empty",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Column>
                      <Column span={8}>
                        <Form.Item
                          name="organization"
                          label="Organization Name"
                          rules={[
                            {
                              required: true,
                              message: "User email can't be empty",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Column>
                    </Row>
                    <Row>
                      <Column span={24}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                              updateLoading ||
                              !!form
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                            loading={updateLoading}
                          >
                            <Icon
                              name="edit"
                              size={15}
                              color="white"
                              className="mr-2"
                            />
                            Update
                          </Button>
                        </Form.Item>
                      </Column>
                    </Row>
                  </Form>
                </div>
              </Card>
              <br />
              <Card>
                <Card.Meta
                  title="Change Password"
                  description={
                    <div>
                      <Link to="/">Forgot Your Password? Click Here</Link>
                    </div>
                  }
                />
                <div className="mt-4">
                  <Form
                    form={passwordChangeForm}
                    onFinish={onPasswordChange}
                    layout="vertical"
                    requiredMark={true}
                  >
                    <Row>
                      <Column span={10}>
                        <Form.Item
                          name="newPassword"
                          label="New Password"
                          rules={[
                            {
                              required: true,
                              message: "Password field can't be empty",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password placeholder="Enter new password..." />
                        </Form.Item>
                      </Column>
                    </Row>
                    <Row>
                      <Column span={10}>
                        <Form.Item
                          name="confirmPassword"
                          label="Confirm Password"
                          dependencies={["newPassword"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Confirm Password field can't be empty",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="Confirm new password..." />
                        </Form.Item>
                      </Column>
                    </Row>
                    <br />
                    <Row>
                      <Column span={24}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                              changePasswordLoading ||
                              !!passwordChangeForm
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                            loading={changePasswordLoading}
                          >
                            <Icon
                              name="edit"
                              size={15}
                              color="white"
                              className="mr-2"
                            />
                            Change Password
                          </Button>
                        </Form.Item>
                      </Column>
                    </Row>
                  </Form>
                </div>
              </Card>
            </div>
          );
        })()}
      </div>
    </Appshell>
  );
};

export default UserDetails;
