import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Column,
  Input,
  Notification,
  Row,
  Select,
} from "@cea/ui-kit";
import { useApolloClient, useMutation } from "@apollo/client";
import { debounce } from "lodash-es";

import {
  FETCH_GH2_STPR_FILES,
  FETCH_GH2_STPR_FOLDERS,
  RENAME_GH2_STPR_FILE,
  RENAME_GH2_STPR_FOLDER,
} from "../../../queries/miServices";
import { getNotification, getOrderByObject, sortOptions } from "../../../utils";
import AuthContext from "../../../contexts/AuthContext";
import GH2STPRReportUploadModal from "../GH2STPRReportUploadModal";
import GH2STPRCreateFolderModal from "../GH2STPRCreateFolderModal";
import useFileFolderDeleteDialog from "../useFileFolderDeleteDialog";
import ClientInviteModal from "../../../components/ClientInviteModal";
import OrganizationContext from "../../../contexts/OrganizationContext";
import ReportsListView from "../../../components/ReportsListView/ReportsListView";

import styles from "./gh2-stpr-service-detail.module.css";

const GH2STPRServiceDetail = () => {
  const [loading, setLoading] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isClientInviteModalOpen, setIsClientInviteModalOpen] = useState(false);
  const [isCreateFileModalVisible, setIsCreateFileModalVisible] =
    useState(false);

  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] =
    useState(false);

  const apolloClient = useApolloClient();
  const [queryParams, setQueryParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const { service, fetchServiceDetails } = useContext(OrganizationContext);

  const [renameFolder, { loading: folderRenameLoading }] = useMutation(
    RENAME_GH2_STPR_FOLDER
  );
  const [renameFile, { loading: fileRenameLoading }] =
    useMutation(RENAME_GH2_STPR_FILE);

  const orderBy = queryParams.get("sort") || "createDate:desc";
  const folderId = queryParams.get("folderId");
  const isRenameFolderLoading =
    folderRenameLoading || fileRenameLoading ? "loading_rename" : null;

  const fetchFolders = async () => {
    try {
      setLoading("loading_data");

      const sortObject = getOrderByObject(orderBy);

      const { data } = await apolloClient.query({
        query: FETCH_GH2_STPR_FOLDERS,
        variables: {
          where: {
            id: folderId,
          },
          orderBy: sortObject,
        },
        fetchPolicy: "network-only",
      });

      setFolderData(data.getGH2STPRFolders);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(null);
    }
  };

  const fetchFiles = async (variables = {}) => {
    try {
      setLoading("loading_data");

      const sortObject = getOrderByObject(orderBy);

      const { data } = await apolloClient.query({
        query: FETCH_GH2_STPR_FILES,
        variables: {
          ...variables,
          orderBy: sortObject,
        },
      });

      setFolderData({
        folders: [],
        files: data.getGH2STPRFiles,
      });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(null);
    }
  };

  useEffect(() => {
    if (!service) {
      const filterQuery = {};

      filterQuery.serviceType = "gh2Stpr";

      fetchServiceDetails(filterQuery);
    }

    if (service) {
      if (inputValue.length) {
        fetchFiles({ name: inputValue });
      } else {
        fetchFolders();
      }
    }
  }, [service, folderId, orderBy]);

  const debouncer = useCallback(
    debounce((searchValue) => fetchFiles(searchValue), 750),
    []
  );

  const onNameSearch = (event) => {
    const searchValue = event.target.value;

    setInputValue(searchValue);

    if (searchValue.length) {
      debouncer({ name: searchValue });
    } else {
      fetchFolders();
    }
  };

  const showCreateFolderModal = () => {
    setIsCreateFolderModalVisible(true);
  };

  const onFolderCreate = (newFolder, parentFolder) => {
    if (!folderData?.id) {
      setFolderData({
        ...parentFolder,
        folders: [
          { ...newFolder, __typename: "GH2STPRFolder", createdBy: user.name },
        ],
        files: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        folders: [
          ...prevState.folders,
          {
            ...newFolder,
            __typename: "GH2STPRFolder",
            createdBy: user.name,
          },
        ],
      }));
    }

    setIsCreateFolderModalVisible(false);
  };

  const onFileCreate = (newFiles, parentFolder) => {
    if (!folderData?.id) {
      setFolderData({
        ...parentFolder,
        files: newFiles.map((item) => ({
          ...item,
          __typename: "GH2STPRFile",
          createdBy: user.name,
        })),
        folders: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        files: [
          ...prevState.files,
          ...newFiles.map((item) => ({
            ...item,
            __typename: "GH2STPRFile",
            createdBy: user.name,
          })),
        ],
      }));
    }

    setIsCreateFileModalVisible(false);
  };

  const onFolderOrFileRename = async (details, newName) => {
    try {
      if (details.__typename.includes("Folder")) {
        await renameFolder({
          variables: {
            data: {
              id: details.id,
              name: newName,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          folders: prevState.folders.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      } else {
        await renameFile({
          variables: {
            data: {
              id: details.id,
              name: newName,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          files: prevState.files.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const onFolderDelete = (id) => {
    setFolderData((prevState) => ({
      ...prevState,
      folders: prevState.folders.filter((folder) => folder.id !== id),
    }));
  };

  const onFileDelete = (id) => {
    setFolderData((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file) => file.id !== id),
    }));
  };

  const handleChange = (value = "") => {
    setSelectedOrder(value || null);

    setQueryParams({ sort: value });
  };

  const header = (
    <div className={styles["reports-filter-container"]}>
      <Row gutter={[16, 32]} justify="space-between">
        <Column span={6}>
          <Input
            placeholder="Search File"
            value={inputValue}
            onChange={onNameSearch}
          />
        </Column>
        <Column span={6}>
          <Select
            allowClear
            onChange={handleChange}
            placeholder="Sort by"
            value={selectedOrder}
            options={sortOptions}
            className={styles["search-input"]}
          />
        </Column>
      </Row>
    </div>
  );

  const navHolder = (
    <div className={styles["flex-container"]}>
      <div className="breadcrumb-holder">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/services">Services</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{service?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className={styles["actions-container"]}>
        <Button
          className="gap-2"
          type="primary"
          size="small"
          onClick={() => {
            setIsClientInviteModalOpen(true);
          }}
        >
          Add Client User
        </Button>
        <Button
          size="small"
          type="primary"
          className="theme-button"
          onClick={() => {
            setIsCreateFileModalVisible(true);
          }}
        >
          Upload File
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={showCreateFolderModal}
          className="theme-button"
        >
          Create Folder
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <ReportsListView
        header={header}
        navHolder={navHolder}
        folderData={folderData}
        onFileDelete={onFileDelete}
        onFolderDelete={onFolderDelete}
        onRename={onFolderOrFileRename}
        loading={loading || isRenameFolderLoading}
        useFileFolderDeleteDialog={useFileFolderDeleteDialog}
      />
      <GH2STPRReportUploadModal
        isVisible={isCreateFileModalVisible}
        handleOk={onFileCreate}
        handleCancel={() => {
          setIsCreateFileModalVisible(false);
        }}
        parentId={folderId || folderData?.id}
      />
      <GH2STPRCreateFolderModal
        isVisible={isCreateFolderModalVisible}
        handleCancel={() => {
          setIsCreateFolderModalVisible(false);
        }}
        parentId={folderId || folderData?.id}
        handleOk={onFolderCreate}
      />
      <ClientInviteModal
        isVisible={isClientInviteModalOpen}
        handleCancel={() => setIsClientInviteModalOpen(false)}
      />
    </>
  );
};

export default GH2STPRServiceDetail;
