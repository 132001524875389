import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import {
  Breadcrumb,
  Button,
  Card,
  Loader,
  Row,
  Column,
  Icon,
  Avatar,
} from "@cea/ui-kit";
import { Link, useParams } from "react-router-dom";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { colorsMap } from "../../../utils";
import Appshell from "../../../components/Appshell";
import AuthContext from "../../../contexts/AuthContext";
import ClientInviteModal from "../../../components/ClientInviteModal";
import OrganizationContext from "../../../contexts/OrganizationContext";

import styles from "./si-service-detail.module.css";

dayjs.extend(advancedFormat);

const SIServiceDetail = () => {
  const [projectManagers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientModalOpen, setClientModalOpen] = useState(false);

  const { organizationId, serviceId } = useParams();
  const {
    service,
    organization,
    fetchOrganizationDetails,
    fetchServiceDetails,
  } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }
  }, [organization]);

  useEffect(() => {
    if (!service) {
      const filterQuery = {};

      if (serviceId) {
        filterQuery.id = serviceId;
      } else {
        filterQuery.serviceType = "si";
      }

      fetchServiceDetails(filterQuery);
    }
  }, [service]);

  const onEditService = () => {
    // Option 1:Change
    setIsModalOpen(true);
  };

  const onAddClient = () => {
    setClientModalOpen(true);
  };

  const getStartingDate = () => {
    const selectedSubscription = organization?.subscriptions.find(
      (item) => item.service.id === service.id
    );

    if (selectedSubscription) {
      return dayjs(selectedSubscription.startingOn).format("Do MMMM, YYYY");
    }
  };

  const closeModal = (type) => {
    if (type === "pm") {
      setIsModalOpen(false);
    } else if (type === "client") {
      setClientModalOpen(false);
    }
  };

  const isLoggedInUserAdmin = () =>
    user?.roles?.find(
      (item) => item.serivceId === serviceId && item.role === "admin"
    );

  return (
    <Appshell>
      {(() => {
        // Todo: Fix Loader styles
        if (!service) {
          return (
            <div className="p-8">
              <Loader tip="Loading" />
            </div>
          );
        }

        return (
          <div className="page-main-container">
            <div className="breadcrumb-holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`/organizations/${organization?.id}`}>
                    {organization?.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/services">Services</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{service?.name}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <section>
              <Card>
                <Row justify="space-between">
                  <Column span={8}>
                    <div className={styles["primary-text"]}>Service Info</div>
                    <div>Service Based Information</div>
                  </Column>
                  <Column span={16}>
                    <div className="standard-flex justify-end gap-4">
                      {isLoggedInUserAdmin ? (
                        <Button type="text" disabled onClick={onEditService}>
                          Edit Project Managers
                        </Button>
                      ) : null}
                      <Button
                        className="gap-2"
                        type="primary"
                        icon={<Icon size={14} name="plus" color="#fff" />}
                        onClick={onAddClient}
                      >
                        Add Client User
                      </Button>
                    </div>
                  </Column>
                </Row>
                <br />
                <Row>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Service Admins
                    </div>
                    <div>
                      <Avatar.Group maxCount={2}>
                        {service?.admins.map((item) => (
                          <Avatar
                            key={item.id}
                            style={{
                              backgroundColor:
                                colorsMap[item.name[0].toLowerCase()],
                            }}
                          >
                            {item?.name
                              .split(" ")
                              .map((name) => name[0])
                              .join("")}
                          </Avatar>
                        ))}
                      </Avatar.Group>
                    </div>
                  </Column>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Project Managers
                    </div>
                    <div>
                      <Avatar.Group maxCount={2}>
                        {projectManagers.map(({ user }) => {
                          const initials = user.name
                            .split(" ")
                            .map((item) => item[0]);

                          return (
                            <Avatar
                              key={user.id}
                              style={{
                                backgroundColor:
                                  colorsMap[initials[0].toLowerCase()],
                              }}
                            >
                              {initials}
                            </Avatar>
                          );
                        })}
                      </Avatar.Group>
                    </div>
                  </Column>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Starting Date
                    </div>
                    <div className={styles["primary-text"]}>
                      {getStartingDate()}
                    </div>
                  </Column>
                </Row>
              </Card>
            </section>
          </div>
        );
      })()}
      <ClientInviteModal
        isVisible={clientModalOpen}
        handleCancel={() => closeModal("client")}
      />
    </Appshell>
  );
};

export default SIServiceDetail;
