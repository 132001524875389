import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Icon,
  Form,
  Modal,
  Button,
  Column,
  Select,
  Upload,
  Notification,
} from "@cea/ui-kit";
import { debounce } from "lodash-es";
import { useApolloClient, useMutation } from "@apollo/client";
import { useParams, useSearchParams } from "react-router-dom";

import {
  fetchFactories,
  fetchSuppliers,
  fetchWorkshops,
} from "../utils/fetchProjectDetails";
import { getNotification, imageUploader } from "../../../utils";
import {
  CREATE_QA_CLIENT_FILE,
  CREATE_QA_FILE,
  CREATE_QA_SUMMARY_FILE,
} from "../../../queries/qaServices";
import getServiceLineName from "../utils/getServiceName";
import Alert from "../../../components/NavigationAlert/NavigationAlert";
import { useCallbackPrompt } from "../../../hooks";

const { Option } = Select;

const serviceLines = [
  { label: "Inline Production Monitoring", value: "ipm" },
  { label: "Pre-Shipment Inspection", value: "psi" },
  { label: "Production Tracking", value: "productionTracking" },
  { label: "Factory Audit", value: "factoryAudit" },
];

const summaryServiceLines = [
  { label: "Container Loading", value: "containerLoading" },
  { label: "Lab Testing", value: "labTesting" },
  { label: "PSR", value: "psr" },
  { label: "Other Project Files", value: "otherProjectFiles" },
];

const ReportUploadForm = ({ formInstance, summaryReport, clientReport }) => {
  const [loadingFileMeta, setLoadingFileMeta] = useState(false);
  const [fileMeta, setFileMeta] = useState({
    factories: [],
    suppliers: [],
    workshops: [],
  });

  const { serviceLine } = useParams();
  const apolloClient = useApolloClient();
  const serviceOptions = summaryReport ? summaryServiceLines : serviceLines;

  const fetchSuppliersData = async (variables = {}) => {
    try {
      setLoadingFileMeta("loading_suppliers");

      const suppliers =
        (await fetchSuppliers({ apolloClient, variables })) || [];

      setFileMeta((prevState) => ({
        ...prevState,
        suppliers,
      }));
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingFileMeta(null);
    }
  };

  const fetchFactoriesData = async (variables = {}) => {
    try {
      setLoadingFileMeta("loading_factories");

      const factories =
        (await fetchFactories({ apolloClient, variables })) || [];

      setFileMeta((prevState) => ({
        ...prevState,
        factories,
      }));
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingFileMeta(null);
    }
  };

  const fetchWorkshopsData = async (variables = {}) => {
    try {
      setLoadingFileMeta("loading_workshops");
      const workshops =
        (await fetchWorkshops({ apolloClient, variables })) || [];

      setFileMeta((prevState) => ({
        ...prevState,
        workshops,
      }));
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingFileMeta(null);
    }
  };

  useEffect(() => {
    fetchSuppliersData();
  }, []);

  const debouncer = useCallback(
    debounce((fn, searchValue) => fn(searchValue), 750),
    []
  );

  const onSearch = (type) => (value) => {
    switch (type) {
      case "supplier":
        debouncer(fetchSuppliersData, { name: value });
        break;

      case "workshop":
        debouncer(fetchWorkshopsData, { name: value });
        break;

      case "factory":
        debouncer(fetchFactoriesData, { name: value });
        break;
    }
  };

  const selectConfig = [
    {
      loading: "loading_suppliers",
      fieldValue: "supplier",
      label: "Supplier",
      onSelect: (_, option) => {
        formInstance.setFieldsValue({
          factory: "",
          workshop: "",
        });

        fetchFactoriesData({
          SupplierID: Number(option.key),
        });
      },
      options: fileMeta.suppliers,
      optionKey: "SupplierID",
      optionValue: "Supplier",
    },
    {
      loading: "loading_factories",
      fieldValue: "factory",
      label: "Factory",
      onSelect: (_, option) => {
        formInstance.setFieldsValue({
          workshop: "",
        });

        fetchWorkshopsData({
          FactoryID: Number(option.key),
        });
      },
      options: fileMeta.factories,
      optionKey: "FactoryID",
      optionValue: "Factory_Formal_Name",
    },
    {
      loading: "loading_workshops",
      fieldValue: "workshop",
      label: "Workshop",
      onSelect: null,
      options: fileMeta.workshops,
      optionKey: "WorkshopID",
      optionValue: "Factory_Workshop_Name",
    },
  ];

  return (
    <Form
      form={formInstance}
      initialValues={{
        ...(summaryReport
          ? { serviceLine: getServiceLineName(serviceLine) }
          : {}),
      }}
      layout="vertical"
      requiredMark={true}
    >
      <Row gutter={45}>
        {!clientReport && (
          <>
            {selectConfig.map((item) => (
              <Column key={item.fieldValue} span={12}>
                <Form.Item
                  name={item.fieldValue}
                  label={item.label}
                  rules={item.rules}
                >
                  <Select
                    showSearch
                    placeholder={`Select a ${item.label}`}
                    loading={loadingFileMeta === item.loading}
                    onSearch={onSearch(item.fieldValue)}
                    onSelect={item.onSelect}
                    filterOption={false}
                  >
                    {item.options.map((option) => (
                      <Option
                        key={option[item.optionKey]}
                        value={option[item.optionValue]}
                      >
                        {option[item.optionValue]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Column>
            ))}
            <Column span={24}>
              <Form.Item
                name="serviceLine"
                label="Service"
                rules={[
                  {
                    required: true,
                    message: "Select at least 1 service",
                  },
                ]}
              >
                <Select
                  placeholder="Select a service"
                  mode={summaryReport ? null : "multiple"}
                  options={serviceOptions}
                  disabled={summaryReport}
                />
              </Form.Item>
            </Column>
          </>
        )}

        <Column span={24}>
          <Form.Item
            name="link"
            label="Upload File"
            rules={[{ required: true, message: "File is required" }]}
            valuePropName="fileList"
            getValueFromEvent={(event) => event?.fileList}
          >
            <Upload.Dragger
              name="link"
              beforeUpload={(_, __) => false}
              multiple
              listType="text"
              showUploadList={{
                showRemoveIcon: true,
                removeIcon: (
                  <Icon
                    name="delete"
                    size={14}
                    onClick={(e) => console.log(e, "custom removeIcon event")}
                  />
                ),
              }}
            >
              <div className="flex items-center justify-center w-full mb-4">
                <Icon name="upload" color="var(--primary-theme)" size={24} />
              </div>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Column>
      </Row>
    </Form>
  );
};

const QAReportUploadModal = ({
  isVisible,
  handleOk,
  handleCancel,
  summaryReport,
  clientId,
  clientReport,
}) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [query] = useSearchParams();
  const { projectId = null, subProjectId } = useParams();
  const [createQAFile] = useMutation(CREATE_QA_FILE);
  const [createQASummaryFile] = useMutation(CREATE_QA_SUMMARY_FILE);
  const [createQAClientFile] = useMutation(CREATE_QA_CLIENT_FILE);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(loading);

  const resetForm = () => {
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    if (isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const onFormSubmit = async () => {
    try {
      setLoading(true);

      const fileServiceName = [];
      const folderId = query.get("folderId");
      const { supplier, workshop, factory, serviceLine, link } =
        form.getFieldsValue();

      if (summaryReport) {
        const serviceName = summaryServiceLines.find(
          (item) => item.label === serviceLine
        );

        fileServiceName.push("summary", serviceName.value);
      } else if (clientReport) {
        fileServiceName.push("relatedFiles");
      } else {
        fileServiceName.push(...serviceLine);
      }

      const uploadedFiles = [];
      let parentName = null;
      let parentId = folderId;

      for (const item of link) {
        const { newFile } = await imageUploader({ file: item });

        if (summaryReport) {
          const { data } = await createQASummaryFile({
            variables: {
              data: {
                name: item.name,
                link: newFile.name,
                serviceLine: fileServiceName,
                projectId: +projectId,
                factory,
                workshop,
                supplier,
                parentId: folderId,
              },
            },
          });

          const { id } = data.createQASummaryFile;

          if (!parentName) {
            parentName = data.createQASummaryFile.parentName;
            parentId = data.createQASummaryFile.parentId;
          }

          uploadedFiles.push({
            link: newFile.name,
            name: item.name,
            parentId,
            id,
            subProjectId,
            projectId: +projectId,
            serviceLine: fileServiceName,
          });
        } else if (clientReport) {
          const { data } = await createQAClientFile({
            variables: {
              data: {
                name: item.name,
                link: newFile.name,
                parentId: folderId,
                clientId,
              },
            },
          });

          const { id } = data.createQAClientFile;

          if (!parentName) {
            parentName = data.createQAClientFile.parentName;
            parentId = data.createQAClientFile.parentId;
          }

          uploadedFiles.push({
            link: newFile.name,
            name: item.name,
            parentId,
            id,
            clientId,
            serviceLine: ["common"],
          });
        } else {
          const { data } = await createQAFile({
            variables: {
              data: {
                name: item.name,
                link: newFile.name,
                serviceLine: fileServiceName,
                projectId: +projectId,
                factory,
                workshop,
                supplier,
                parentId: folderId,
                subProject: +subProjectId || null,
              },
            },
          });

          const { id } = data.createQAFile;

          if (!parentName) {
            parentName = data.createQAFile.parentName;
            parentId = data.createQAFile.parentId;
          }

          uploadedFiles.push({
            link: newFile.name,
            name: item.name,
            parentId,
            id,
            subProjectId,
            projectId: +projectId,
            serviceLine: fileServiceName,
          });
        }
      }

      setLoading(false);
      form.resetFields();
      handleOk(uploadedFiles, { parentName, parentId });
      Notification.success({ message: "File uploaded successfully" });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(false);
      // handleCancel();
    }
  };

  return (
    <>
      <Modal
        title="Upload Report"
        visible={isVisible}
        onOk={onFormSubmit}
        onCancel={handleCancel}
        centered
        width={720}
        footer={[
          <Button
            key="submit"
            loading={loading}
            type="primary"
            onClick={onFormSubmit}
            className="ml-auto"
          >
            Save
          </Button>,
        ]}
      >
        <ReportUploadForm
          resetForm={resetForm}
          formInstance={form}
          summaryReport={summaryReport}
          clientReport={clientReport}
        />
      </Modal>
      <Alert
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </>
  );
};

ReportUploadForm.defaultProps = {
  formInstance: {
    setFieldsValue: () => {},
    getFieldsValue: () => {},
    resetFields: () => {},
  },
  summaryReport: false,
  clientReport: false,
};

ReportUploadForm.propTypes = {
  formInstance: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    resetFields: PropTypes.func,
  }),
  summaryReport: PropTypes.bool,
  clientReport: PropTypes.bool,
};

QAReportUploadModal.defaultProps = {
  isVisible: false,
  handleOk: () => {},
  summaryReport: false,
  clientReport: false,
  clientId: null,
  handleCancel: () => {},
};

QAReportUploadModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  summaryReport: PropTypes.bool.isRequired,
  clientReport: PropTypes.bool,
  clientId: PropTypes.string,
};

export default QAReportUploadModal;
