import PropTypes from "prop-types";

const Notification = ({ size = 64, fill = "none" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2143 28.2807H29.25V14.62C29.25 8.95078 25.0594 4.26596 19.6071 3.4865V1.92355C19.6071 1.0356 18.8879 0.316406 18 0.316406C17.1121 0.316406 16.3929 1.0356 16.3929 1.92355V3.4865C10.9406 4.26596 6.75 8.95078 6.75 14.62V28.2807H5.78571C5.07455 28.2807 4.5 28.8552 4.5 29.5664V30.8521C4.5 31.0289 4.64464 31.1736 4.82143 31.1736H13.5C13.5 33.6566 15.517 35.6736 18 35.6736C20.483 35.6736 22.5 33.6566 22.5 31.1736H31.1786C31.3554 31.1736 31.5 31.0289 31.5 30.8521V29.5664C31.5 28.8552 30.9254 28.2807 30.2143 28.2807ZM18 33.1021C16.9353 33.1021 16.0714 32.2383 16.0714 31.1736H19.9286C19.9286 32.2383 19.0647 33.1021 18 33.1021ZM9.64286 28.2807V14.62C9.64286 12.3861 10.5107 10.2887 12.0897 8.70971C13.6688 7.13069 15.7661 6.26284 18 6.26284C20.2339 6.26284 22.3313 7.13069 23.9103 8.70971C25.4893 10.2887 26.3571 12.3861 26.3571 14.62V28.2807H9.64286Z"
      fill={fill}
    />
  </svg>
);

Notification.defaultProps = {
  size: 64,
  fill: "black",
};

Notification.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Notification;
