import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Layout } from "@cea/ui-kit";

import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import ThemeContext from "../../contexts/ThemeContext";

const { Content } = Layout;

const Appshell = ({ children }) => {
  const { collapsed } = useContext(ThemeContext);

  return (
    <Layout>
      <Sidebar />

      <Layout>
        <Navbar />
        <Content
          style={{
            marginLeft: collapsed ? "50px" : "250px",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

Appshell.defualtProps = {
  children: null,
};

Appshell.propTypes = {
  children: PropTypes.node,
};

export default Appshell;
