import { gql } from "@apollo/client";

export const CREATE_EL_INSPECTIONS_FOLDER = gql`
  mutation CreateELInspectionsFolder($name: String!, $parentId: ID) {
    createELInspectionsFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_EL_INSPECTIONS_FILE = gql`
  mutation CreateELInspectionsFile(
    $link: String!
    $name: String!
    $parentId: ID
  ) {
    createELInspectionsFile(
      data: { link: $link, name: $name, parentId: $parentId }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const FETCH_EL_INSPECTIONS_FOLDERS = gql`
  query GetELInspectionsFolders(
    $where: ELInspectionsFolderWhereInput!
    $orderBy: ELInspectionsOrderByInput!
  ) {
    getELInspectionsFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_EL_INSPECTIONS_FILES = gql`
  query GetELInspectionsFiles(
    $name: String!
    $orderBy: ELInspectionsOrderByInput!
  ) {
    getELInspectionsFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const RENAME_EL_INSPECTIONS_FOLDER = gql`
  mutation RenameELInspectionsFolder($data: UpdateFolderOrFileInput!) {
    updateELInspectionsFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_EL_INSPECTIONS_FILE = gql`
  mutation RenameELInspectionsFile($data: UpdateFolderOrFileInput!) {
    updateELInspectionsFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_EL_INSPECTIONS_FOLDER = gql`
  mutation DeleteELInspectionsFolder($id: ID!) {
    deleteELInspectionsFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_EL_INSPECTIONS_FILE = gql`
  mutation DeleteELInspectionsFile($id: ID!) {
    deleteELInspectionsFile(id: $id) {
      message
      id
    }
  }
`;
