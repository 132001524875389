import { gql } from "@apollo/client";

export const FETCH_CRMI_FOLDERS = gql`
  query GetCRMIFolders(
    $where: CRMIFolderWhereInput!
    $orderBy: CRMIOrderByInput!
  ) {
    getCRMIFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_CRMI_FILES = gql`
  query GetCRMIFiles(
    $name: String!
    $organization: ID!
    $orderBy: CRMIOrderByInput!
  ) {
    getCRMIFiles(name: $name, organization: $organization, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_CRMI_FOLDER = gql`
  mutation CreateCRMIFolder($name: String!, $parentId: ID, $organization: ID!) {
    createCRMIFolder(
      data: { name: $name, parentId: $parentId, organization: $organization }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_CRMI_FILE = gql`
  mutation CreateCRMIFile(
    $link: String!
    $name: String!
    $organization: ID!
    $parentId: ID
  ) {
    createCRMIFile(
      data: {
        link: $link
        name: $name
        parentId: $parentId
        organization: $organization
      }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_CRMI_FOLDER = gql`
  mutation RenameCRMIFolder($data: UpdateFolderOrFileInput!) {
    updateCRMIFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_CRMI_FILE = gql`
  mutation RenameCRMIFile($data: UpdateFolderOrFileInput!) {
    updateCRMIFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_CRMI_FOLDER = gql`
  mutation DeleteCRMIFolder($id: ID!) {
    deleteCRMIFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_CRMI_FILE = gql`
  mutation DeleteCRMIFile($id: ID!) {
    deleteCRMIFile(id: $id) {
      message
      id
    }
  }
`;
