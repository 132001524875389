import PropTypes from "prop-types";
import { Layout, Menu as AntMenu } from "antd";

import styles from "./sidebar.module.css";

const { Sider } = Layout;
const { SubMenu: AntSubMenu, Item: AntItem, ItemGroup } = AntMenu;

const Menu = (props) => {
  const { children } = props;

  return (
    <AntMenu {...props} className={styles["sidebar-menu"]} inlineIndent={0}>
      {children}
    </AntMenu>
  );
};

const SidebarHead = (props) => {
  const { children } = props;

  return <div className={styles["sidebar-head"]}>{children}</div>;
};

const MenuGroupHeader = (props) => {
  const { title, children, ...rest } = props;

  return (
    <ItemGroup title={title} className={styles["menu-group"]} {...rest}>
      {children}
    </ItemGroup>
  );
};

const CustomMenuItemGroup = (props) => {
  const { title, children } = props;

  return (
    <ItemGroup title={title} className={styles["custom-item-group"]}>
      {children}
    </ItemGroup>
  );
};

const MenuItem = (props) => {
  const { children, icon, ...rest } = props;

  const renderMenuIcon = () => {
    if (!icon) return null;

    return <span className="ant-menu-item-icon">{icon}</span>;
  };

  return (
    <AntItem className={styles["menu-item"]} icon={renderMenuIcon()} {...rest}>
      {children}
    </AntItem>
  );
};

const SubMenu = (props) => {
  const { children, icon, title, ...rest } = props;

  const renderMenuIcon = () => {
    if (!icon) return null;

    return <span className="ant-menu-item-icon">{icon}</span>;
  };

  return (
    <AntSubMenu
      title={title}
      className={styles["sub-menu"]}
      icon={renderMenuIcon()}
      {...rest}
    >
      {children}
    </AntSubMenu>
  );
};

const Sidebar = (props) => {
  const { children, collapsed, collapsedWidth = 50, width = 250 } = props;

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      trigger={null}
      width={width}
      className={styles.sidebar}
    >
      {children}
    </Sider>
  );
};

Menu.defaultProps = {
  children: null,
};

Menu.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedKeys: PropTypes.shape([]).isRequired,
  openKeys: PropTypes.shape([]).isRequired,
  onOpenChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

SidebarHead.defaultProps = {
  children: null,
};

SidebarHead.propTypes = {
  children: PropTypes.node,
};

MenuGroupHeader.defaultProps = {
  children: null,
};

MenuGroupHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

CustomMenuItemGroup.defaultProps = {
  children: PropTypes.node,
};

CustomMenuItemGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
  children: null,
};

MenuItem.propTypes = {
  children: PropTypes.node,
};

SubMenu.defaultProps = {
  children: null,
};

SubMenu.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  children: null,
  collapsed: false,
  collapsedWidth: 50,
  width: 250,
};

Sidebar.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  collapsedWidth: PropTypes.number,
  width: PropTypes.number,
};

Sidebar.Menu = Menu;
Sidebar.MenuItem = MenuItem;
Sidebar.SidebarHead = SidebarHead;
Sidebar.MenuGroupHeader = MenuGroupHeader;
Sidebar.SubMenu = SubMenu;
Sidebar.CustomMenuItemGroup = CustomMenuItemGroup;

export default Sidebar;
