import PropTypes from "prop-types";

const BulbOutlined = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2344 23.7691C8.78906 21.7758 6.46875 18.0492 6.46875 13.7812C6.46875 7.41445 11.6332 2.25 18 2.25C24.3668 2.25 29.5312 7.41445 29.5312 13.7812C29.5312 18.0492 27.2109 21.7758 23.7656 23.7691V27.8438C23.7656 28.466 23.2629 28.9688 22.6406 28.9688H13.3594C12.7371 28.9688 12.2344 28.466 12.2344 27.8438V23.7691ZM13.7812 31.2188H22.2188C22.3734 31.2188 22.5 31.3453 22.5 31.5V32.625C22.5 33.2473 21.9973 33.75 21.375 33.75H14.625C14.0027 33.75 13.5 33.2473 13.5 32.625V31.5C13.5 31.3453 13.6266 31.2188 13.7812 31.2188Z"
      fill="none"
      stroke={fill}
      strokeWidth={2}
    />
  </svg>
);

BulbOutlined.defaultProps = {
  size: 64,
  fill: "black",
};

BulbOutlined.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default BulbOutlined;
