import PropTypes from "prop-types";

const ArrowLeft = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.4635 16.4721H8.95498L23.0255 4.25781C23.2505 4.06094 23.1139 3.69531 22.8166 3.69531H19.2608C19.1041 3.69531 18.9554 3.75156 18.8389 3.85201L3.65542 17.0266C3.51636 17.1471 3.40483 17.2961 3.3284 17.4635C3.25196 17.631 3.2124 17.8128 3.2124 17.9969C3.2124 18.1809 3.25196 18.3628 3.3284 18.5302C3.40483 18.6976 3.51636 18.8466 3.65542 18.9672L18.9273 32.2221C18.9876 32.2743 19.0599 32.3025 19.1362 32.3025H22.8126C23.1099 32.3025 23.2465 31.9328 23.0215 31.74L8.95498 19.5257H32.4635C32.6402 19.5257 32.7849 19.381 32.7849 19.2042V16.7935C32.7849 16.6167 32.6402 16.4721 32.4635 16.4721Z"
      fill={fill}
    />
  </svg>
);

ArrowLeft.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowLeft.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowLeft;
