import PropTypes from "prop-types";

const Search = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.9756 31.7662L23.5413 21.3318C25.1604 19.2385 26.0363 16.6791 26.0363 13.9872C26.0363 10.7648 24.7788 7.74341 22.5046 5.46529C20.2305 3.18716 17.2011 1.93359 13.9828 1.93359C10.7645 1.93359 7.735 3.19118 5.4609 5.46529C3.18277 7.7394 1.9292 10.7648 1.9292 13.9872C1.9292 17.2055 3.18679 20.2349 5.4609 22.509C7.735 24.7872 10.7604 26.0407 13.9828 26.0407C16.6747 26.0407 19.2301 25.1648 21.3234 23.5497L31.7578 33.98C31.7884 34.0106 31.8247 34.0349 31.8647 34.0515C31.9047 34.0681 31.9475 34.0766 31.9908 34.0766C32.0341 34.0766 32.0769 34.0681 32.1169 34.0515C32.1569 34.0349 32.1932 34.0106 32.2238 33.98L33.9756 32.2323C34.0062 32.2017 34.0305 32.1653 34.0471 32.1253C34.0637 32.0854 34.0722 32.0425 34.0722 31.9992C34.0722 31.9559 34.0637 31.9131 34.0471 31.8731C34.0305 31.8331 34.0062 31.7968 33.9756 31.7662ZM20.3471 20.3515C18.6435 22.051 16.3854 22.9872 13.9828 22.9872C11.5801 22.9872 9.32206 22.051 7.61848 20.3515C5.91893 18.6479 4.98277 16.3898 4.98277 13.9872C4.98277 11.5845 5.91893 9.32243 7.61848 7.62288C9.32206 5.92333 11.5801 4.98717 13.9828 4.98717C16.3854 4.98717 18.6475 5.91931 20.3471 7.62288C22.0466 9.32645 22.9828 11.5845 22.9828 13.9872C22.9828 16.3898 22.0466 18.6519 20.3471 20.3515Z"
      fill={fill}
    />
  </svg>
);

Search.defaultProps = {
  size: 64,
  fill: "black",
};

Search.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Search;
