import { useContext, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Button, Empty, File, Loader, StatusLabel } from "@cea/ui-kit";
import { useNavigate } from "react-router-dom";

import Appshell from "../Appshell";
import { getFilePath } from "../../utils";
import RenameFolderModal from "../RenameFolderModal";
import OrganizationContext from "../../contexts/OrganizationContext";

import styles from "./reports-list-view.module.css";

const ReportsListView = ({
  header,
  loading,
  onRename,
  navHolder,
  folderData,
  onFileDelete,
  onFolderDelete,
  useFileFolderDeleteDialog,
}) => {
  const [renameModalState, setRenameModalState] = useState({
    isVisible: false,
    details: null,
  });

  const navigate = useNavigate();
  const { service } = useContext(OrganizationContext);

  const onFolderClick = (itemType, item) => {
    if (itemType === "folder") {
      const { id } = item;

      navigate({
        search: `?folderId=${id}`,
      });
    }
  };

  const { ShowDeleteDialog } = useFileFolderDeleteDialog({
    onFileDelete,
    onFolderDelete,
  });

  const renderFileContextMenu = (type, item) => {
    const permissionedActions = [
      {
        title: "Rename",
        action: () => {
          setRenameModalState({
            isVisible: true,
            details: item,
          });
        },
      },
    ];

    // - Role-wise permissions or type-wise actions can be here.
    permissionedActions.push({
      title: "Delete",
      action: () => {
        ShowDeleteDialog(type, item?.id, item?.name);
      },
    });

    return [...permissionedActions];
  };

  const renderFileColumns = (item) => [
    {
      span: 15,
      heading: item?.name,
    },
    {
      span: 5,
      heading: (
        <StatusLabel
          type="date"
          value={{
            date: dayjs(item.createDate).format("MMM D, YYYY HH:mm"),
            username: item?.createdBy?.name,
          }}
        />
      ),
    },
  ];

  const renderFiles = () => {
    const filesAndFolders = [...folderData?.folders, ...folderData?.files];

    return filesAndFolders?.map((item) => {
      const itemType = item.link ? "file" : "folder";

      return (
        <div key={item.id} style={{ position: "relative" }}>
          <File
            file={{
              type: itemType,
              columns: [...renderFileColumns(item)],
            }}
            onClick={() => onFolderClick(itemType, item)}
            primaryAction={
              itemType !== "folder" ? (
                <Button
                  type="link"
                  target="_blank"
                  href={getFilePath(item.link)}
                  className={styles.link}
                  style={{ paddingTop: "0.375rem !important" }}
                  rel="noreferrer"
                >
                  Download
                </Button>
              ) : null
            }
            contextMenu={[...renderFileContextMenu(itemType, item)]}
          />
          {itemType === "file" && item.uploading ? (
            <div className={styles["file-uploading"]}>
              <Loader tip="Uploading" className={styles.loader} />
            </div>
          ) : null}
        </div>
      );
    });
  };

  const renderBackButton = () => (
    <div>
      {folderData?.parentId ? (
        <div className={styles["previous-page-link-container"]}>
          <div
            className={styles["previous-page-link"]}
            onClick={() => {
              navigate({
                search: `?folderId=${folderData.parentId}`,
              });
            }}
          >
            ...
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <Appshell>
        {(() => {
          // Todo: Fix Loader styles
          if (!service) {
            return (
              <div className="p-8">
                <Loader tip="Loading" />
              </div>
            );
          }

          return (
            <div>
              {header}
              <div className={styles.holder}>
                {navHolder}
                <section className="mt-8">
                  {(() => {
                    if (loading === "loading_data") {
                      return <File.FileSkeleton />;
                    }

                    if (
                      !folderData?.files?.length &&
                      !folderData?.folders?.length
                    ) {
                      return (
                        <>
                          {renderBackButton()}
                          <Empty
                            description={<p level={3}>No Reports added!</p>}
                          />
                        </>
                      );
                    }

                    return (
                      <div>
                        {renderBackButton()}
                        {renderFiles()}
                      </div>
                    );
                  })()}
                </section>
              </div>
            </div>
          );
        })()}
      </Appshell>
      {renameModalState.isVisible && (
        <RenameFolderModal
          {...renameModalState}
          handleOk={async (...rest) => {
            await onRename(...rest);

            setRenameModalState({
              isVisible: false,
              details: null,
            });
          }}
          loading={loading === "loading_rename"}
          handleCancel={() => {
            setRenameModalState({
              isVisible: false,
              details: null,
            });
          }}
        />
      )}
    </>
  );
};

ReportsListView.defaultProps = {
  folderData: PropTypes.shape({
    id: null,
    folders: [],
    files: [],
    parentId: null,
  }),
  loading: null,
  onRename: () => {},
  onFileDelete: () => {},
  onFolderDelete: () => {},
  useFileFolderDeleteDialog: () => {},
  navHolder: null,
  header: null,
};

ReportsListView.propTypes = {
  folderData: PropTypes.shape({
    id: PropTypes.string,
    folders: PropTypes.array,
    files: PropTypes.array,
    parentId: PropTypes.string,
  }),
  loading: PropTypes.oneOf(["loading_data", "loading_rename"]),
  onRename: PropTypes.func,
  onFileDelete: PropTypes.func,
  onFolderDelete: PropTypes.func,
  navHolder: PropTypes.node,
  header: PropTypes.node,
  useFileFolderDeleteDialog: PropTypes.func,
};

export default ReportsListView;
