import React from "react";
import { Empty, Typography } from "@cea/ui-kit";

import ComingSoonImage from "../../assets/coming-soon.svg";

import styles from "./coming-soon.module.css";

const { Title, Paragraph } = Typography;

const ComingSoon = () => (
  <div className={styles.holder}>
    <Empty
      className={styles["empty-main"]}
      image={ComingSoonImage}
      imageStyle={{
        height: 200,
      }}
      description={
        <div className={styles.content}>
          <Title level={2}>Coming Soon!</Title>
          <Paragraph>
            Our engineers are working on launching this page.
            <br />
            Stay Tuned!
          </Paragraph>
        </div>
      }
    />
  </div>
);

export default ComingSoon;
