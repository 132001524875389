import { sidebarConfig } from "./constants";

const getOpenSidebarKeys = (currentLocation) => {
  const subUrlName = currentLocation
    .split(process.env.REACT_APP_FRONTEND_URL)[0]
    .split("/")[1];

  return Object.keys(sidebarConfig).includes(subUrlName) ? "services" : "";
};

export default { getOpenSidebarKeys };
