export const addQueryParam = (url, params) => {
  if (!url || typeof url !== "string") {
    console.error("Invalid URL: The URL must be a non-empty string.");

    return "";
  }

  if (!params || typeof params !== "object") {
    console.error("Invalid Params: Params must be an object.");

    return url;
  }

  try {
    const urlObj = new URL(url, window.location.origin);

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        urlObj.searchParams.set(key, value);
      } else {
        urlObj.searchParams.delete(key);
      }
    });

    return `${urlObj.pathname}${urlObj.search}`;
  } catch (error) {
    console.error("Invalid URL:", error);

    return url;
  }
};
