/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  Form,
  Card,
  Input,
  Select,
  Button,
  Loader,
  Divider,
  Breadcrumb,
  Notification,
  ManagementTable,
} from "@cea/ui-kit";

import { getNotification } from "../../../utils";
import Appshell from "../../../components/Appshell";
import fetchProjectDetails from "../utils/fetchProjectDetails";
import PageSearch from "../../../components/PageSearch/PageSearch";
import OrganizationContext from "../../../contexts/OrganizationContext";
import {
  FETCH_PROJECT_STATUSES,
  UPDATE_QA_PROJCET,
} from "../../../queries/qaServices";

import styles from "./project-details.module.css";

const { Meta } = Card;
const { Option } = Select;

const serviceLines = [
  { label: "Container Loading", value: "containerLoading" },
  { label: "Lab Testing", value: "labTesting" },
  { label: "PSR", value: "psr" },
  { label: "Other Project Files", value: "otherProjectFiles" },
];

const RiskStatusData = {
  HIGH_RISK: "High Risk",
  NONE: "Not Set",
  LOW: "Low",
  MODERATE: "Moderate",
};

const ProjectDetails = () => {
  const [loadingProject, setLoadingProject] = useState(true);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [subProjects, setSubProjects] = useState({ data: [], total: 0 });

  const [form] = Form.useForm();
  const apolloClient = useApolloClient();
  const { organizationId, projectId } = useParams();
  const {
    organization,
    service,
    fetchOrganizationDetails,
    fetchServiceDetails,
    updateServiceMeta,
    loading,
    serviceMeta,
  } = useContext(OrganizationContext);

  const [updateQAProject, { loading: updateLoading }] =
    useMutation(UPDATE_QA_PROJCET);

  const fetchProject = async () => {
    setLoadingProject(true);

    try {
      const { qaProject, qaSubProjects } = await fetchProjectDetails({
        apolloClient,
        projectId,
      });

      setSubProjects({ data: qaSubProjects.data, total: qaSubProjects.total });
      updateServiceMeta(qaProject);
      setLoadingProject(false);

      const { data: statusData } = await apolloClient.query({
        query: FETCH_PROJECT_STATUSES,
      });

      const { qaProjectStatuses } = statusData;

      setProjectStatuses(qaProjectStatuses.data);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      form.resetFields();
    }
  };

  useEffect(() => {
    fetchProject();

    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }

    if (!service) {
      fetchServiceDetails({ serviceType: "qa" });
    }
  }, [organization]);

  const onProjectChange = (name, value) => {
    updateServiceMeta({
      ...serviceMeta,
      [name]: value,
    });
  };

  const onProjectSave = async ({
    CP_Progress,
    CP_ProjectStatusID,
    CP_RiskStatus,
  }) => {
    try {
      await updateQAProject({
        variables: {
          PID: +projectId,
          CP_Progress,
          CP_ProjectStatusID,
          CP_RiskStatus,
        },
        notifyOnNetworkStatusChange: true,
      });

      Notification.success({ message: "Project details updated" });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const subProjectColumns = [
    {
      title: "Sub Project Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Actions",
      dataIndex: "SubProjectID",
      key: "SubProjectID",
      width: 200,
      render: (SubProjectID) => (
        <Link
          to={`/organizations/${organizationId}/services/qa/projects/${projectId}/subProjects/${SubProjectID}/reports`}
        >
          View Reports
        </Link>
      ),
    },
  ];

  const summaryReportColumns = [
    {
      title: "Summary Reports",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Actions",
      dataIndex: "value",
      key: "value",
      width: 200,
      render: (serviceName) => (
        <Link
          to={`/organizations/${organizationId}/services/qa/projects/${projectId}/reports/${serviceName}`}
        >
          View Reports
        </Link>
      ),
    },
  ];

  return (
    <Appshell>
      <PageSearch isSearchEnabled={true} />
      <div className="page-main-container">
        <div className="breadcrumb-holder">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/organizations/${organization?.id}`}>
                {organization?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services">Services</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organizations/${organization?.id}/services/${service?.id}`}
              >
                {service?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organizations/${organization?.id}/services/qa/projects/${serviceMeta?.PID}`}
              >
                {serviceMeta?.Name}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {(() => {
          if (loading || loadingProject) {
            return (
              <div className="standard-padding">
                <Loader tip="Loading" />
              </div>
            );
          }

          return (
            <div>
              <div>
                <Card>
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ ...serviceMeta }}
                    requiredMark={true}
                    onFinish={onProjectSave}
                  >
                    <div className="flex items-center justify-between">
                      <Meta
                        title="Project Info"
                        description="Project based information"
                      />

                      <Button
                        htmlType="submit"
                        type="primary"
                        disabled={
                          !form.isFieldsTouched(false) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                        loading={loading || updateLoading}
                        className="theme-button"
                      >
                        + Save
                      </Button>
                    </div>

                    <div className="mt-8">
                      <div className="flex">
                        <div
                          className={`flex flex-col mr-12 ${styles["custom-status-select"]}`}
                        >
                          <label>Starting Date</label>
                          <p className="mt-3 font-semibold">
                            (Monday) Mar 19, 2021
                          </p>
                        </div>

                        <Divider type="vertical" className={styles.divider} />

                        <Form.Item
                          name="CP_ProjectStatusID"
                          label="Project Status"
                          style={{ width: 150 }}
                          className={styles["custom-status-select"]}
                        >
                          <Select
                            onChange={(value) =>
                              onProjectChange("CP_ProjectStatusID", value)
                            }
                          >
                            {projectStatuses.map((status) => (
                              <Option
                                value={status.ProjectStatusID}
                                key={status.ProjectStatusID}
                              >
                                {status.Name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Divider type="vertical" className={styles.divider} />

                        <Form.Item
                          name="CP_RiskStatus"
                          label="Risk Status"
                          style={{ width: 150 }}
                          className={styles["custom-status-select"]}
                        >
                          <Select
                            onChange={(value) =>
                              onProjectChange("CP_RiskStatus", value)
                            }
                          >
                            {Object.keys(RiskStatusData).map((key) => (
                              <Option value={RiskStatusData[key]} key={key}>
                                {RiskStatusData[key]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Divider type="vertical" className={styles.divider} />
                        <Form.Item
                          label="Progress (in %)"
                          name="CP_Progress"
                          className={styles["custom-status-select"]}
                        >
                          <Input
                            style={{ width: 100 }}
                            type="text"
                            onChange={(e) => onProjectChange("progress", e)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </Card>
              </div>
              <div className="mt-8">
                <ManagementTable
                  loading={loading}
                  columns={subProjectColumns}
                  data={subProjects.data}
                  pageSize={25}
                  listSize={5}
                />
              </div>
              <div className="mt-8">
                <ManagementTable
                  columns={summaryReportColumns}
                  data={serviceLines}
                  pageSize={25}
                  listSize={5}
                />
              </div>
            </div>
          );
        })()}
      </div>
    </Appshell>
  );
};

export default ProjectDetails;
