import { Col, Row, Skeleton } from "antd";

const FileSkeleton = () => (
  <Row gutter={12} wrap={false} align="middle">
    <Col>
      <Skeleton.Avatar active shape="circle" size={50} />
    </Col>
    <Col span={8}>
      <Skeleton.Button active />
    </Col>
    <Col span={6}>
      <Skeleton.Button active />
    </Col>
    <Col span={5}>
      <Skeleton.Button active />
    </Col>
    <Col span={3}>
      <Skeleton.Button active />
    </Col>
  </Row>
);

export default FileSkeleton;
