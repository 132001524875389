import PropTypes from "prop-types";

const CheckCircle = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5741 12.4091H22.9253C22.5667 12.4091 22.2257 12.5813 22.0147 12.8766L16.4882 20.5407L13.9851 17.0673C13.7741 16.7755 13.4366 16.5997 13.0745 16.5997H11.4257C11.1972 16.5997 11.0636 16.8598 11.1972 17.0462L15.5776 23.1212C15.6811 23.2656 15.8175 23.3833 15.9756 23.4645C16.1336 23.5457 16.3087 23.588 16.4864 23.588C16.6641 23.588 16.8392 23.5457 16.9973 23.4645C17.1553 23.3833 17.2917 23.2656 17.3952 23.1212L24.7991 12.8555C24.9362 12.6692 24.8026 12.4091 24.5741 12.4091Z"
      fill={fill}
    />
    <path
      d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z"
      fill={fill}
    />
  </svg>
);

CheckCircle.defaultProps = {
  size: 64,
  fill: "black",
};

CheckCircle.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default CheckCircle;
