import { useState, useEffect, useContext } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  Row,
  Input,
  Button,
  Column,
  Breadcrumb,
  Notification,
  Select,
} from "@cea/ui-kit";

import {
  FETCH_QA_FOLDERS,
  RENAME_QA_FILE,
  RENAME_QA_FOLDER,
} from "../../../queries/qaServices";
import Appshell from "../../../components/Appshell";
import AuthContext from "../../../contexts/AuthContext";
import { getNotification, getOrderByObject, sortOptions } from "../../../utils";
import OrganizationContext from "../../../contexts/OrganizationContext";
import QAReportUploadModal from "../QAReportUploadModal/QAReportUploadModal";
import getServiceLineName from "../utils/getServiceName";

import ReportsList from "./ReportsList";
import styles from "./reports-list.module.css";
import CreateFolderModal from "./components/CreateFolderModal";

const QAClientFileShare = () => {
  const [loading, setLoading] = useState(false);
  const [folderData, setFolderData] = useState({});
  const [selectedOrder, setSelectedOrder] = useState("");
  const [isCreateFileModalVisible, setIsCreateFileModalVisible] =
    useState(false);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] =
    useState(false);

  const {
    service,
    organization,
    fetchOrganizationDetails,
    fetchServiceDetails,
  } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);
  const apolloClient = useApolloClient();
  const [queryParams, setQueryParams] = useSearchParams();
  const { organizationId } = useParams();

  const [renameQAFolder, { loading: folderRenameLoading }] =
    useMutation(RENAME_QA_FOLDER);
  const [renameQAFile, { loading: fileRenameLoading }] =
    useMutation(RENAME_QA_FILE);

  const orderBy = queryParams.get("sort") || "createDate:desc";
  const folderId = queryParams.get("folderId") || null;
  const isRenameFolderLoading =
    folderRenameLoading || fileRenameLoading ? "loading_rename" : null;

  const fetchFolders = async (folderId) => {
    try {
      setLoading("loading_data");

      const sortObject = getOrderByObject(orderBy);

      const { data } = await apolloClient.query({
        query: FETCH_QA_FOLDERS,
        variables: {
          where: {
            clientId: organizationId,
            serviceLine: "relatedFiles",
            id: folderId,
          },
          orderBy: sortObject,
        },
        fetchPolicy: "network-only",
      });

      if (data.getFolders) {
        setFolderData(data.getFolders);
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(null);
    }
  };

  useEffect(() => {
    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }

    if (!service) {
      fetchServiceDetails({ serviceType: "qa" });
    }

    fetchFolders(folderId);
  }, [organization, service, folderId, orderBy]);

  const onFolderCreate = (newFolder, parentFolder) => {
    if (!folderData.id) {
      setFolderData({
        ...parentFolder,
        folders: [
          {
            ...newFolder,
            __typename: "QAFolder",
            createdBy: user.name,
          },
        ],
        files: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
        serviceLine: ["relatedFiles"],
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        folders: [
          ...prevState.folders,
          {
            ...newFolder,
            __typename: "QAFolder",
            createdBy: user.name,
          },
        ],
      }));
    }
  };

  const onFileCreate = (newFiles, parentFolder) => {
    if (!folderData.id) {
      setFolderData({
        ...parentFolder,
        files: newFiles.map((item) => ({
          ...item,
          __typename: "QAFile",
        })),
        folders: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
        serviceLine: ["relatedFiles"],
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        files: [
          ...prevState.files,
          ...newFiles.map((item) => ({
            ...item,
            __typename: "QAFile",
          })),
        ],
      }));
    }
  };

  const onFolderOrFileRename = async (details, newName) => {
    try {
      if (details.__typename === "QAFolder") {
        await renameQAFolder({
          variables: {
            data: {
              id: details.id,
              name: newName,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          folders: prevState.folders.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      } else {
        await renameQAFile({
          variables: {
            data: {
              id: details.id,
              name: newName,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          files: prevState.files.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const showCreateFolderModal = () => {
    setIsCreateFolderModalVisible(true);
  };

  const handleChange = (value = "") => {
    setSelectedOrder(value || null);

    setQueryParams({ sort: value });
  };

  const header = (
    <div className={styles["reports-filter-container"]}>
      <Row gutter={[16, 32]} justify="space-between">
        <Column span={6}>
          <Input placeholder="Search File/ Folder" />
        </Column>
        <Column span={6}>
          <Select
            allowClear
            onChange={handleChange}
            placeholder="Sort by"
            value={selectedOrder}
            options={sortOptions}
            className={styles["search-input"]}
          />
        </Column>
      </Row>
    </div>
  );

  const navHolder = (
    <div className={styles["flex-container"]}>
      <div className="breadcrumb-holder">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/organizations/${organization?.id}`}>
              {organization?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/services">Services</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/organizations/${organization?.id}/services/${service?.id}`}
            >
              {service?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {getServiceLineName("relatedFiles")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles["actions-container"]}>
        <Button
          size="small"
          type="primary"
          className="theme-button"
          onClick={() => {
            setIsCreateFileModalVisible(true);
          }}
        >
          Upload File
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={showCreateFolderModal}
          className="theme-button"
        >
          Create Folder
        </Button>
      </div>
    </div>
  );

  return (
    <Appshell>
      <ReportsList
        header={header}
        navHolder={navHolder}
        loading={loading || isRenameFolderLoading}
        folderData={folderData}
        setFolderData={setFolderData}
        onRename={onFolderOrFileRename}
      />
      <CreateFolderModal
        summaryFolder
        isVisible={isCreateFolderModalVisible}
        handleCancel={() => {
          setIsCreateFolderModalVisible(false);
        }}
        parentId={folderId}
        handleOk={onFolderCreate}
        clientFolder
        clientId={organizationId}
      />
      <QAReportUploadModal
        isVisible={isCreateFileModalVisible}
        handleOk={onFileCreate}
        handleCancel={() => {
          setIsCreateFileModalVisible(false);
        }}
        clientId={organizationId}
        clientReport
      />
    </Appshell>
  );
};

export default QAClientFileShare;
