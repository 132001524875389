import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { Modal, Button, Notification, Icon } from "@cea/ui-kit";
import { useState } from "react";

import { getNotification } from "../../utils";

const DeleteModal = ({
  isVisible,
  resource,
  variables,
  handleOk,
  handleCancel,
  mutationName,
}) => {
  const [loading, setLoading] = useState(false);

  const apolloClient = useApolloClient();

  const onDelete = async () => {
    try {
      setLoading(true);

      const { data } = await apolloClient.mutate({
        mutation: mutationName,
        variables,
      });

      handleOk(data);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({ message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          <Icon name="delete" size={16} />
          Delete {resource}
        </div>
      }
      visible={isVisible}
      onOk={onDelete}
      onCancel={handleCancel}
      footer={[
        <Button
          key="ok"
          loading={loading}
          danger
          onClick={onDelete}
          className="ml-auto"
        >
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this {resource}?</p>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  isVisible: false,
  handleOk: () => {},
  handleCancel: () => {},
  resource: null,
};

DeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  resource: PropTypes.string,
  variables: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  mutationName: PropTypes.object.isRequired,
};

export default DeleteModal;
