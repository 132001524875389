import PropTypes from "prop-types";
import { Typography, Button, Row, Column } from "@cea/ui-kit";
import { useContext } from "react";

import LogoImage from "../../assets/logo.jpg";
import LoginCoverImage from "../../assets/login-cover.jpg";
import MicrosoftLogoImage from "../../assets/microsoft-logo.png";
import AuthContext from "../../contexts/AuthContext";

import classes from "./login.module.css";

const { Title } = Typography;

const Login = () => {
  const { handleLogin } = useContext(AuthContext);

  return (
    <div className={classes.main}>
      <Row>
        <Column span={14}>
          <div
            style={{
              backgroundImage: `url(${LoginCoverImage})`,
            }}
            className={classes.leftImageHolder}
          ></div>
        </Column>
        <Column span={10}>
          <div className={classes.rightContentHolder}>
            <div className={classes.logoHolder}>
              <img src={LogoImage} width={50} />
              <Title level={3} className={classes.logoText}>
                Welcome To CEA
              </Title>
            </div>
            <div className={classes.loginContent}>
              <div>
                <Title level={4}>Login to your account</Title>
                <Typography className={classes.loginText}>
                  Welcome to the CEA admin portal. Let’s get you access after
                  verifying your microsoft account.
                </Typography>

                <Button
                  className={classes.oAuthLoginButton}
                  onClick={handleLogin}
                  // disabled={loading}
                  // loading={loading}
                >
                  <img src={MicrosoftLogoImage} />
                  Continue with Microsoft
                </Button>
              </div>
            </div>
          </div>
        </Column>
      </Row>
    </div>
  );
};

Login.defaultProps = {
  history: {},
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Login;
