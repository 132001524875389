export function isCamelCase(str) {
  // Check for empty string or if the first character is not lowercase
  if (
    !str ||
    str[0] !== str[0].toLowerCase() ||
    str[0].toUpperCase() === str[0]
  ) {
    return false;
  }

  // Flag to check if there's at least one uppercase letter in the string
  let hasUpperCase = false;

  for (let i = 1; i < str.length; i++) {
    const char = str[i];

    // If character is uppercase, set the flag
    if (char.toUpperCase() === char && char.toLowerCase() !== char) {
      hasUpperCase = true;
    }

    // If character is not a letter (uppercase check followed by lowercase check implies it's not a letter)
    // or character is an uppercase following another uppercase, it's not camelCase
    if (
      (char.toLowerCase() === char && char.toUpperCase() === char) ||
      (char.toUpperCase() === char && str[i - 1].toUpperCase() === str[i - 1])
    ) {
      return false;
    }
  }

  // Ensure that the string has at least one uppercase letter to be considered camelCase
  return hasUpperCase;
}
