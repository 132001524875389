import { useEffect, useState, useContext } from "react";
import {
  Icon,
  Input,
  Avatar,
  Button,
  Select,
  Breadcrumb,
  Notification,
  ManagementTable,
} from "@cea/ui-kit";
import { useApolloClient } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Appshell from "../../components/Appshell";
import AuthContext from "../../contexts/AuthContext";
import { usePagination, useQuery } from "../../hooks";
import { FETCH_SERVICES } from "../../queries/services";
import { colorsMap, getUserInitials } from "../../utils";
import OrganizationContext from "../../contexts/OrganizationContext";
import { addQueryParam } from "../../utils/addQueryParams";

import styles from "./services.module.css";

const { Option } = Select;

const Services = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [services, setServices] = useState({ data: [], total: 0 });

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const { user } = useContext(AuthContext);
  const { currentPage, pageLimit, onPaginationChange } = usePagination();
  const { updateServiceDetails } = useContext(OrganizationContext);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, record) => (
        <Link to={`/services/${record.id}`}>
          <div className="text-[#000000a6] hover:text-black">{value}</div>
        </Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      render: (value) => <div className="font-semibold">{value[0]}</div>,
    },
    {
      title: "Admins",
      dataIndex: "admins",
      key: "admins",
      render: (value) => (
        <Avatar.Group maxCount={3}>
          {value.map((item) => (
            <Avatar
              key={item.name}
              style={{
                backgroundColor: colorsMap[item.name[0].toLowerCase()],
                verticalAlign: "middle",
              }}
            >
              {getUserInitials(item.name)}
            </Avatar>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Actions",
      align: "left",
      key: "action",
      width: 150,
      render: (record, serviceRecord) => {
        const currentServiceAdmin = serviceRecord.admins.find(
          (item) => item.id === user.id
        );

        const viewServiceLink = serviceRecord.hasSharedDirectory
          ? `/services/${serviceRecord.serviceType}`
          : `/services/${record.id}/select-organization`;

        return (
          <div key={record.id}>
            <Link to={viewServiceLink}>
              <div className="standard-flex gap-1 text-center mb-2">
                View Service
                <Icon name="arrow_lined_right" size={14} color="#1890ff" />
              </div>
            </Link>
            {user.isSuperAdmin || user.isSalesAdmin || currentServiceAdmin ? (
              <Link className="pl-2" to={`/services/${record.id}`}>
                Edit Service
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const fetchServices = async (variables = {}) => {
    try {
      const page = query.get("page") || currentPage;
      const text = query.get("searchText") || searchValue;

      setLoading(true);

      const { data } = await apolloClient.query({
        query: FETCH_SERVICES,
        variables: {
          limit: pageLimit,
          offset: (page - 1) * pageLimit,
          where: {
            name: text,
            ...variables,
          },
        },
        fetchPolicy: "network-only",
      });

      const { services, total } = data.services;

      // Todo: fetch categories  from another api
      const categories = [];

      services.map((item) => categories.push(...item.categories));

      setServices({ data: services, total });
      setCategories([...new Set(categories)]);
    } catch (error) {
      Notification.error({
        description: "Error while fetching data!",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateServiceDetails(null);
  }, []);

  useEffect(() => {
    fetchServices();
  }, [currentPage]);

  const onPaginationUpdate = (page) => {
    onPaginationChange(page);

    const text = query.get("searchText") || "";
    const { pathname, search } = location;

    const paramsObj = { page: page, searchText: text };

    navigate(addQueryParam(pathname + search, paramsObj));
  };

  const onSearch = (event) => {
    const inputValue = event.target.value;

    setSearchValue(inputValue);
    onPaginationChange(1);

    const { pathname, search } = location;

    const paramsObj = {
      page: 1,
      searchText: inputValue.toLowerCase(),
    };

    navigate(addQueryParam(pathname + search, paramsObj));

    fetchServices({ name: inputValue });
  };

  const onFilterChange = (filterType) => (value) => {
    fetchServices({ [filterType.toLowerCase()]: value });
  };

  return (
    <Appshell>
      <div className="h-full">
        <div className={styles["filters-container"]}>
          <Input
            placeholder="Search Services"
            value={searchValue}
            onChange={onSearch}
            size="large"
          />

          <div className="grid grid-cols-2 items-center gap-4">
            <div>
              <div className={styles.filter}>
                <div className={styles["filter-name"]}>Category</div>
                <Select
                  placeholder="Category"
                  allowClear
                  onSelect={onFilterChange("Category")}
                >
                  {categories.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="standard-padding">
          <div className={styles.actions}>
            <Breadcrumb>
              <Breadcrumb.Item>Services</Breadcrumb.Item>
            </Breadcrumb>
            {user.isSuperAdmin || user.isSalesAdmin ? (
              <Button
                type="primary"
                onClick={() => {
                  navigate("/services/create");
                }}
                className="gap-1"
                icon={<Icon name="plus" size={14} color="#fff" />}
              >
                Create Service
              </Button>
            ) : null}
          </div>
          <ManagementTable
            loading={loading}
            columns={columns}
            data={services.data}
            pageSize={pageLimit}
            currentPage={currentPage}
            listSize={services.total}
            onPageChange={onPaginationUpdate}
            isPaginated
          />
        </div>
      </div>
    </Appshell>
  );
};

export default Services;
