import { useState } from "react";
import PropTypes from "prop-types";
import { Notification } from "@cea/ui-kit";
import { useNavigate } from "react-router-dom";

import { getNotification } from "../../utils";
import { FETCH_SERVICE_DETAILS } from "../../queries/services";
import OrganizationContext from "../../contexts/OrganizationContext";
import { GET_ORGANIZATION_DETAILS } from "../../queries/organizations";

const OrganizationHolder = ({ children, client }) => {
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState(null);
  const [serviceMeta, setServiceMeta] = useState(null);
  const [organization, setOrganization] = useState(null);

  const navigate = useNavigate();

  const fetchOrganizationDetails = async (id) => {
    try {
      setLoading(true);

      const { data } = await client.query({
        query: GET_ORGANIZATION_DETAILS,
        variables: {
          id,
        },
        fetchPolicy: "network-only",
      });

      const { organization } = data;

      setOrganization(organization);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchServiceDetails = async (variables) => {
    try {
      setLoading(true);

      const { data } = await client.query({
        query: FETCH_SERVICE_DETAILS,
        variables,
        fetchPolicy: "network-only",
      });

      const { serviceDetails } = data;

      setService(serviceDetails);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });

      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const updateOrganizationDetails = (organizationDetails) => {
    setOrganization(organizationDetails);
  };

  const updateServiceDetails = (serviceDetails) => {
    setService(serviceDetails);
  };

  const updateServiceMeta = (serviceMetaFields) => {
    setServiceMeta(serviceMetaFields);
  };

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        service,
        serviceMeta,
        loading,
        fetchOrganizationDetails,
        fetchServiceDetails,
        updateServiceDetails,
        updateOrganizationDetails,
        updateServiceMeta,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationHolder.defaultProps = {
  children: null,
  client: null,
};

OrganizationHolder.propTypes = {
  children: PropTypes.node,
  client: PropTypes.shape({
    query: PropTypes.func,
  }),
};

export default OrganizationHolder;
