import PropTypes from "prop-types";

const Location = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2925 5.67501L20.4087 33.602C19.8553 34.9026 17.9745 34.7859 17.5862 33.4267L14.4789 22.5513C14.3366 22.053 13.947 21.6634 13.4487 21.5211L2.57326 18.4138C1.21415 18.0255 1.09736 16.1447 2.398 15.5913L30.325 3.70746C31.5675 3.17873 32.8213 4.43251 32.2925 5.67501Z"
      fill={fill}
    />
  </svg>
);

Location.defaultProps = {
  size: 64,
  fill: "black",
};

Location.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Location;
