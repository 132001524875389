import PropTypes from "prop-types";

const Inbox = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.1204 15.6832L31.1134 15.6551L27.1689 5.63203C26.9931 5.06602 26.4693 4.67578 25.8751 4.67578H9.88606C9.2884 4.67578 8.75754 5.07305 8.58879 5.64609L4.9009 15.5672L4.89035 15.5918L4.88332 15.6199C4.83762 15.7922 4.82355 15.968 4.84816 16.1402C4.84465 16.1965 4.84113 16.2527 4.84113 16.309V29.1727C4.84206 29.7393 5.06756 30.2824 5.46822 30.6831C5.86888 31.0837 6.41202 31.3092 6.97863 31.3102H29.0286C30.2064 31.3102 31.1661 30.3504 31.1696 29.1727V16.309C31.1696 16.2633 31.1696 16.2176 31.1661 16.1789C31.1802 16.0066 31.1661 15.8414 31.1204 15.6832ZM20.7212 14.1715L20.7107 14.7234C20.6825 16.302 19.5927 17.3637 18.0001 17.3637C17.2232 17.3637 16.5552 17.1141 16.0736 16.6395C15.5919 16.1648 15.3282 15.5039 15.3142 14.7234L15.3036 14.1715H8.06848L10.8634 7.37578H24.8978L27.77 14.1715H20.7212ZM7.53762 16.8715H13.0677C13.922 18.8789 15.7396 20.0637 18.0036 20.0637C19.1884 20.0637 20.2888 19.7332 21.1782 19.1074C21.9587 18.559 22.5669 17.7926 22.9607 16.8715H28.4626V28.6102H7.53762V16.8715Z"
      fill={fill}
    />
  </svg>
);

Inbox.defaultProps = {
  size: 64,
  fill: "black",
};

Inbox.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Inbox;
