import PropTypes from "prop-types";

const CheckFilled = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2C13.7564 2 9.68678 3.68568 6.68623 6.68623C3.68568 9.68713 2 13.7568 2 18C2 22.2432 3.68568 26.3132 6.68623 29.3138C9.68713 32.3143 13.7568 34 18 34C22.2432 34 26.3132 32.3143 29.3138 29.3138C32.3143 26.3129 34 22.2432 34 18C34 15.1912 33.2606 12.432 31.8564 9.99955C30.4521 7.56715 28.4323 5.54735 25.9999 4.14311C23.5675 2.73886 20.8088 2 18 2ZM16.2299 25.0704L9.86991 18.7051L12.6999 15.8751L16.2352 19.4104L23.3051 12.3451L26.1351 15.1751L16.2299 25.0704Z"
      fill={fill}
    />
  </svg>
);

CheckFilled.defaultProps = {
  size: 64,
  fill: "black",
};

CheckFilled.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default CheckFilled;
