import PropTypes from "prop-types";

const Edit = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.78212 27.6401C7.86248 27.6401 7.94283 27.632 8.02319 27.62L14.7812 26.4347C14.8616 26.4186 14.9379 26.3825 14.9942 26.3222L32.0259 9.29051C32.0631 9.25334 32.0927 9.20919 32.1128 9.16059C32.133 9.11198 32.1434 9.05988 32.1434 9.00726C32.1434 8.95463 32.133 8.90253 32.1128 8.85392C32.0927 8.80532 32.0631 8.76117 32.0259 8.724L25.3482 2.0423C25.2719 1.96596 25.1714 1.92578 25.0629 1.92578C24.9544 1.92578 24.854 1.96596 24.7777 2.0423L7.74596 19.074C7.68569 19.1343 7.64953 19.2066 7.63346 19.2869L6.44819 26.045C6.4091 26.2602 6.42307 26.4817 6.48888 26.6904C6.55469 26.899 6.67036 27.0884 6.82587 27.2423C7.09105 27.4994 7.42453 27.6401 7.78212 27.6401ZM10.4902 20.6329L25.0629 6.06417L28.008 9.00926L13.4352 23.578L9.86337 24.2088L10.4902 20.6329ZM32.7852 31.0151H3.21381C2.50265 31.0151 1.9281 31.5896 1.9281 32.3008V33.7472C1.9281 33.924 2.07274 34.0686 2.24953 34.0686H33.7495C33.9263 34.0686 34.071 33.924 34.071 33.7472V32.3008C34.071 31.5896 33.4964 31.0151 32.7852 31.0151Z"
      fill={fill}
    />
  </svg>
);

Edit.defaultProps = {
  size: 64,
  fill: "black",
};

Edit.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Edit;
