import PropTypes from "prop-types";

const SortDescending = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.1621 14.8586L27.5219 3.47602C27.4983 3.39985 27.4507 3.33332 27.3863 3.28629C27.3219 3.23925 27.2441 3.2142 27.1643 3.21486H24.0545C23.8898 3.21486 23.7492 3.31932 23.6969 3.47602L20.0286 14.8586C20.0166 14.8948 20.0085 14.9349 20.0085 14.9751C20.0085 15.18 20.1773 15.3488 20.3822 15.3488H22.6483C22.817 15.3488 22.9617 15.2363 23.0099 15.0756L23.713 12.6006H27.2889L27.984 15.0716C28.0282 15.2323 28.1768 15.3448 28.3456 15.3448H30.8045C30.8447 15.3448 30.8809 15.3407 30.917 15.3287C31.0134 15.2966 31.0898 15.2323 31.138 15.1439C31.1822 15.0555 31.1902 14.955 31.1621 14.8586ZM24.0786 10.5073L25.3965 5.81039H25.6496L26.9393 10.5073H24.0786ZM29.8442 30.3113H24.6371V30.2952L29.9648 22.7055C30.009 22.6412 30.0331 22.5689 30.0331 22.4885V21.026C30.0331 20.8211 29.8643 20.6524 29.6594 20.6524H21.463C21.2581 20.6524 21.0893 20.8211 21.0893 21.026V22.7537C21.0893 22.9586 21.2581 23.1274 21.463 23.1274H26.3889V23.1434L21.0411 30.7332C20.9965 30.7967 20.9726 30.8725 20.9728 30.9501V32.4126C20.9728 32.6175 21.1416 32.7863 21.3465 32.7863H29.8402C30.0451 32.7863 30.2139 32.6175 30.2139 32.4126V30.6849C30.2144 30.6361 30.2053 30.5876 30.1869 30.5423C30.1686 30.4969 30.1414 30.4557 30.1071 30.421C30.0727 30.3862 30.0317 30.3586 29.9866 30.3398C29.9415 30.321 29.8931 30.3113 29.8442 30.3113ZM9.89558 4.14298C9.86552 4.10456 9.8271 4.07349 9.78324 4.05213C9.73939 4.03076 9.69124 4.01965 9.64246 4.01965C9.59367 4.01965 9.54553 4.03076 9.50167 4.05213C9.45782 4.07349 9.4194 4.10456 9.38933 4.14298L4.88933 9.84432C4.72058 10.0573 4.87326 10.3666 5.14246 10.3666H8.19603V31.6613C8.19603 31.8381 8.34067 31.9827 8.51746 31.9827H10.7675C10.9442 31.9827 11.0889 31.8381 11.0889 31.6613V10.3666H14.1425C14.4117 10.3666 14.5643 10.0532 14.3956 9.84432L9.89558 4.14298Z"
      fill={fill}
    />
  </svg>
);

SortDescending.defaultProps = {
  size: 64,
  fill: "black",
};

SortDescending.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default SortDescending;
