import { gql } from "@apollo/client";

export const FETCH_QA_PROJECTS = gql`
  query qaProjects($limit: Int, $offset: Int, $where: QAProjectsWhereInput!) {
    qaProjects(limit: $limit, offset: $offset, where: $where) {
      data {
        Name
        PID
        CP_RiskStatus
        CP_Progress
        CP_ProjectStatusID
        PROJECT_STATUS {
          ProjectStatusID
          Name
        }
        LastUpdate
        LastUpdatedBy {
          DisplayName
        }
      }
      total
    }
  }
`;

export const FETCH_QA_PROJECT = gql`
  query qaProject($PID: Int!) {
    qaProject(where: { PID: $PID }) {
      Name
      PID
      CP_RiskStatus
      CP_ProjectStatusID
      CP_Progress
      PROJECT_STATUS {
        ProjectStatusID
        Name
      }
      IncludesFA
      IncludesIPM
      IncludesPSI
      IncludesFieldTesting
      IncludesLabTesting
      IncludesProductionTracking
      IncludesCL
      LastUpdatedBy {
        DisplayName
      }
    }

    qaSubProjects(where: { ProjectID: $PID }) {
      data {
        SubProjectID
        Name
        ProjectID
      }
      total
    }
  }
`;

export const CHECK_SUMMARY_REPORTS = gql`
  query GetSummaryReports($projectId: Int!) {
    getSummaryReports(projectId: $projectId) {
      summaryServices
    }
  }
`;

export const FETCH_SUB_PROJECTS = gql`
  query FetchSubProjects($Name: String, $ProjectID: Int!) {
    qaSubProjects(where: { Name: $Name, ProjectID: $ProjectID }) {
      data {
        SubProjectID
        Name
        ProjectID
        IncludesFA
        IncludesCL
        IncludesIPM
        IncludesPSI
        IncludesProductionTracking
      }
      total
    }
  }
`;

export const FETCH_SUB_PROJECT = gql`
  query GetSubProject($id: ID!) {
    qaSubProject(id: $id) {
      SubProjectID
      Name
      ProjectID
      IncludesFA
      IncludesCL
      IncludesIPM
      IncludesPSI
      IncludesProductionTracking
    }
  }
`;

export const FETCH_PROJECT_STATUSES = gql`
  query {
    qaProjectStatuses {
      data {
        Name
        ProjectStatusID
      }
    }
  }
`;

export const UPDATE_QA_PROJCET = gql`
  mutation updateQAServiceProject(
    $PID: Int!
    $CP_Progress: String
    $CP_ProjectStatusID: Int
    $CP_RiskStatus: String
  ) {
    updateQAServiceProject(
      data: {
        PID: $PID
        CP_Progress: $CP_Progress
        CP_ProjectStatusID: $CP_ProjectStatusID
        CP_RiskStatus: $CP_RiskStatus
      }
    ) {
      message
    }
  }
`;

export const FETCH_PROJECT_SUPPLIERS = gql`
  query FetchSuppliers($name: String) {
    getSuppliers(where: { name: $name }) {
      SupplierID
      Supplier
    }
  }
`;

export const FETCH_PROJECT_FACTORIES = gql`
  query FetchFactories($name: String, $SupplierID: Int) {
    getFactories(where: { name: $name, SupplierID: $SupplierID }) {
      FactoryID
      Factory_Formal_Name
    }
  }
`;

export const FETCH_PROJECT_WORKSHOP = gql`
  query FetchWorkshops($name: String, $FactoryID: Int) {
    getWorkshops(where: { name: $name, FactoryID: $FactoryID }) {
      WorkshopID
      Factory_Workshop_Name
    }
  }
`;

export const FETCH_QA_FOLDERS = gql`
  query getFolders(
    $where: QAFolderWhereInput!
    $orderBy: QAFolderOrderByInput
  ) {
    getFolders(where: $where, orderBy: $orderBy) {
      id
      name
      projectId
      serviceLine
      clientId
      parentId
      folders {
        id
        name
        projectId
        createDate
        serviceLine
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        factory
        workshop
        supplier
        dayshift
        inspection
        serviceLine
        projectId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_QA_FILES = gql`
  query GetQAFiles($where: QAFileWhereInput, $orderBy: QAFolderOrderByInput!) {
    getQAFiles(where: $where, orderBy: $orderBy) {
      id
      name
      link
      factory
      workshop
      supplier
      dayshift
      inspection
      serviceLine
      projectId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const RENAME_QA_FOLDER = gql`
  mutation RenameQAFolder($data: UpdateFolderOrFileInput!) {
    updateQAFolder(data: $data) {
      id
      name
      projectId
      serviceLine
      parentId
      folders {
        id
        name
        projectId
        createDate
        serviceLine
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        factory
        workshop
        supplier
        dayshift
        inspection
        projectId
        serviceLine
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_QA_FILE = gql`
  mutation RenameQAFile($data: UpdateFolderOrFileInput!) {
    updateQAFile(data: $data) {
      id
      name
      link
      factory
      workshop
      supplier
      dayshift
      inspection
      serviceLine
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const CREATE_QA_FOLDER = gql`
  mutation CreateQAFolder(
    $name: String!
    $serviceLine: [ServiceLines!]
    $parentId: ID
    $projectId: Int!
    $subProject: Int!
  ) {
    createQAFolder(
      data: {
        name: $name
        serviceLine: $serviceLine
        parentId: $parentId
        projectId: $projectId
        subProject: $subProject
      }
    ) {
      message
      id
      parentId
      parentName
    }
  }
`;

export const CREATE_QA_SUMMARY_FOLDER = gql`
  mutation CreateQASummaryFolder(
    $name: String!
    $serviceLine: [ServiceLines!]
    $parentId: ID
    $projectId: Int!
  ) {
    createQASummaryFolder(
      data: {
        name: $name
        serviceLine: $serviceLine
        parentId: $parentId
        projectId: $projectId
      }
    ) {
      message
      id
      parentId
      parentName
    }
  }
`;

export const CREATE_QA_CLIENT_FOLDER = gql`
  mutation createQAClientFolder($name: String!, $parentId: ID, $clientId: ID!) {
    createQAClientFolder(
      data: { name: $name, parentId: $parentId, clientId: $clientId }
    ) {
      message
      id
      parentId
      parentName
    }
  }
`;

export const CREATE_QA_CLIENT_FILE = gql`
  mutation createQAClientFile($data: CreateQAClientFileInput) {
    createQAClientFile(data: $data) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_QA_FILE = gql`
  mutation CreateQAFile($data: CreateFileInput) {
    createQAFile(data: $data) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_QA_SUMMARY_FILE = gql`
  mutation CreateQASummaryFile($data: CreateSummaryFileInput) {
    createQASummaryFile(data: $data) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const DELETE_QA_FOLDER = gql`
  mutation deleteQAFolder($id: ID!) {
    deleteQAFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_QA_FILE = gql`
  mutation deleteQAFile($id: ID!) {
    deleteQAFile(id: $id) {
      message
      id
    }
  }
`;

export const FETCH_PROJECT_MANAGERS = gql`
  query FetchProjectManagers($where: GetPMInputWhere!) {
    getProjectManagers(where: $where) {
      id
      user {
        id
        name
        email
        avatar
      }
    }
  }
`;

export const ADD_PROJECT_MANAGER = gql`
  mutation AddPM($user: ID!, $subscription: ID!, $service: ID!) {
    addProjectManager(
      data: { user: $user, subscription: $subscription, service: $service }
    ) {
      serviceToPM {
        id
        user {
          id
          name
          email
          avatar
        }
      }
      message
    }
  }
`;

export const REMOVE_PROJECT_MANAGER = gql`
  mutation RemovePM($id: ID!, $service: ID!) {
    removeProjectManager(data: { id: $id, service: $service }) {
      id
      message
    }
  }
`;

export const DOWNLOAD_QA_FOLDER = gql`
  query downloadQAFolder($id: ID!) {
    downloadQAFolder(id: $id) {
      id
      message
    }
  }
`;

export const ON_QA_FOLDER_DOWNLOAD_PROGRESS = gql`
  subscription onQAFolderDownloadProgress($folderId: ID!) {
    onQAFolderDownloadProgress(folderId: $folderId) {
      link
      progress
      folderId
    }
  }
`;
