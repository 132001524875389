import PropTypes from "prop-types";

const Contact = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2373_16393)">
      <path
        d="M34.7143 3.85156H1.28571C0.574554 3.85156 0 4.42612 0 5.13728V30.8516C0 31.5627 0.574554 32.1373 1.28571 32.1373H34.7143C35.4254 32.1373 36 31.5627 36 30.8516V5.13728C36 4.42612 35.4254 3.85156 34.7143 3.85156ZM33.1071 29.2444H2.89286V6.74442H33.1071V29.2444ZM21.9496 16.548H26.9076C26.9598 16.548 27 16.4033 27 16.2266V14.298C27 14.1212 26.9598 13.9766 26.9076 13.9766H21.9496C21.8973 13.9766 21.8571 14.1212 21.8571 14.298V16.2266C21.8571 16.4033 21.8973 16.548 21.9496 16.548ZM22.1424 22.3337H29.6036C29.7603 22.3337 29.8888 22.1891 29.8888 22.0123V20.0837C29.8888 19.9069 29.7603 19.7623 29.6036 19.7623H22.1424C21.9857 19.7623 21.8571 19.9069 21.8571 20.0837V22.0123C21.8571 22.1891 21.9857 22.3337 22.1424 22.3337V22.3337ZM6.42857 24.4632H8.19241C8.36116 24.4632 8.49777 24.3306 8.50982 24.1618C8.6625 22.1328 10.358 20.5257 12.4152 20.5257C14.4723 20.5257 16.1679 22.1328 16.3205 24.1618C16.3326 24.3306 16.4692 24.4632 16.6379 24.4632H18.4018C18.4454 24.4632 18.4885 24.4544 18.5286 24.4373C18.5687 24.4201 18.6049 24.395 18.6349 24.3634C18.665 24.3318 18.6883 24.2945 18.7035 24.2536C18.7187 24.2127 18.7254 24.1692 18.7232 24.1257C18.6107 21.9842 17.4375 20.1199 15.7259 19.0592C16.4807 18.2294 16.8978 17.1473 16.8951 16.0257C16.8951 13.5386 14.8902 11.5257 12.4192 11.5257C9.94821 11.5257 7.9433 13.5386 7.9433 16.0257C7.9433 17.1949 8.38527 18.2556 9.1125 19.0592C8.24114 19.5991 7.51342 20.342 6.99147 21.2242C6.46953 22.1065 6.16887 23.102 6.11518 24.1257C6.09911 24.3105 6.24375 24.4632 6.42857 24.4632V24.4632ZM12.4152 13.9364C13.5603 13.9364 14.4924 14.8725 14.4924 16.0257C14.4924 17.1788 13.5603 18.115 12.4152 18.115C11.2701 18.115 10.3379 17.1788 10.3379 16.0257C10.3379 14.8725 11.2701 13.9364 12.4152 13.9364Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2373_16393">
        <rect width="36" height="36" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

Contact.defaultProps = {
  size: 64,
  fill: "black",
};

Contact.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Contact;
