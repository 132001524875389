import { BlobServiceClient } from "@azure/storage-blob";

const sasWrite = process.env.REACT_APP_SAS_WRITE;
const sasRead = process.env.REACT_APP_SAS_READ;
const containerName = process.env.REACT_APP_AZURE_STORAGE_ADMIN_CONTAINER_NAME;

const blobServiceClientWrite = new BlobServiceClient(
  `${process.env.REACT_APP_STORAGE_URL}?${sasWrite}`
);

const blobServiceClientRead = new BlobServiceClient(
  `${process.env.REACT_APP_STORAGE_URL}?${sasRead}`
);

export const uploadToAzure = async (file) => {
  const blobName = file.name;
  const containerClient =
    blobServiceClientWrite.getContainerClient(containerName);

  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  await blockBlobClient.upload(file, file.size);

  return { name: blobName, container: containerName };
};

// Used to convert a browser Blob into string.
async function blobToString(blob, blobName) {
  blob.lastModifiedDate = new Date();
  blob.name = blobName;

  return blob;
}

export const getFileFromAzure = async (fileName) => {
  const containerClient =
    blobServiceClientRead.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(fileName);

  // Get blob content from position 0 to the end
  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  const downloadBlockBlobResponse = await blobClient.download();
  const downloaded = await blobToString(
    await downloadBlockBlobResponse.blobBody,
    fileName
  );

  return downloaded;
};

export const imageUploader = async (info) => {
  const { file: uploadingFile } = info;

  const updatedFileName = `${new Date().getTime()}-${uploadingFile.name}`;

  const fileToUse = uploadingFile.originFileObj || uploadingFile;

  const newFile = new File([fileToUse], updatedFileName, {
    type: uploadingFile.type,
  });

  const { container, name } = await uploadToAzure(newFile);

  if (container && name) {
    return { updatedFileName, newFile };
  }
};

export const getFilePath = (name) =>
  `${process.env.REACT_APP_STORAGE_URL}${containerName}/${name}${sasRead}`;
