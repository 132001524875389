import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import {
  Card,
  Breadcrumb,
  Select,
  Icon,
  Notification,
  Loader,
} from "@cea/ui-kit";

import { getNotification } from "../../utils";
import Appshell from "../../components/Appshell";
import { GET_ORGANIZATIONS } from "../../queries/organizations";
import OrganizationContext from "../../contexts/OrganizationContext";

import styles from "./select-organization.module.css";

const { Option } = Select;

const SelectOrganization = () => {
  const [loading, setLoading] = useState(true);
  const [organizationsList, setOrganizationsList] = useState([]);

  const navigate = useNavigate();
  const { serviceId } = useParams();
  const apolloClient = useApolloClient();
  const { updateOrganizationDetails } = useContext(OrganizationContext);

  const fetchOrganizations = async (variables = {}) => {
    try {
      setLoading(true);

      const { data } = await apolloClient.query({
        query: GET_ORGANIZATIONS,
        variables: {
          ...variables,
          where: { serviceId },
        },
      });

      setOrganizationsList(data?.organizations?.organizations);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateOrganizationDetails(null);
    if (serviceId) {
      fetchOrganizations();
    } else {
      navigate("/");
    }
  }, []);

  const onFilterChange = (organizationId) => {
    navigate(`/organizations/${organizationId}/services/${serviceId}`);
  };

  return (
    <Appshell>
      {(() => {
        if (loading) {
          return (
            <div className="p-8">
              <Loader tip="Loading" />
            </div>
          );
        }

        return (
          <div className="page-main-container">
            <div className="breadcrumb-holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/services">Services</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Select Organization</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="mt-4">
              <Card>
                <Card.Meta
                  title="Select Organization"
                  description="Select an Organization to proceed."
                />
                <div className="mt-4">
                  <Select
                    allowClear
                    placeholder={
                      <div className="standard-flex gap-2">
                        <Icon
                          name="arrow_lined_right"
                          size={10}
                          color="#000000"
                        />
                        Select an Organization
                      </div>
                    }
                    onSelect={onFilterChange}
                    className={styles["organization-selector"]}
                  >
                    {organizationsList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Card>
            </div>
          </div>
        );
      })()}
    </Appshell>
  );
};

export default SelectOrganization;
