import PropTypes from "prop-types";

import classes from "./page-search.module.css";

const PageSearch = ({ isSearchEnabled = 0, onSearchChange }) => (
  <div className={classes.navbar}>
    {isSearchEnabled ? (
      <input
        className={classes.search}
        placeholder="Search..."
        onChange={onSearchChange}
      />
    ) : null}
  </div>
);

export default PageSearch;

PageSearch.defaultProps = {
  isSearchEnabled: 0,
  onSearchChange: null,
};

PageSearch.propTypes = {
  isSearchEnabled: PropTypes.bool,
  onSearchChange: PropTypes.func,
};
