import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  Row,
  Menu,
  Input,
  Button,
  Column,
  Select,
  Breadcrumb,
  Notification,
} from "@cea/ui-kit";
import { useApolloClient, useMutation } from "@apollo/client";
import { debounce } from "lodash-es";

import Appshell from "../../../components/Appshell";
import { getNotification, getOrderByObject, sortOptions } from "../../../utils";
import fetchProjectDetails from "../utils/fetchProjectDetails";
import fetchSubProjectDetails from "../utils/fetchSubProjectDetails";
import OrganizationContext from "../../../contexts/OrganizationContext";
import QAReportUploadModal from "../QAReportUploadModal/QAReportUploadModal";
import {
  FETCH_QA_FOLDERS,
  RENAME_QA_FOLDER,
  RENAME_QA_FILE,
  FETCH_QA_FILES,
} from "../../../queries/qaServices";
import AuthContext from "../../../contexts/AuthContext";

import ReportsList from "./ReportsList";
import styles from "./reports-list.module.css";
import CreateFolderModal from "./components/CreateFolderModal";

const serviceLinesOptions = [
  { label: "Inline Production Monitoring", value: "ipm" },
  { label: "Pre-Shipment Inspection", value: "psi" },
  { label: "Production Tracking", value: "productionTracking" },
  { label: "Factory Audit", value: "fa" },
];

const QAReportsList = () => {
  const [loading, setLoading] = useState(false);
  const [folderData, setFolderData] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loadingProject, setLoadingProject] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isCreateFileModalVisible, setIsCreateFileModalVisible] =
    useState(false);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] =
    useState(false);

  const apolloClient = useApolloClient();
  const { organizationId, projectId, subProjectId } = useParams();
  const {
    service,
    serviceMeta,
    organization,
    fetchOrganizationDetails,
    fetchServiceDetails,
    updateServiceMeta,
  } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);
  const [queryParams, setQueryParams] = useSearchParams();
  const { subProject } = fetchSubProjectDetails(subProjectId);

  const [renameQAFolder, { loading: folderRenameLoading }] =
    useMutation(RENAME_QA_FOLDER);
  const [renameQAFile, { loading: fileRenameLoading }] =
    useMutation(RENAME_QA_FILE);

  const orderBy = queryParams.get("sort") || "createDate:desc";
  const folderId = queryParams.get("folderId") || null;
  const serviceLines = queryParams.get("serviceLine") || null;
  const isRenameFolderLoading =
    folderRenameLoading || fileRenameLoading ? "loading_rename" : null;

  const fetchProject = async () => {
    setLoadingProject("loading_data");

    try {
      const { qaProject } = await fetchProjectDetails({
        apolloClient,
        projectId,
      });

      updateServiceMeta(qaProject);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingProject(null);
    }
  };

  const fetchFolders = async () => {
    if (serviceMeta) {
      try {
        setLoading("loading_data");

        const serviceLinesParam = serviceLines?.length
          ? serviceLines.split(",")
          : null;

        const sortObject = getOrderByObject(orderBy);

        const { data } = await apolloClient.query({
          query: FETCH_QA_FOLDERS,
          variables: {
            where: {
              projectId: +projectId,
              serviceLine: serviceLinesParam,
              id: folderId,
              subProject: +subProjectId,
            },
            orderBy: sortObject,
          },
          fetchPolicy: "network-only",
        });

        setFolderData(data.getFolders);
      } catch (error) {
        const message = getNotification(error);

        Notification.error({
          message,
        });
      } finally {
        setLoading(null);
      }
    }
  };

  const fetchFiles = async (variables = {}) => {
    try {
      setLoading("loading_data");

      const sortObject = getOrderByObject(orderBy);

      const { data } = await apolloClient.query({
        query: FETCH_QA_FILES,
        variables: {
          where: { ...variables, projectId },
          orderBy: sortObject,
        },
      });

      setFolderData({
        folders: [],
        files: data.getQAFiles,
      });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(null);
    }
  };

  useEffect(() => {
    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }

    if (!service) {
      fetchServiceDetails({ serviceType: "qa" });
    }

    if (!serviceMeta) {
      fetchProject();
    }

    if (inputValue.length) {
      fetchFiles({ name: inputValue });
    } else {
      fetchFolders(folderId);
    }
  }, [organization, service, serviceMeta, folderId, serviceLines, orderBy]);

  useEffect(() => {
    if (serviceLines) {
      const selectedServicesParams = serviceLines.split(",");
      const selectedServicesList = [];

      serviceLinesOptions.forEach((item) => {
        if (selectedServicesParams.includes(item.value)) {
          selectedServicesList.push(item.label);
        }
      });

      setSelectedServices(selectedServicesList);
    }
  }, []);

  const onFolderCreate = (newFolder, parentFolder) => {
    const serviceLinesParam = serviceLines?.split(",") || null;

    if (!folderData?.id) {
      setFolderData({
        ...parentFolder,
        folders: [
          {
            subProject: subProjectId,
            projectId,
            ...newFolder,
            __typename: "QAFolder",
            createdBy: user.name,
          },
        ],
        files: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
        subProject: subProjectId,
        projectId,
        serviceLine: serviceLinesParam,
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        folders: [
          ...prevState.folders,
          {
            subProject: subProjectId,
            projectId,
            ...newFolder,
            __typename: "QAFolder",
            createdBy: user.name,
          },
        ],
      }));
    }

    setIsCreateFolderModalVisible(false);
  };

  const onFileCreate = (newFiles, parentFolder) => {
    const serviceLinesParam = serviceLines?.split(",") || null;

    if (!folderData?.id) {
      setFolderData({
        ...parentFolder,
        files: newFiles.map((item) => ({
          ...item,
          __typename: "QAFile",
          createdBy: user.name,
        })),
        folders: [],
        parentId: null,
        id: parentFolder.parentId,
        name: parentFolder.parentName,
        subProject: subProjectId,
        projectId,
        serviceLine: serviceLinesParam,
      });
    } else {
      setFolderData((prevState) => ({
        ...prevState,
        files: [
          ...prevState.files,
          ...newFiles.map((item) => ({
            ...item,
            __typename: "QAFile",
            createdBy: user.name,
          })),
        ],
      }));
    }

    setIsCreateFileModalVisible(false);
  };

  const showCreateFolderModal = () => {
    setIsCreateFolderModalVisible(true);
  };

  const handleChange = (values) => {
    setSelectedServices(values);

    queryParams.set("serviceLine", values.join(","));
    setQueryParams(queryParams);
  };

  const onFolderOrFileRename = async (details, newName) => {
    try {
      if (details.__typename === "QAFolder") {
        await renameQAFolder({
          variables: {
            data: {
              id: details.id,
              name: newName,
              projectId: +details.projectId,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          folders: prevState.folders.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      } else {
        await renameQAFile({
          variables: {
            data: {
              id: details.id,
              name: newName,
              projectId: +details.projectId,
            },
          },
        });

        setFolderData((prevState) => ({
          ...prevState,
          files: prevState.files.map((item) => {
            if (item.id === details.id) {
              return {
                ...item,
                name: newName,
              };
            }

            return item;
          }),
        }));
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const debouncer = useCallback(
    debounce((searchValue) => fetchFiles(searchValue), 750),
    []
  );

  const onNameSearch = (event) => {
    const searchValue = event.target.value;

    setInputValue(searchValue);

    if (searchValue.length) {
      debouncer({ name: searchValue });
    } else {
      fetchFolders();
    }
  };

  const breadcrumbMenu = () => (
    <Menu>
      <Menu.Item>
        <Link
          to={`/organizations/${organization?.id}/services/qa/projects/${serviceMeta?.PID}`}
        >
          {serviceMeta?.Name}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const handleOrderChange = (value = "") => {
    setSelectedOrder(value || null);

    queryParams.set("sort", value);
    setQueryParams(queryParams);
  };

  const header = (
    <div className={styles["reports-filter-container"]}>
      <Row gutter={[16, 32]}>
        <Column span={6}>
          <Input
            placeholder="Search all Files"
            value={inputValue}
            onChange={onNameSearch}
          />
        </Column>
        <Column span={12}>
          <Select
            onChange={handleChange}
            placeholder="Select Service"
            mode="multiple"
            value={selectedServices}
            options={serviceLinesOptions}
            className="min-w-[35%]"
          />
        </Column>
        <Column span={6}>
          <Select
            allowClear
            onChange={handleOrderChange}
            placeholder="Sort by"
            value={selectedOrder}
            options={sortOptions}
            className={styles["search-input"]}
          />
        </Column>
      </Row>
    </div>
  );

  const navHolder = (
    <div className={styles["flex-container"]}>
      <div className="breadcrumb-holder">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/organizations/${organization?.id}`}>
              {organization?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/services">Services</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item overlay={breadcrumbMenu}>
            <Link
              to={`/organizations/${organization?.id}/services/${service?.id}`}
            >
              {service?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{subProject?.Name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className={styles["actions-container"]}>
        <Button
          size="small"
          type="primary"
          className="theme-button"
          onClick={() => {
            setIsCreateFileModalVisible(true);
          }}
        >
          Upload File
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={showCreateFolderModal}
          className="theme-button"
        >
          Create Folder
        </Button>
      </div>
    </div>
  );

  return (
    <Appshell>
      <ReportsList
        header={header}
        navHolder={navHolder}
        loading={loading || loadingProject || isRenameFolderLoading}
        folderData={folderData}
        setFolderData={setFolderData}
        onRename={onFolderOrFileRename}
      />
      <CreateFolderModal
        isVisible={isCreateFolderModalVisible}
        handleCancel={() => {
          setIsCreateFolderModalVisible(false);
        }}
        projectId={projectId}
        parentId={folderId}
        handleOk={onFolderCreate}
        summaryFolder={false}
      />
      <QAReportUploadModal
        isVisible={isCreateFileModalVisible}
        handleOk={onFileCreate}
        handleCancel={() => {
          setIsCreateFileModalVisible(false);
        }}
      />
    </Appshell>
  );
};

export default QAReportsList;
