import PropTypes from "prop-types";

const ArrowSolidUp = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.1962 24.2218L18.6509 10.8343C18.3204 10.4511 17.6841 10.4511 17.3501 10.8343L5.8048 24.2218C5.37589 24.721 5.76261 25.4522 6.45519 25.4522H29.5458C30.2384 25.4522 30.6251 24.721 30.1962 24.2218Z"
      fill={fill}
    />
  </svg>
);

ArrowSolidUp.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowSolidUp.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowSolidUp;
