import PropTypes from "prop-types";

const Folder = ({ size = 64, fill = "#FFA000" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.6 3.37479H15.3L11.9 0H3.4C1.53 0 0 1.51866 0 3.37479V10.1244H34V6.74959C34 4.89345 32.47 3.37479 30.6 3.37479Z"
      fill={fill}
    />
    <path
      d="M30.6 3.375H3.4C1.53 3.375 0 4.89366 0 6.74979V23.6238C0 25.4799 1.53 26.9986 3.4 26.9986H30.6C32.47 26.9986 34 25.4799 34 23.6238V6.74979C34 4.89366 32.47 3.375 30.6 3.375Z"
      fill={fill}
    />
  </svg>
);

Folder.defaultProps = {
  size: 64,
  fill: "black",
};

Folder.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Folder;
