import { Steps as AntSteps } from "antd";
import React from "react";
import PropTypes from "prop-types";

import styles from "./steps.module.css";

const Steps = ({ current, list, ...props }) => (
  <AntSteps className={styles.steps} current={current} {...props}>
    {list.map((item) => (
      <AntSteps.Step key={item.id} {...item} />
    ))}
  </AntSteps>
);

Steps.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  current: PropTypes.string,
};

export default Steps;
