const colorsMap = {
  a: "#B18FCF", // African Volet
  b: "#CAF0F8", // Blue (Powder)
  c: "#92BFB1", // Cambridge Blue
  d: "#635D5C", // Davys Grey
  e: "#CC444B", // English Vermillion
  f: "#885A89", // French Lilac
  g: "#CBEFB6", // Tea Green
  h: "#978897", // Heliotrope Gray
  i: "#DA5552", // Indian Red
  j: "#8C2155", // Jazzberry Jam
  k: "#D499B9", // Kobi
  l: "#D8D8F6", // Lavender Web
  m: "#BCAEC2", // Mimi Pink
  n: "#DCEED1", // Nyanza
  o: "#AF7595", // Opera Mauve
  p: "#564D80", // Purple Navy
  q: "#4D7298", // Queen Blue
  r: "#9B1D20", // Ruby Red
  s: "#2176AE", // Star Command Blue
  t: "#50013C", // Tyrian Purple
  u: "#FAD4C0", // Unbleached Silk
  v: "#A61C3C", // Vivid Burgundy
  w: "#98A6D4", // Wild Blue Yonder
  x: "#050013", // Xiketic
  y: "#F4aC45", // Yellow Orange
  z: "#2CA58D", // Zomp
};

export default colorsMap;
