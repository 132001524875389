import PropTypes from "prop-types";

const FilePdf = ({ size = 44, fill = "#000" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.1424 0H14.4767V10.9681C14.4767 11.0683 14.4361 11.1642 14.364 11.235C14.2921 11.3058 14.1941 11.3455 14.0922 11.3455H2.91138V18.1107H27.1425L27.1424 0Z"
      fill={fill}
    />
    <path d="M2.91132 31.1797H27.1425V33.9981H2.91132V31.1797Z" fill={fill} />
    <path d="M13.7075 0.53125L3.45319 10.5904H13.7075V0.53125Z" fill={fill} />
    <path
      d="M28.6809 18.8633H1.31916C0.969576 18.8641 0.634475 19.0009 0.38741 19.2433C0.140311 19.4857 0.000803584 19.8144 0 20.1574V29.1298C0.000803584 29.4727 0.140318 29.8011 0.38741 30.0438C0.634509 30.2862 0.969603 30.4228 1.31916 30.4238H28.6809C29.0304 30.4228 29.3655 30.2862 29.6126 30.0438C29.8597 29.8011 29.9992 29.4727 30 29.1298V20.1574C29.9992 19.8144 29.8597 19.4857 29.6126 19.2433C29.3655 19.0009 29.0304 18.8641 28.6809 18.8633ZM9.40722 25.6548H8.31126V27.9187C8.31126 28.0187 8.27074 28.1148 8.19855 28.1856C8.12637 28.2564 8.02843 28.2961 7.9265 28.2961H7.06879C6.85629 28.2961 6.68431 28.1272 6.68431 27.9187V21.3612C6.68431 21.1527 6.85629 20.9837 7.06879 20.9837H9.40722C11.065 20.9837 11.9804 21.8102 11.9804 23.3155C11.9804 24.8209 11.065 25.6548 9.40722 25.6548ZM15.1151 28.2961H12.892C12.6795 28.2961 12.5073 28.1271 12.5073 27.9186V21.3611C12.5073 21.1527 12.6795 20.9837 12.892 20.9837H15.1151C17.5152 20.9837 18.8344 22.2854 18.8344 24.6473C18.8344 27.0094 17.5498 28.2961 15.1151 28.2961ZM24.346 22.112C24.346 22.212 24.3055 22.3081 24.2336 22.3789C24.1614 22.4494 24.0634 22.4892 23.9615 22.4892H21.1538V23.9983H23.5193L23.5191 23.9985C23.621 23.9985 23.7189 24.0382 23.7911 24.109C23.8633 24.1798 23.9038 24.2756 23.9038 24.3756V25.1304C23.9038 25.2305 23.8633 25.3265 23.7911 25.397C23.7189 25.4679 23.621 25.5076 23.5191 25.5076H21.1538V27.9186C21.1538 28.0186 21.1132 28.1147 21.0411 28.1855C20.9689 28.2563 20.871 28.296 20.7691 28.296H19.9151C19.7029 28.296 19.5306 28.127 19.5306 27.9186V21.3611C19.5306 21.1526 19.7029 20.9837 19.9151 20.9837H23.9652C24.0671 20.9837 24.1651 21.0234 24.2372 21.0942C24.3094 21.165 24.3499 21.2611 24.3499 21.3611L24.346 22.112Z"
      fill={fill}
    />
    <path
      d="M17.1848 24.6459C17.1848 23.8688 17.0118 23.3026 16.6616 22.9594V22.9592C16.2367 22.6002 15.6818 22.4249 15.1233 22.4725H14.1346V26.804H15.1154C16.5578 26.804 17.177 26.1587 17.1848 24.6459H17.1848Z"
      fill={fill}
    />
    <path
      d="M10.3386 23.3143C10.3386 22.6955 10.0923 22.4766 9.40789 22.4766H8.31165V24.1481H9.40789C10.1731 24.1481 10.3308 23.8123 10.3386 23.3143Z"
      fill={fill}
    />
  </svg>
);

FilePdf.defaultProps = {
  size: 64,
  fill: "black",
};

FilePdf.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default FilePdf;
