import PropTypes from "prop-types";

const Address = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0723 8.03013C25.0723 7.85335 24.9277 7.70871 24.7509 7.70871H9.32235C9.14556 7.70871 9.00092 7.85335 9.00092 8.03013V9.95871C9.00092 10.1355 9.14556 10.2801 9.32235 10.2801H24.7509C24.9277 10.2801 25.0723 10.1355 25.0723 9.95871V8.03013ZM24.7509 13.4944H9.32235C9.14556 13.4944 9.00092 13.6391 9.00092 13.8158V15.7444C9.00092 15.9212 9.14556 16.0658 9.32235 16.0658H24.7509C24.9277 16.0658 25.0723 15.9212 25.0723 15.7444V13.8158C25.0723 13.6391 24.9277 13.4944 24.7509 13.4944ZM16.7152 19.2801H9.32235C9.14556 19.2801 9.00092 19.4248 9.00092 19.6016V21.5301C9.00092 21.7069 9.14556 21.8516 9.32235 21.8516H16.7152C16.892 21.8516 17.0366 21.7069 17.0366 21.5301V19.6016C17.0366 19.4248 16.892 19.2801 16.7152 19.2801ZM14.7866 31.6551H5.78663V3.36942H28.2866V17.1908C28.2866 17.3676 28.4313 17.5123 28.6081 17.5123H30.8581C31.0348 17.5123 31.1795 17.3676 31.1795 17.1908V1.76228C31.1795 1.05112 30.6049 0.476562 29.8938 0.476562H4.17949C3.46833 0.476562 2.89378 1.05112 2.89378 1.76228V33.2623C2.89378 33.9734 3.46833 34.548 4.17949 34.548H14.7866C14.9634 34.548 15.1081 34.4034 15.1081 34.2266V31.9766C15.1081 31.7998 14.9634 31.6551 14.7866 31.6551ZM29.1223 28.6658C30.2875 27.6092 31.0188 26.0824 31.0188 24.3828C31.0188 21.1886 28.4273 18.5971 25.2331 18.5971C22.0389 18.5971 19.4473 21.1886 19.4473 24.3828C19.4473 26.0824 20.1786 27.6092 21.3438 28.6658C19.0536 29.9717 17.4786 32.3904 17.3581 35.1788C17.35 35.3596 17.4987 35.5123 17.6795 35.5123H19.6121C19.7808 35.5123 19.9215 35.3797 19.9335 35.2069C20.0902 32.4266 22.4085 30.2087 25.2331 30.2087C28.0576 30.2087 30.3759 32.4266 30.5326 35.2069C30.5407 35.3757 30.6813 35.5123 30.854 35.5123H32.7866C32.9715 35.5123 33.1161 35.3596 33.1081 35.1788C32.9915 32.3864 31.4125 29.9717 29.1223 28.6658ZM25.2331 21.1685C27.009 21.1685 28.4473 22.6069 28.4473 24.3828C28.4473 26.1587 27.009 27.5971 25.2331 27.5971C23.4572 27.5971 22.0188 26.1587 22.0188 24.3828C22.0188 22.6069 23.4572 21.1685 25.2331 21.1685Z"
      fill={fill}
    />
  </svg>
);

Address.defaultProps = {
  size: 64,
  fill: "black",
};

Address.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Address;
