import PropTypes from "prop-types";
import Table from "antd/lib/table";

import Icon from "../Icon";

import styles from "./managementTable.module.css";

const ManagementTable = (props) => {
  const {
    data,
    ExpandedSection,
    pageSize,
    listSize,
    currentPage,
    onPageChange,
    isPaginated = true,
  } = props;

  return (
    <Table
      {...props}
      dataSource={data}
      bordered
      className={styles.table}
      expandable={
        ExpandedSection
          ? {
              expandedRowRender: ExpandedSection,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <Icon
                    name="arrow_up"
                    size={12}
                    color="white"
                    onClick={(e) => onExpand(record, e)}
                    className={styles["up-icon"]}
                  />
                ) : (
                  <Icon
                    name="arrow_down"
                    size={14}
                    onClick={(e) => onExpand(record, e)}
                  />
                ),
            }
          : null
      }
      pagination={
        isPaginated
          ? {
              total: listSize || data.length,
              showTotal: (total, range) =>
                data.length > 0 ? (
                  <div className={styles["pagination-info"]}>
                    Showing{" "}
                    <b>
                      {range[0]}-{range[1]}
                    </b>{" "}
                    of <b>{total}</b> items
                  </div>
                ) : null,
              current: currentPage,
              pageSize,
              onChange: onPageChange,
              defaultCurrent: 1,
            }
          : false
      }
    />
  );
};

ManagementTable.defaultProps = {
  columns: [],
  data: [],
  ExpandedSection: null,
  pageSize: 2,
  listSize: null,
  currentPage: 1,
  onPageChange: () => {},
  isPaginated: false,
};

ManagementTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ExpandedSection: PropTypes.elementType,
  listSize: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  isPaginated: PropTypes.bool,
};

export default ManagementTable;
