/* eslint-disable react/prop-types */
import { Modal } from "@cea/ui-kit";

const Alert = ({ showDialog, cancelNavigation, confirmNavigation }) => (
  <Modal
    title="Navigation Confirm!"
    visible={showDialog}
    onOk={confirmNavigation}
    onCancel={cancelNavigation}
    centered
    width={720}
  >
    <b>Your file/s are uploading</b>
    <br /> Are you sure you want to navigate away?
  </Modal>
);

export default Alert;
