import PropTypes from "prop-types";

const User = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 1C13.5818 1 10 4.80539 10 9.49966C10 14.1942 13.5816 18 18 18C22.4183 18 26 14.1943 26 9.49966C26 4.80509 22.4183 1 18 1Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0003 19C11.356 19 7.08246 21.5711 4.81985 25.498C3.41595 27.9351 3.95318 30.4714 5.38849 32.2398C6.76545 33.9366 8.98416 35 11.294 35H24.7059C27.0158 35 29.2341 33.9366 30.6114 32.2398C32.0467 30.4714 32.5842 27.9351 31.18 25.498C28.9179 21.5711 24.6443 19 17.9996 19H18.0003Z"
      fill={fill}
    />
  </svg>
);

User.defaultProps = {
  size: 64,
  fill: "black",
};

User.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default User;
