import { gql } from "@apollo/client";

// PV SMIP Queries
export const FETCH_PV_SMIP_FOLDERS = gql`
  query GetPVSMIPFolders(
    $where: PVSMIPFolderWhereInput!
    $orderBy: PVSMIPOrderByInput!
  ) {
    getPVSMIPFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_PV_SMIP_FILES = gql`
  query GetPVSMIPFiles($name: String!, $orderBy: PVSMIPOrderByInput) {
    getPVSMIPFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_PV_SMIP_FOLDER = gql`
  mutation CreatePVSMIPFolder($name: String!, $parentId: ID) {
    createPVSMIPFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_PV_SMIP_FILE = gql`
  mutation CreatePVSMIPFile($link: String!, $name: String!, $parentId: ID) {
    createPVSMIPFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_PV_SMIP_FOLDER = gql`
  mutation RenamePVSMIPFolder($data: UpdateFolderOrFileInput!) {
    updatePVSMIPFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_PV_SMIP_FILE = gql`
  mutation RenamePVSMIPFile($data: UpdateFolderOrFileInput!) {
    updatePVSMIPFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_PV_SMIP_FOLDER = gql`
  mutation DeletePVSMIPFolder($id: ID!) {
    deletePVSMIPFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_PV_SMIP_FILE = gql`
  mutation DeletePVSMIPFile($id: ID!) {
    deletePVSMIPFile(id: $id) {
      message
      id
    }
  }
`;

// ESS SMIP Queries
export const FETCH_ESS_SMIP_FOLDERS = gql`
  query GetESSSMIPFolders(
    $where: ESSSMIPFolderWhereInput!
    $orderBy: ESSSMIPOrderByInput!
  ) {
    getESSSMIPFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_ESS_SMIP_FILES = gql`
  query GetESSSMIPFiles($name: String!, $orderBy: ESSSMIPOrderByInput!) {
    getESSSMIPFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_ESS_SMIP_FOLDER = gql`
  mutation CreateESSSMIPFolder($name: String!, $parentId: ID) {
    createESSSMIPFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_ESS_SMIP_FILE = gql`
  mutation CreateESSSMIPFile($link: String!, $name: String!, $parentId: ID) {
    createESSSMIPFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_ESS_SMIP_FOLDER = gql`
  mutation RenameESSSMIPFolder($data: UpdateFolderOrFileInput!) {
    updateESSSMIPFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_ESS_SMIP_FILE = gql`
  mutation RenameESSSMIPFile($data: UpdateFolderOrFileInput!) {
    updateESSSMIPFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_ESS_SMIP_FOLDER = gql`
  mutation DeleteESSSMIPFolder($id: ID!) {
    deleteESSSMIPFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_ESS_SMIP_FILE = gql`
  mutation DeleteESSSMIPFile($id: ID!) {
    deleteESSSMIPFile(id: $id) {
      message
      id
    }
  }
`;

// PV PFT Queries
export const FETCH_PV_PFT_FOLDERS = gql`
  query GetPVPFTFolders(
    $where: PVPFTFolderWhereInput!
    $orderBy: PVPFTOrderByInput!
  ) {
    getPVPFTFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_PV_PFT_FILES = gql`
  query GetPVPFTFiles($name: String!, $orderBy: PVPFTOrderByInput!) {
    getPVPFTFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_PV_PFT_FOLDER = gql`
  mutation CreatePVPFTFolder($name: String!, $parentId: ID) {
    createPVPFTFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_PV_PFT_FILE = gql`
  mutation CreatePVPFTFile($link: String!, $name: String!, $parentId: ID) {
    createPVPFTFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_PV_PFT_FOLDER = gql`
  mutation RenamePVPFTFolder($data: UpdateFolderOrFileInput!) {
    updatePVPFTFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_PV_PFT_FILE = gql`
  mutation RenamePVPFTFile($data: UpdateFolderOrFileInput!) {
    updatePVPFTFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_PV_PFT_FOLDER = gql`
  mutation DeletePVPFTFolder($id: ID!) {
    deletePVPFTFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_PV_PFT_FILE = gql`
  mutation DeletePVPFTFile($id: ID!) {
    deletePVPFTFile(id: $id) {
      message
      id
    }
  }
`;

// EBR Queries
export const FETCH_EBR_FOLDERS = gql`
  query GetEBRFolders(
    $where: EBRFolderWhereInput!
    $orderBy: EBROrderByInput!
  ) {
    getEBRFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_EBR_FILES = gql`
  query GetEBRFiles(
    $name: String!
    $organization: ID!
    $orderBy: EBROrderByInput!
  ) {
    getEBRFiles(name: $name, organization: $organization, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_EBR_FOLDER = gql`
  mutation CreateEBRFolder($name: String!, $parentId: ID, $organization: ID!) {
    createEBRFolder(
      data: { name: $name, parentId: $parentId, organization: $organization }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_EBR_FILE = gql`
  mutation CreateEBRFile(
    $link: String!
    $name: String!
    $organization: ID!
    $parentId: ID
  ) {
    createEBRFile(
      data: {
        link: $link
        name: $name
        parentId: $parentId
        organization: $organization
      }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_EBR_FOLDER = gql`
  mutation RenameEBRFolder($data: UpdateFolderOrFileInput!) {
    updateEBRFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_EBR_FILE = gql`
  mutation RenameEBRFile($data: UpdateFolderOrFileInput!) {
    updateEBRFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_EBR_FOLDER = gql`
  mutation DeleteEBRFolder($id: ID!) {
    deleteEBRFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_EBR_FILE = gql`
  mutation DeleteEBRFile($id: ID!) {
    deleteEBRFile(id: $id) {
      message
      id
    }
  }
`;

// ESS PFR Queries
export const FETCH_ESS_PFR_FOLDERS = gql`
  query GetESSPFRFolders(
    $where: ESSPFRFolderWhereInput!
    $orderBy: ESSPFROrderByInput!
  ) {
    getESSPFRFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_ESS_PFR_FILES = gql`
  query GetESSPFRFiles($name: String!, $orderBy: ESSPFROrderByInput) {
    getESSPFRFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_ESS_PFR_FOLDER = gql`
  mutation CreateESSPFRFolder($name: String!, $parentId: ID) {
    createESSPFRFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_ESS_PFR_FILE = gql`
  mutation CreateESSPFRFile($link: String!, $name: String!, $parentId: ID) {
    createESSPFRFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_ESS_PFR_FOLDER = gql`
  mutation RenameESSPFRFolder($data: UpdateFolderOrFileInput!) {
    updateESSPFRFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_ESS_PFR_FILE = gql`
  mutation RenameESSPFRFile($data: UpdateFolderOrFileInput!) {
    updateESSPFRFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_ESS_PFR_FOLDER = gql`
  mutation DeleteESSPFRFolder($id: ID!) {
    deleteESSPFRFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_ESS_PFR_FILE = gql`
  mutation DeleteESSPFRFile($id: ID!) {
    deleteESSPFRFile(id: $id) {
      message
      id
    }
  }
`;

// ESS STPR Queries
export const FETCH_ESS_STPR_FOLDERS = gql`
  query GetESSSTPRFolders(
    $where: ESSSTPRFolderWhereInput!
    $orderBy: ESSSTPROrderByInput!
  ) {
    getESSSTPRFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_ESS_STPR_FILES = gql`
  query GetESSSTPRFiles($name: String!, $orderBy: ESSSTPROrderByInput) {
    getESSSTPRFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_ESS_STPR_FOLDER = gql`
  mutation CreateESSSTPRFolder($name: String!, $parentId: ID) {
    createESSSTPRFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_ESS_STPR_FILE = gql`
  mutation CreateESSSTPRFile($link: String!, $name: String!, $parentId: ID) {
    createESSSTPRFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_ESS_STPR_FOLDER = gql`
  mutation RenameESSSTPRFolder($data: UpdateFolderOrFileInput!) {
    updateESSSTPRFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_ESS_STPR_FILE = gql`
  mutation RenameESSSTPRFile($data: UpdateFolderOrFileInput!) {
    updateESSSTPRFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_ESS_STPR_FOLDER = gql`
  mutation DeleteESSSTPRFolder($id: ID!) {
    deleteESSSTPRFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_ESS_STPR_FILE = gql`
  mutation DeleteESSSTPRFile($id: ID!) {
    deleteESSSTPRFile(id: $id) {
      message
      id
    }
  }
`;

// GH2 SMIP Queries
export const FETCH_GH2_SMIP_FOLDERS = gql`
  query GetGH2SMIPFolders(
    $where: GH2SMIPFolderWhereInput!
    $orderBy: GH2SMIPOrderByInput!
  ) {
    getGH2SMIPFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_GH2_SMIP_FILES = gql`
  query GetGH2SMIPFiles($name: String!, $orderBy: GH2SMIPOrderByInput) {
    getGH2SMIPFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_GH2_SMIP_FOLDER = gql`
  mutation CreateGH2SMIPFolder($name: String!, $parentId: ID) {
    createGH2SMIPFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_GH2_SMIP_FILE = gql`
  mutation CreateGH2SMIPFile($link: String!, $name: String!, $parentId: ID) {
    createGH2SMIPFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_GH2_SMIP_FOLDER = gql`
  mutation RenameGH2SMIPFolder($data: UpdateFolderOrFileInput!) {
    updateGH2SMIPFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_GH2_SMIP_FILE = gql`
  mutation RenameGH2SMIPFile($data: UpdateFolderOrFileInput!) {
    updateGH2SMIPFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_GH2_SMIP_FOLDER = gql`
  mutation DeleteGH2SMIPFolder($id: ID!) {
    deleteGH2SMIPFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_GH2_SMIP_FILE = gql`
  mutation DeleteGH2SMIPFile($id: ID!) {
    deleteGH2SMIPFile(id: $id) {
      message
      id
    }
  }
`;

// GH2 STPR Queries
export const FETCH_GH2_STPR_FOLDERS = gql`
  query GetGH2STPRFolders(
    $where: GH2STPRFolderWhereInput!
    $orderBy: GH2STPROrderByInput!
  ) {
    getGH2STPRFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_GH2_STPR_FILES = gql`
  query GetGH2STPRFiles($name: String!, $orderBy: GH2STPROrderByInput) {
    getGH2STPRFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_GH2_STPR_FOLDER = gql`
  mutation CreateGH2STPRFolder($name: String!, $parentId: ID) {
    createGH2STPRFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_GH2_STPR_FILE = gql`
  mutation CreateGH2STPRFile($link: String!, $name: String!, $parentId: ID) {
    createGH2STPRFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_GH2_STPR_FOLDER = gql`
  mutation RenameGH2STPRFolder($data: UpdateFolderOrFileInput!) {
    updateGH2STPRFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_GH2_STPR_FILE = gql`
  mutation RenameGH2STPRFile($data: UpdateFolderOrFileInput!) {
    updateGH2STPRFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_GH2_STPR_FOLDER = gql`
  mutation DeleteGH2STPRFolder($id: ID!) {
    deleteGH2STPRFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_GH2_STPR_FILE = gql`
  mutation DeleteGH2STPRFile($id: ID!) {
    deleteGH2STPRFile(id: $id) {
      message
      id
    }
  }
`;

// PV STPR Queries
export const FETCH_PV_STPR_FOLDERS = gql`
  query GetPVSTPRFolders(
    $where: PVSTPRFolderWhereInput!
    $orderBy: PVSTPROrderByInput!
  ) {
    getPVSTPRFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_PV_STPR_FILES = gql`
  query GetPVSTPRFiles($name: String!, $orderBy: PVSTPROrderByInput) {
    getPVSTPRFiles(name: $name, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_PV_STPR_FOLDER = gql`
  mutation CreatePVSTPRFolder($name: String!, $parentId: ID) {
    createPVSTPRFolder(data: { name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_PV_STPR_FILE = gql`
  mutation CreatePVSTPRFile($link: String!, $name: String!, $parentId: ID) {
    createPVSTPRFile(data: { link: $link, name: $name, parentId: $parentId }) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_PV_STPR_FOLDER = gql`
  mutation RenamePVSTPRFolder($data: UpdateFolderOrFileInput!) {
    updatePVSTPRFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_PV_STPR_FILE = gql`
  mutation RenamePVSTPRFile($data: UpdateFolderOrFileInput!) {
    updatePVSTPRFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_PV_STPR_FOLDER = gql`
  mutation DeletePVSTPRFolder($id: ID!) {
    deletePVSTPRFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_PV_STPR_FILE = gql`
  mutation DeletePVSTPRFile($id: ID!) {
    deletePVSTPRFile(id: $id) {
      message
      id
    }
  }
`;
