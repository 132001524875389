import PropTypes from "prop-types";
import { Card, Col, Row, Menu, Dropdown } from "antd";

import Icon from "../Icon";
import FileSkeleton from "./FileSkeleton";

import styles from "./file.module.css";

const File = ({
  file,
  onClick,
  primaryAction = null,
  contextMenu = [],
  hoverable = false,
}) => {
  const renderFileIcon = (type) => {
    if (type === "folder") {
      return <Icon name="folder" size={32} />;
    }

    if (type === "pdf") {
      return <Icon name="file_pdf" size={32} />;
    }

    if (type === "doc") {
      return <Icon name="file_docx" size={32} />;
    }

    return <Icon name="file" size={32} />;
  };

  return (
    <Card
      onClick={onClick}
      hoverable={hoverable}
      className={styles["file-card"]}
    >
      <Row gutter={12} wrap={false} align="middle">
        <Col>{renderFileIcon(file?.type)}</Col>

        {file?.columns
          ? file.columns.map((item) => (
              <Col span={item?.span} key={Date.now() + Math.random()}>
                {item?.heading ? (
                  <div className={styles["card-title"]}>{item.heading}</div>
                ) : null}

                {item?.description ? (
                  <div className={styles["card-description"]}>
                    {item.description}
                  </div>
                ) : null}
              </Col>
            ))
          : null}

        {primaryAction || contextMenu?.length ? (
          <Col span={3}>
            <div className={styles["actions-container"]}>
              {primaryAction || null}

              {contextMenu?.length ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {contextMenu?.map((menu) => (
                        <Menu.Item
                          key={menu.title}
                          onClick={(event) => {
                            event.domEvent.stopPropagation();
                            menu.action();
                          }}
                        >
                          {menu.title}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  disabled={!contextMenu?.length}
                  trigger={["click"]}
                >
                  <div role="none" onClick={(event) => event.stopPropagation()}>
                    <Icon name="more_menu" size={20} />
                  </div>
                </Dropdown>
              ) : null}
            </div>
          </Col>
        ) : null}
      </Row>
    </Card>
  );
};

File.FileSkeleton = FileSkeleton;

export default File;

File.defaultProps = {
  onClick: () => {},
  contextMenu: null,
  hoverable: false,
  primaryAction: null,
};

File.propTypes = {
  onClick: PropTypes.func,
  contextMenu: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        action: PropTypes.func,
      })
    ),
  ]),
  hoverable: PropTypes.bool,
  primaryAction: PropTypes.node,
  file: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        span: PropTypes.number.isRequired,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      })
    ),
    type: PropTypes.string.isRequired,
  }).isRequired,
};
