import PropTypes from "prop-types";

const LayoutGrid = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.25H2.25V0H0V2.25ZM3.375 9H5.625V6.75H3.375V9ZM0 9H2.25V6.75H0V9ZM0 5.625H2.25V3.375H0V5.625ZM3.375 5.625H5.625V3.375H3.375V5.625ZM6.75 0V2.25H9V0H6.75ZM3.375 2.25H5.625V0H3.375V2.25ZM6.75 5.625H9V3.375H6.75V5.625ZM6.75 9H9V6.75H6.75V9Z"
      fill={fill}
    />
  </svg>
);

LayoutGrid.defaultProps = {
  size: 64,
  fill: "black",
};

LayoutGrid.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default LayoutGrid;
