import PropTypes from "prop-types";

const ArrowSolidDown = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5458 10.5469H6.45519C5.76261 10.5469 5.37589 11.2781 5.8048 11.7773L17.3501 25.1648C17.6806 25.548 18.3169 25.548 18.6509 25.1648L30.1962 11.7773C30.6251 11.2781 30.2384 10.5469 29.5458 10.5469Z"
      fill={fill}
    />
  </svg>
);

ArrowSolidDown.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowSolidDown.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowSolidDown;
