import PropTypes from "prop-types";

const Notes = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.75 2H8C7.20435 2 6.44129 2.3746 5.87868 3.0414C5.31607 3.70819 5 4.61256 5 5.55556V30.4444C5 32.4 6.35 34 8 34H29C29.7956 34 30.5587 33.6254 31.1213 32.9586C31.6839 32.2918 32 31.3874 32 30.4444V11.7778L23.75 2Z"
      fill={fill}
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 1V12H32"
      stroke="white"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Notes.defaultProps = {
  size: 64,
  fill: "black",
};

Notes.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Notes;
