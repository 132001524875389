export const getNetworkError = (errorObject) => {
  const { networkError } = errorObject;

  if (networkError) {
    if (networkError.result && networkError.result.errors.length) {
      return networkError.result.errors;
    }
  }

  return [{ message: errorObject.message }];
};

const getNotification = (errorObject) => {
  const errors = getNetworkError(errorObject);

  const notificationData = errors.map((item) => (
    <div key={item.message}>{item.message}</div>
  ));

  return notificationData;
};

export default getNotification;
