import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS = gql`
  query Organizations(
    $limit: Int
    $offset: Int
    $orderBy: OrganizationsOrderByInput
    $where: OrganizationsWhereInput
  ) {
    organizations(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      organizations {
        id
        name
        logo
        clientId
        subscriptions {
          id
          serviceType
          expiringOn
          startingOn
          service {
            id
            name
            admins {
              id
              name
              avatar
              email
            }
          }
        }
      }

      total
    }
  }
`;

export const GET_ORGANIZATION_DETAILS = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      name
      logo
      clientId
      subscriptions {
        id
        serviceType
        expiringOn
        startingOn
        service {
          id
          name
          admins {
            id
            name
            avatar
            email
          }
        }
        organization {
          id
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $id: ID!
    $name: String
    $logo: String
    $clientId: Int
  ) {
    updateOrganization(
      data: { id: $id, name: $name, logo: $logo, clientId: $clientId }
    ) {
      id
      message
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $logo: String, $clientId: Int!) {
    createOrganization(
      data: { name: $name, logo: $logo, clientId: $clientId }
    ) {
      id
      message
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      id
      message
    }
  }
`;

export const DELETE_SUBSCRIPTION = gql`
  mutation DeleteCeaSubscription($id: ID!) {
    deleteCeaSubscription(id: $id) {
      id
      message
    }
  }
`;

export const FETCH_SUBSCRIPTION_DETAILS = gql`
  query SubscriptionDetails($id: ID, $organization: ID, $service: ID) {
    ceaSubscription(
      where: { id: $id, organization: $organization, service: $service }
    ) {
      id
      organization {
        id
        name
      }
      service {
        id
        name
      }
      serviceType
      expiringOn
      startingOn
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateCeaSubscription(
    $organization: ID!
    $service: ID!
    $serviceType: CeaServiceType!
    $expiringOn: DateTime
    $startingOn: DateTime!
  ) {
    createCeaSubscription(
      data: {
        organization: $organization
        service: $service
        serviceType: $serviceType
        expiringOn: $expiringOn
        startingOn: $startingOn
      }
    ) {
      message
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateCeaSubscription(
    $id: ID!
    $service: ID!
    $serviceType: CeaServiceType
    $expiringOn: DateTime
    $startingOn: DateTime
  ) {
    updateCeaSubscription(
      data: {
        id: $id
        service: $service
        serviceType: $serviceType
        expiringOn: $expiringOn
        startingOn: $startingOn
      }
    ) {
      message
    }
  }
`;
