import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Spin from "antd/lib/spin";
import AntDList from "antd/lib/list";

import styles from "./management-list.module.css";

import Icon from "../Icon";

const ManagementList = ({
  data,
  loading,
  onDeleteItem,
  baseUrl,
  pagination,
}) => (
  <AntDList
    itemLayout="vertical"
    size="large"
    dataSource={data}
    pagination={{
      ...pagination,
      total: data.length,
    }}
    renderItem={(item) => (
      <AntDList.Item
        key={item.id}
        actions={[
          <a key="edit-link" to={`${baseUrl}/${item.id}`}>
            <div
              className={cx(styles["edit-container"], {
                [styles.disabled]: loading === item.id,
              })}
            >
              <Icon name="edit" size={16} color="rgba(59, 130, 246, 0.8)" />
              Edit
            </div>
          </a>,
          <button
            type="button"
            className={cx(styles["delete-container"], {
              [styles.disabled]: loading === item.id,
            })}
            key="delete-link"
            onClick={() => onDeleteItem(item.id)}
          >
            {loading === item.id ? (
              <Spin data-testid="delete-loader" size="small" />
            ) : (
              <Icon
                name="delete"
                data-testid="delete-icon"
                size={16}
                color="red"
              />
            )}
          </button>,
        ]}
      >
        <div>{item.title}</div>
      </AntDList.Item>
    )}
  />
);

ManagementList.defaultProps = {
  data: [],
  loading: null,
  onDeleteItem: () => {},
  pagination: PropTypes.shape({
    current: 1,
    pageSize: 15,
    onPageChange: () => {},
  }),
};

ManagementList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.string,
  onDeleteItem: PropTypes.func,
  baseUrl: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func,
  }),
};

export default ManagementList;
