import PropTypes from "prop-types";
import { Spin as AntSpin } from "antd";

const loadingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={30}
    height={30}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{
      marginRight: "-2px",
      display: "block",
      backgroundRepeatY: "initial",
      backgroundRepeatX: "initial",
      backgroundColor: "transparent",
      animationPlayState: "paused",
      height: 40,
      width: 40,
    }}
  >
    <g
      transform="matrix(1,0,0,1,0,0)"
      style={{
        transform: "matrix(1, 0, 0, 1, 0, 0)",
        animationPlayState: "paused",
      }}
    >
      <path
        d="M50 33A17 17 0 1 0 64.49488279401956 41.117524399828866"
        fill="none"
        stroke="#5b88e1"
        strokeWidth="4"
        style={{
          animationPlayState: "paused",
        }}
      />
      <path
        d="M49 25L49 41L57 33L49 25"
        fill="#5b88e1"
        style={{
          animationPlayState: "paused",
        }}
      />
    </g>
  </svg>
);

const Loader = (props) => {
  const { children, tip } = props;

  return (
    <AntSpin {...props} indicator={loadingIcon} tip={tip} size="default">
      {children}
    </AntSpin>
  );
};

Loader.defaultProps = {
  children: null,
  tip: null,
};

Loader.propTypes = {
  children: PropTypes.node,
  tip: PropTypes.string,
};

export default Loader;
