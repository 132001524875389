/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import iconMap from "./svgs/icon-map";

const Icon = ({ name, size, color, onClick, ...rest }) => {
  const BaseIcon = iconMap[name];

  return (
    <span onClick={onClick} {...rest}>
      <BaseIcon size={size} fill={color} />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: 64,
  color: "var(--black)",
  onClick: null,
};

export default Icon;
