import PropTypes from "prop-types";

const Filter = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.7902 3.62109H3.21073C2.22635 3.62109 1.61162 4.69386 2.10582 5.54967L11.4514 21.4363V31.1032C11.4514 31.8144 12.0219 32.389 12.729 32.389H23.2719C23.979 32.389 24.5496 31.8144 24.5496 31.1032V21.4363L33.8991 5.54967C34.3893 4.69386 33.7746 3.62109 32.7902 3.62109ZM21.6728 29.4961H14.3281V23.2282H21.6768V29.4961H21.6728ZM22.0585 19.9898L21.6768 20.6568H14.3241L13.9424 19.9898L5.97501 6.51395H30.0259L22.0585 19.9898Z"
      fill={fill}
    />
  </svg>
);

Filter.defaultProps = {
  size: 64,
  fill: "black",
};

Filter.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Filter;
