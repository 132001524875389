import PropTypes from "prop-types";

const IssuesCircle = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z"
      fill={fill}
    />
    <path
      d="M16.312 24.1875C16.312 24.6351 16.4897 25.0643 16.8062 25.3807C17.1227 25.6972 17.5519 25.875 17.9995 25.875C18.447 25.875 18.8762 25.6972 19.1927 25.3807C19.5092 25.0643 19.687 24.6351 19.687 24.1875C19.687 23.7399 19.5092 23.3107 19.1927 22.9943C18.8762 22.6778 18.447 22.5 17.9995 22.5C17.5519 22.5 17.1227 22.6778 16.8062 22.9943C16.4897 23.3107 16.312 23.7399 16.312 24.1875ZM17.1557 20.25H18.8432C18.9979 20.25 19.1245 20.1234 19.1245 19.9688V10.4062C19.1245 10.2516 18.9979 10.125 18.8432 10.125H17.1557C17.001 10.125 16.8745 10.2516 16.8745 10.4062V19.9688C16.8745 20.1234 17.001 20.25 17.1557 20.25Z"
      fill={fill}
    />
  </svg>
);

IssuesCircle.defaultProps = {
  size: 64,
  fill: "black",
};

IssuesCircle.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default IssuesCircle;
