import React from "react";
import PropTypes from "prop-types";

import LogoImage from "../../assets/logo.jpg";
import FullLogoImage from "../../assets/full-logo.png";

const Logo = ({ isFull, size = 35 }) => {
  const getImage = () => {
    if (isFull) {
      return FullLogoImage;
    }

    return LogoImage;
  };

  return (
    <div className="flex cursor-pointer">
      <img width={!size && isFull ? 120 : size} src={getImage()} />
    </div>
  );
};

Logo.defaultProps = {
  isFull: false,
  size: 35,
};

Logo.propTypes = {
  isFull: PropTypes.bool,
  size: PropTypes.number,
};

export default Logo;
