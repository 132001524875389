const getInitials = (name) => {
  if (name.length) {
    const [firstName, lastName] = name.split(" ");
    const secondInitial = lastName ? lastName[0] : "";

    return firstName[0] + secondInitial;
  }

  return "";
};

export default getInitials;
