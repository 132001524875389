import PropTypes from "prop-types";

const ArrowSolidLeft = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2229 5.8048L10.8354 17.3501C10.4522 17.6806 10.4522 18.3169 10.8354 18.6509L24.2229 30.1962C24.7221 30.6251 25.4533 30.2384 25.4533 29.5458V6.45519C25.4533 5.76261 24.7221 5.37589 24.2229 5.8048Z"
      fill={fill}
    />
  </svg>
);

ArrowSolidLeft.defaultProps = {
  size: 64,
  fill: "black",
};

ArrowSolidLeft.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default ArrowSolidLeft;
