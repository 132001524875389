import PropTypes from "prop-types";

const Visibility = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.2851 16.964C31.4761 8.94029 25.7185 4.90234 18.0002 4.90234C10.2779 4.90234 4.52434 8.94029 0.715411 16.968C0.562633 17.2915 0.483398 17.6448 0.483398 18.0026C0.483398 18.3603 0.562633 18.7137 0.715411 19.0372C4.52434 27.0608 10.2819 31.0988 18.0002 31.0988C25.7226 31.0988 31.4761 27.0608 35.2851 19.0331C35.5944 18.3823 35.5944 17.6269 35.2851 16.964ZM18.0002 28.2059C11.5194 28.2059 6.77434 24.9193 3.42746 18.0006C6.77434 11.0818 11.5194 7.7952 18.0002 7.7952C24.481 7.7952 29.2261 11.0818 32.573 18.0006C29.2301 24.9193 24.4851 28.2059 18.0002 28.2059ZM17.8395 10.9291C13.9342 10.9291 10.7681 14.0952 10.7681 18.0006C10.7681 21.9059 13.9342 25.072 17.8395 25.072C21.7449 25.072 24.9109 21.9059 24.9109 18.0006C24.9109 14.0952 21.7449 10.9291 17.8395 10.9291ZM17.8395 22.5006C15.3525 22.5006 13.3395 20.4876 13.3395 18.0006C13.3395 15.5135 15.3525 13.5006 17.8395 13.5006C20.3266 13.5006 22.3395 15.5135 22.3395 18.0006C22.3395 20.4876 20.3266 22.5006 17.8395 22.5006Z"
      fill={fill}
    />
  </svg>
);

Visibility.defaultProps = {
  size: 64,
  fill: "black",
};

Visibility.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Visibility;
