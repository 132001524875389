import PropTypes from "prop-types";

const Check = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 8.98552L31.1815 5L12.9694 24.0286L4.81849 15.512L1 19.4975L12.9694 32L35 8.98552Z"
      fill={fill}
    />
  </svg>
);

Check.defaultProps = {
  size: 64,
  fill: "black",
};

Check.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Check;
