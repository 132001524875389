import { gql } from "@apollo/client";

export const FETCH_SCM_FOLDERS = gql`
  query GetSCMFolders(
    $where: SCMFolderWhereInput!
    $orderBy: SCMOrderByInput!
  ) {
    getSCMFolders(where: $where, orderBy: $orderBy) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const FETCH_SCM_FILES = gql`
  query GetSCMFiles(
    $name: String!
    $organization: ID!
    $orderBy: SCMOrderByInput!
  ) {
    getSCMFiles(name: $name, organization: $organization, orderBy: $orderBy) {
      id
      name
      link
      parentId
      createdBy {
        name
      }
      createDate
    }
  }
`;

export const CREATE_SCM_FOLDER = gql`
  mutation CreateSCMFolder($name: String!, $parentId: ID, $organization: ID!) {
    createSCMFolder(
      data: { name: $name, parentId: $parentId, organization: $organization }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const CREATE_SCM_FILE = gql`
  mutation CreateSCMFile(
    $link: String!
    $name: String!
    $organization: ID!
    $parentId: ID
  ) {
    createSCMFile(
      data: {
        link: $link
        name: $name
        parentId: $parentId
        organization: $organization
      }
    ) {
      id
      message
      parentId
      parentName
    }
  }
`;

export const RENAME_SCM_FOLDER = gql`
  mutation RenameSCMFolder($data: UpdateFolderOrFileInput!) {
    updateSCMFolder(data: $data) {
      id
      name
      parentId
      folders {
        id
        name
        createDate
        parentId
        createDate
        createdBy {
          name
        }
      }
      files {
        id
        name
        link
        parentId
        createDate
        createdBy {
          name
        }
      }
      createDate
      deleted
    }
  }
`;

export const RENAME_SCM_FILE = gql`
  mutation RenameSCMFile($data: UpdateFolderOrFileInput!) {
    updateSCMFile(data: $data) {
      id
      name
      link
      parentId
      createDate
      createdBy {
        name
      }
    }
  }
`;

export const DELETE_SCM_FOLDER = gql`
  mutation DeleteSCMFolder($id: ID!) {
    deleteSCMFolder(id: $id) {
      message
      id
    }
  }
`;

export const DELETE_SCM_FILE = gql`
  mutation DeleteSCMFile($id: ID!) {
    deleteSCMFile(id: $id) {
      message
      id
    }
  }
`;
