import PropTypes from "prop-types";

const Collapse = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.61 26.4261H57.41C57.7015 26.4261 57.94 26.1909 57.94 25.9034V22.2443C57.94 21.9568 57.7015 21.7216 57.41 21.7216H25.61C25.3185 21.7216 25.08 21.9568 25.08 22.2443V25.9034C25.08 26.1909 25.3185 26.4261 25.61 26.4261ZM25.08 39.7557C25.08 40.0432 25.3185 40.2784 25.61 40.2784H57.41C57.7015 40.2784 57.94 40.0432 57.94 39.7557V36.0966C57.94 35.8091 57.7015 35.5739 57.41 35.5739H25.61C25.3185 35.5739 25.08 35.8091 25.08 36.0966V39.7557ZM58.47 8H6.53C6.2385 8 6 8.23523 6 8.52273V12.1818C6 12.4693 6.2385 12.7045 6.53 12.7045H58.47C58.7615 12.7045 59 12.4693 59 12.1818V8.52273C59 8.23523 58.7615 8 58.47 8ZM58.47 49.2955H6.53C6.2385 49.2955 6 49.5307 6 49.8182V53.4773C6 53.7648 6.2385 54 6.53 54H58.47C58.7615 54 59 53.7648 59 53.4773V49.8182C59 49.5307 58.7615 49.2955 58.47 49.2955ZM6.22525 31.4509L16.5801 39.4943C16.9644 39.7949 17.5341 39.527 17.5341 39.0435V22.9565C17.5341 22.473 16.971 22.2051 16.5801 22.5057L6.22525 30.5491C6.15562 30.6025 6.09928 30.6709 6.06052 30.749C6.02177 30.8272 6.00162 30.913 6.00162 31C6.00162 31.087 6.02177 31.1728 6.06052 31.251C6.09928 31.3291 6.15562 31.3975 6.22525 31.4509V31.4509Z"
      fill={fill}
    />
  </svg>
);

Collapse.defaultProps = {
  size: 64,
  fill: "black",
};

Collapse.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Collapse;
