import PropTypes from "prop-types";

const Laptop = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.875 31.3891L33.4442 22.827V4.18415C33.4442 3.47299 32.8696 2.89844 32.1585 2.89844H3.87276C3.1616 2.89844 2.58704 3.47299 2.58704 4.18415V22.827L0.156239 31.3891C-0.1451 32.2288 0.47365 33.1127 1.36561 33.1127H34.6656C35.5576 33.1127 36.1763 32.2288 35.875 31.3891ZM5.4799 5.79129H30.5513V21.6618H5.4799V5.79129ZM14.6527 30.2199L14.9781 28.7333H21.017L21.3424 30.2199H14.6527ZM23.6527 30.2199L22.8853 26.7364C22.8531 26.5877 22.7205 26.4833 22.5719 26.4833H13.4272C13.2745 26.4833 13.146 26.5877 13.1138 26.7364L12.3464 30.2199H3.49508L5.19463 24.2333H30.8366L32.5361 30.2199H23.6527Z"
      fill={fill}
    />
  </svg>
);

Laptop.defaultProps = {
  size: 64,
  fill: "black",
};

Laptop.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Laptop;
