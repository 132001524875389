import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Modal, Notification } from "@cea/ui-kit";

import { CREATE_GH2_SMIP_FOLDER } from "../../../queries/miServices";
import { getNotification } from "../../../utils";

export const GH2SMIPCreateFolderModal = ({
  isVisible,
  handleCancel,
  handleOk,
  parentId,
}) => {
  const [form] = Form.useForm();
  const [createGH2SMIPFolder, { loading: createFolderLoading }] = useMutation(
    CREATE_GH2_SMIP_FOLDER
  );

  const onFolderCreate = async () => {
    const { name } = form.getFieldsValue();

    try {
      const { data } = await createGH2SMIPFolder({
        variables: {
          parentId,
          name,
        },
        notifyOnNetworkStatusChange: true,
      });

      const { id, parentName } = data.createGH2SMIPFolder;

      form.resetFields();
      handleOk({ name, id }, { parentName, parentId });

      Notification.success({
        message: `New folder with name : ${name} created.`,
      });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      handleCancel();
    }
  };

  return (
    <Modal
      title="Create new folder"
      visible={isVisible}
      onOk={onFolderCreate}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          key="submit"
          loading={createFolderLoading}
          type="primary"
          onClick={onFolderCreate}
          className="ml-auto"
        >
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFolderCreate}
        autoComplete="off"
      >
        <Form.Item
          label="Folder name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name of the folder",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

GH2SMIPCreateFolderModal.defaultProps = {
  parentId: null,
};

GH2SMIPCreateFolderModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};

export default GH2SMIPCreateFolderModal;
