import PropTypes from "prop-types";

const Delete = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8922 4.81864H11.5708C11.7476 4.81864 11.8922 4.674 11.8922 4.49721V4.81864H24.1065V4.49721C24.1065 4.674 24.2512 4.81864 24.4279 4.81864H24.1065V7.7115H26.9994V4.49721C26.9994 3.07891 25.8463 1.92578 24.4279 1.92578H11.5708C10.1525 1.92578 8.99937 3.07891 8.99937 4.49721V7.7115H11.8922V4.81864ZM32.1422 7.7115H3.85652C3.14535 7.7115 2.5708 8.28605 2.5708 8.99721V10.2829C2.5708 10.4597 2.71544 10.6044 2.89223 10.6044H5.31902L6.31143 31.6177C6.37571 32.9878 7.50875 34.0686 8.87884 34.0686H27.1199C28.494 34.0686 29.623 32.9919 29.6873 31.6177L30.6797 10.6044H33.1065C33.2833 10.6044 33.4279 10.4597 33.4279 10.2829V8.99721C33.4279 8.28605 32.8534 7.7115 32.1422 7.7115ZM26.8105 31.1758H9.18821L8.21589 10.6044H27.7829L26.8105 31.1758Z"
      fill={fill}
    />
  </svg>
);

Delete.defaultProps = {
  size: 64,
  fill: "black",
};

Delete.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Delete;
