import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Input, Modal } from "@cea/ui-kit";

const RenameFolderModal = ({ handleOk, handleCancel, details, loading }) => {
  const [folderName, setFolderName] = useState(details.name);

  return (
    <Modal
      visible
      title={
        <div className="flex items-center gap-2">
          <Icon name="edit" size={16} />
          Rename {details.__typename.includes("Folder") ? "Folder" : "File"}
        </div>
      }
      onOk={() => handleOk(details, folderName)}
      onCancel={handleCancel}
      footer={[
        <Button
          key="ok"
          type="primary"
          loading={loading}
          onClick={() => handleOk(details, folderName)}
          className="ml-auto"
        >
          Rename
        </Button>,
      ]}
    >
      <Input
        value={folderName}
        onChange={(event) => setFolderName(event.target.value)}
        placeholder="Enter Name here"
      />
    </Modal>
  );
};

RenameFolderModal.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    __typename: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

RenameFolderModal.defaultProps = {
  handleOk: () => {},
  handleCancel: () => {},
  details: {},
  loading: false,
};

export default RenameFolderModal;
