export const sortKeyAscDesc = ({ value1, value2, key }) => {
  switch (key) {
    case "string":
      return ("" + value1).localeCompare(value2);

    case "number":
      return value1 - value2;

    default:
      return value1 - value2;
  }
};
