import { startCase } from "lodash-es";

import { isCamelCase } from "./isCamelCase";

const getServiceLineName = (name) => {
  if (isCamelCase(name)) {
    return startCase(name);
  } else {
    return name.toUpperCase();
  }
};

export default getServiceLineName;
