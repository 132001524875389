import PropTypes from "prop-types";

import styles from "./percentage-label.module.css";

const getVariantColor = (value) => {
  const normalized = Number(value);
  let backgroundColor;

  if (normalized < 34) {
    backgroundColor = "#F87171";
  } else if (normalized < 68) {
    backgroundColor = "#FDE68A";
  } else {
    backgroundColor = "#10B981";
  }

  return {
    background: `linear-gradient(90deg, ${backgroundColor} ${normalized}%, var(--white) ${normalized}%)`,
  };
};

const PercentageLabel = ({ value }) => (
  <div className={styles.container}>
    <div className={styles["item-container"]} style={getVariantColor(value)}>
      {value}%
    </div>
  </div>
);

PercentageLabel.defaultProps = {
  value: null,
};

PercentageLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PercentageLabel;
