import { useState, useEffect } from "react";
import { Notification } from "@cea/ui-kit";
import { useApolloClient } from "@apollo/client";

import { getNotification } from "../../../utils";
import { FETCH_SUB_PROJECT } from "../../../queries/qaServices";

const fetchSubProjectDetails = (subProjectId) => {
  const [subProject, setSubProject] = useState({});

  const apolloClient = useApolloClient();

  const fetchSubProject = async () => {
    try {
      const { data } = await apolloClient.query({
        query: FETCH_SUB_PROJECT,
        variables: {
          id: subProjectId,
        },
      });

      setSubProject(data.qaSubProject);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  useEffect(() => {
    fetchSubProject();
  }, []);

  return {
    subProject,
  };
};

export default fetchSubProjectDetails;
