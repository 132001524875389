import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  Row,
  Form,
  Card,
  Icon,
  Button,
  Loader,
  Column,
  Select,
  Breadcrumb,
  DatePicker,
  Notification,
} from "@cea/ui-kit";
import moment from "moment";

import Appshell from "../../components/Appshell";
import { getNotification } from "../../utils";
import {
  CREATE_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_DETAILS,
  GET_ORGANIZATION_DETAILS,
  UPDATE_SUBSCRIPTION,
} from "../../queries/organizations";
import { FETCH_SERVICES } from "../../queries/services";
import OrganizationContext from "../../contexts/OrganizationContext";

import styles from "./create-subscription.module.css";

const { Meta } = Card;
const { RangePicker } = DatePicker;

const OrganizationDetails = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [subscription, setSubscription] = useState({
    expiringOn: null,
    service: null,
  });
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const { organizationId, subscriptionId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { organization, fetchOrganizationDetails } =
    useContext(OrganizationContext);
  const [createSubscription, { loading: createLoading }] =
    useMutation(CREATE_SUBSCRIPTION);
  const [updateSubscription, { loading: updateLoading }] =
    useMutation(UPDATE_SUBSCRIPTION);

  const fetchServices = async () => {
    setLoading(true);

    try {
      const { data } = await apolloClient.query({
        query: FETCH_SERVICES,
      });
      const { services } = data.services;

      setServices(services);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscription = async () => {
    setLoadingSubscription(true);

    try {
      const { data } = await apolloClient.query({
        query: FETCH_SUBSCRIPTION_DETAILS,
        variables: {
          id: subscriptionId,
        },
      });

      const { subscription: subscriptionDetails } = data;

      setSubscription(subscriptionDetails);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    fetchServices();

    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }

    if (subscriptionId) {
      fetchSubscription();
    }
  }, []);

  const onFinish = async (formValues) => {
    try {
      const { service, dates } = formValues;
      const [startingDate, expiringDate] = dates;

      const startingOn = startingDate._d.toISOString();
      const expiringOn = expiringDate?._d.toISOString();

      const selectedServiceType = services.find(
        (item) => item.id === service
      )?.serviceType;

      if (subscriptionId) {
        await updateSubscription({
          variables: {
            id: subscriptionId,
            service,
            serviceType: selectedServiceType,
            organization: organization.id,
            startingOn,
            expiringOn,
          },
          notifyOnNetworkStatusChange: true,
          refetchQueries: [
            {
              query: GET_ORGANIZATION_DETAILS,
              variables: {
                id: organizationId,
              },
            },
          ],
        });

        Notification.success({ message: "New Subscription created!" });
        navigate(`/organizations/${organizationId}`);
      } else {
        await createSubscription({
          variables: {
            service,
            serviceType: selectedServiceType,
            organization: organization.id,
            startingOn,
            expiringOn,
          },
          notifyOnNetworkStatusChange: true,
        });

        Notification.success({ message: "New Subscription created!" });
        navigate(`/organizations/${organization.id}`);
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  return (
    <Appshell>
      {(() => {
        if (loading || loadingSubscription) {
          return (
            <div className="p-8">
              <Loader tip="Loading" />
            </div>
          );
        }

        return (
          <div className="page-main-container">
            <div className="breadcrumb-holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/organizations">Organizations</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/organizations/${organization?.id}`}>
                    {organization?.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Subscriptions</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Card>
              <Meta
                title="Add New Subscription"
                description="Link a new subscription"
                className={styles["meta-info"]}
              />
              <br />
              <Form
                form={form}
                initialValues={{
                  organization: organizationId,
                  service: subscription.service?.id,
                  dates: [
                    moment(subscription.startingOn),
                    subscription.expiringOn
                      ? moment(subscription.expiringOn)
                      : null,
                  ],
                }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={true}
              >
                <Row gutter={45}>
                  <Column span={7}>
                    <Form.Item
                      name="service"
                      label="Service"
                      rules={[
                        {
                          required: true,
                          message: "Please select a service",
                        },
                      ]}
                    >
                      <Select placeholder="Select a subscription">
                        {services.map((service) => (
                          <Select.Option value={service?.id} key={service?.id}>
                            {service?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Column>
                </Row>
                <Row gutter={45}>
                  <Column span={16}>
                    <Form.Item
                      name="dates"
                      label="Subscription Dates"
                      rules={[
                        {
                          required: true,
                          message: "Start Date is required",
                        },
                      ]}
                    >
                      <RangePicker
                        allowEmpty={[false, true]}
                        ranges={{
                          Today: [moment(), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                      />
                    </Form.Item>
                  </Column>
                  <Column span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={createLoading || updateLoading}
                        loading={createLoading || updateLoading}
                        className="gap-1"
                        icon={<Icon name="plus" size={14} color="white" />}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Column>
                </Row>
              </Form>
            </Card>
          </div>
        );
      })()}
    </Appshell>
  );
};

export default OrganizationDetails;
