import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Empty, Loader } from "@cea/ui-kit";

import Appshell from "../../components/Appshell";
import { servicesMap } from "../../utils";
import OrganizationContext from "../../contexts/OrganizationContext";

const GenericServiceDetails = () => {
  const [selectedServiceType, setSelectedServiceType] = useState(null);

  const navigate = useNavigate();
  const { serviceId, organizationId } = useParams();
  const {
    organization,
    service,
    fetchOrganizationDetails,
    fetchServiceDetails,
    loading,
  } = useContext(OrganizationContext);

  useEffect(() => {
    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }

    if (!service) {
      fetchServiceDetails({ id: serviceId });
    }

    if (organization) {
      const selectedSubscription = organization.subscriptions.find(
        (item) => item.service.id === serviceId
      );

      if (selectedSubscription) {
        setSelectedServiceType(selectedSubscription?.serviceType);
      } else {
        navigate("/");
      }
    }
  }, [organization]);

  return (
    <>
      {(() => {
        if (loading) {
          return (
            <Appshell>
              <div className="p-8">
                <Loader tip="Loading" />
              </div>
            </Appshell>
          );
        }

        if (selectedServiceType) {
          const SelectedService = servicesMap[selectedServiceType];

          return (
            <div>
              {SelectedService ? (
                <SelectedService organization={organization} />
              ) : (
                <Appshell>
                  <Empty
                    description={<p level={3}>Coming soon</p>}
                    className="pt-8"
                  />
                </Appshell>
              )}
            </div>
          );
        }

        return null;
      })()}
    </>
  );
};

export default GenericServiceDetails;
