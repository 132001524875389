import React from "react";

const OrganizationContext = React.createContext({
  organization: null,
  service: null,
  serviceMeta: null,
  updateOrganizationDetails: () => {},
  updateServiceDetails: () => {},
  updateServiceMeta: () => {},
  loading: null,
  fetchOrganizationDetails: () => {},
  fetchServiceDetails: () => {},
});

export default OrganizationContext;
