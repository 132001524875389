import PropTypes from "prop-types";

const FolderBack = ({ size = 64, fill = "#FFA000" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.6029 13.9996H28.8015L22.8456 8H7.95588C4.68015 8 2 10.6998 2 13.9996V25.9989H61.5588V19.9993C61.5588 16.6995 58.8787 13.9996 55.6029 13.9996Z"
      fill={fill}
    />
    <path
      d="M55.6029 13.334H7.95588C4.68015 13.334 2 16.0338 2 19.3336V49.3318C2 52.6316 4.68015 55.3314 7.95588 55.3314H55.6029C58.8787 55.3314 61.5588 52.6316 61.5588 49.3318V19.3336C61.5588 16.0338 58.8787 13.334 55.6029 13.334Z"
      fill={fill}
    />
    <path
      d="M27.5882 37.6293L20.2353 30.2219L27.5882 22.8145"
      stroke={fill === "#000" ? "white" : "black"}
      strokeWidth="2.96296"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7647 46.519V36.1486C43.7647 34.5769 43.145 33.0696 42.0418 31.9583C40.9386 30.847 39.4424 30.2227 37.8823 30.2227H20.2353"
      stroke={fill === "#000" ? "white" : "black"}
      strokeWidth="2.96296"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

FolderBack.defaultProps = {
  size: 64,
  fill: "black",
};

FolderBack.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default FolderBack;
