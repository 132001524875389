import PropTypes from "prop-types";

const MenuFold = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8221 15.1897H33.1078C33.2846 15.1897 33.4292 15.0451 33.4292 14.8683V12.6183C33.4292 12.4415 33.2846 12.2969 33.1078 12.2969H13.8221C13.6453 12.2969 13.5006 12.4415 13.5006 12.6183V14.8683C13.5006 15.0451 13.6453 15.1897 13.8221 15.1897ZM13.5006 23.3862C13.5006 23.5629 13.6453 23.7076 13.8221 23.7076H33.1078C33.2846 23.7076 33.4292 23.5629 33.4292 23.3862V21.1362C33.4292 20.9594 33.2846 20.8147 33.1078 20.8147H13.8221C13.6453 20.8147 13.5006 20.9594 13.5006 21.1362V23.3862ZM33.7506 3.85938H2.25063C2.07384 3.85938 1.9292 4.00402 1.9292 4.1808V6.4308C1.9292 6.60759 2.07384 6.75223 2.25063 6.75223H33.7506C33.9274 6.75223 34.0721 6.60759 34.0721 6.4308V4.1808C34.0721 4.00402 33.9274 3.85938 33.7506 3.85938ZM33.7506 29.2522H2.25063C2.07384 29.2522 1.9292 29.3969 1.9292 29.5737V31.8237C1.9292 32.0004 2.07384 32.1451 2.25063 32.1451H33.7506C33.9274 32.1451 34.0721 32.0004 34.0721 31.8237V29.5737C34.0721 29.3969 33.9274 29.2522 33.7506 29.2522ZM2.06581 18.2795L8.34572 23.2254C8.57875 23.4103 8.92429 23.2455 8.92429 22.9482V13.0563C8.92429 12.7589 8.58277 12.5942 8.34572 12.779L2.06581 17.725C2.02358 17.7578 1.98941 17.7999 1.96591 17.8479C1.9424 17.896 1.93018 17.9487 1.93018 18.0022C1.93018 18.0557 1.9424 18.1085 1.96591 18.1565C1.98941 18.2046 2.02358 18.2466 2.06581 18.2795Z"
      fill={fill}
    />
  </svg>
);

MenuFold.defaultProps = {
  size: 64,
  fill: "black",
};

MenuFold.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default MenuFold;
