import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";

import { SSELink } from "./utils/SSELink";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

export const sseLink = new SSELink({
  url: `${process.env.REACT_APP_API_URL}stream`,
  headers: () => {
    const token =
      sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

    return {
      authorization: token || "",
    };
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("admin-token");

  return {
    headers: {
      ...headers,
      authorization: token || process.env.REACT_APP_USER_TOKEN,
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  sseLink,
  authLink.concat(httpLink)
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
