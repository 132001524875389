import PropTypes from "prop-types";

const Create = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 26.1889V30.7489C4.5 31.1689 4.83 31.4989 5.25 31.4989H9.81C10.005 31.4989 10.2 31.4239 10.335 31.2739L26.715 14.9089L21.09 9.28387L4.725 25.6489C4.575 25.7989 4.5 25.9789 4.5 26.1889ZM31.065 10.5589C31.65 9.97387 31.65 9.02887 31.065 8.44387L27.555 4.93387C26.97 4.34887 26.025 4.34887 25.44 4.93387L22.695 7.67887L28.32 13.3039L31.065 10.5589Z"
      fill={fill}
    />
  </svg>
);

Create.defaultProps = {
  size: 64,
  fill: "black",
};

Create.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Create;
