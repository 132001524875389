import PropTypes from "prop-types";

import DateLabel from "./DateLabel";
import StringLabel from "./StringLabel";
import PercentageLabel from "./PercentageLabel";

const StatusLabel = ({ type, value }) => {
  const renderItem = () => {
    switch (type) {
      case "percentage":
        return <PercentageLabel value={value} />;

      case "string":
        return <StringLabel value={value} />;

      case "date":
        return <DateLabel {...value} />;

      default:
        return null;
    }
  };

  return renderItem();
};

StatusLabel.defaultProps = {
  type: null,
  value: null,
};

StatusLabel.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({ date: PropTypes.string, username: PropTypes.string }),
  ]),
};

StatusLabel.DateLabel = DateLabel;
StatusLabel.StringLabel = StringLabel;
StatusLabel.PercentageLabel = PercentageLabel;

export default StatusLabel;
