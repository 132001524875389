import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { MsalProvider } from "@azure/msal-react";

import App from "./App";
import { msalInstance } from "./msalConfig";
import { apolloClient } from "./apolloClient";

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </MsalProvider>,
  document.getElementById("root")
);
