import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import {
  Row,
  Form,
  Card,
  Icon,
  Input,
  Button,
  Upload,
  Loader,
  Avatar,
  Column,
  Breadcrumb,
  StatusLabel,
  Notification,
  ManagementTable,
} from "@cea/ui-kit";

import {
  UPDATE_ORGANIZATION,
  CREATE_ORGANIZATION,
  DELETE_SUBSCRIPTION,
} from "../../queries/organizations";
import {
  getFileFromAzure,
  getFilePath,
  getNotification,
  imageUploader,
  sortKeyAscDesc,
} from "../../utils";
import Appshell from "../../components/Appshell";
import AuthContext from "../../contexts/AuthContext";
import DeleteModal from "../../components/DeleteModal";
import OrganizationContext from "../../contexts/OrganizationContext";

import styles from "./organization-details.module.css";

const { Meta } = Card;

const OrganizationDetails = () => {
  const [imagePath, setImagePath] = useState(null);
  const [deleteModalState, setDeleteModalState] = useState({
    isOpen: false,
    variables: {},
    resource: null,
  });
  const [paginationState, setPaginationState] = useState({
    limit: 5,
    offset: 0,
    currentPage: 1,
  });

  const [form] = Form.useForm();
  const { organizationId } = useParams();
  const {
    loading,
    organization,
    updateServiceDetails,
    fetchOrganizationDetails,
    updateOrganizationDetails,
  } = useContext(OrganizationContext);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [createOrganization, { loading: createLoading }] =
    useMutation(CREATE_ORGANIZATION);
  const [updateOrganization, { loading: updateLoading }] =
    useMutation(UPDATE_ORGANIZATION);

  const onDeleteSubscription = (data) => {
    if (data.deleteSubscription?.id) {
      closeModal();

      const updatedOrganization = {
        ...organization,
        subscriptions: organization.subscriptions.filter(
          (item) => item.id !== data.deleteSubscription.id
        ),
      };

      updateOrganizationDetails(updatedOrganization);
    }
  };

  const columns = [
    {
      title: "Subscription Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => record?.service?.name,
      sorter: (a, b) =>
        sortKeyAscDesc({
          value1: a.service.name,
          value2: b.service.name,
          key: "string",
        }),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Subscribed On",
      dataIndex: "startingOn",
      key: "startingOn",
      render: (record) => {
        if (record) {
          return (
            <StatusLabel
              type="date"
              value={{
                date: dayjs(record).format("MMM D, YYYY HH:mm"),
              }}
            />
          );
        }
      },
      sorter: (a, b) =>
        sortKeyAscDesc({
          value1: a.createDate,
          value2: b.createDate,
          key: "string",
        }),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Expiring On",
      dataIndex: "expiringOn",
      key: "expiringOn",
      render: (record) => {
        if (record) {
          return (
            <StatusLabel
              type="date"
              value={{
                date: dayjs(record).format("MMM D, YYYY HH:mm"),
              }}
            />
          );
        }
      },
      sorter: (a, b) =>
        sortKeyAscDesc({
          value1: a.expiringOn,
          value2: b.expiringOn,
          key: "string",
        }),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (_, record) => (
        <div key={record.id} className="flex justify-between items-center">
          <Link
            to={`/organizations/${organization?.id}/services/${record?.service.id}`}
          >
            <div className="standard-flex gap-1 text-center">
              View Service
              <Icon name="arrow_lined_right" size={14} color="#1890ff" />
            </div>
          </Link>
          <div>
            <Link
              to={`/organizations/${organization?.id}/subscriptions/${record?.id}`}
            >
              Edit
            </Link>
            {user.isSuperAdmin || user.isSalesAdmin ? (
              <div
                className="text-red-500 hover:text-red-400 mt-2 cursor-pointer"
                onClick={() => {
                  setDeleteModalState({
                    isOpen: true,
                    variables: {
                      id: record?.id,
                    },
                    resource: "Subscription",
                  });
                }}
              >
                Delete
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (organizationId) {
      fetchOrganizationDetails(organizationId);
    }

    updateServiceDetails(null);
  }, []);

  useEffect(() => {
    if (organization) {
      getOrganizationLogo();
    }
  }, [organization]);

  const onFinish = async (values) => {
    try {
      const { name, logo, clientId } = values;

      const logoName = logo ? logo[0].name : null;

      if (organizationId) {
        await updateOrganization({
          variables: {
            id: organizationId,
            name,
            logo: logoName,
            clientId: +clientId,
          },
          notifyOnNetworkStatusChange: true,
        });

        if (organization.name !== name) {
          updateOrganizationDetails({ ...organization, name });
        }

        Notification.success({ message: "Organization details updated" });
      } else {
        const { data } = await createOrganization({
          variables: { name, logo: logoName, clientId: +clientId },
          notifyOnNetworkStatusChange: true,
        });

        if (data?.createOrganization?.id) {
          form.setFieldsValue((values) => ({
            ...values,
            id: data.createOrganization.id,
          }));

          await fetchOrganizationDetails(data.createOrganization.id);
          navigate(`/organizations/${data.createOrganization.id}`);
        }

        Notification.success({ message: "Organization created successfully" });
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({ message });
    }
  };

  const onImageUpload = async (info) => {
    const { updatedFileName, newFile } = await imageUploader(info);
    const filePath = getFilePath(updatedFileName);

    form.setFieldsValue({
      logo: [newFile],
    });

    setImagePath(filePath);
  };

  const getOrganizationLogo = async () => {
    if (organization.logo) {
      const logoFile = await getFileFromAzure(organization?.logo);
      const filePath = getFilePath(organization.logo);

      setImagePath(filePath);

      form.setFieldsValue({
        logo: [logoFile],
      });
    }
  };

  const onPaginationChange = (page) => {
    if (paginationState.currentPage !== page) {
      setPaginationState((prevState) => ({
        ...prevState,
        offset: (page - 1) * paginationState.limit,
        currentPage: page,
      }));
    }
  };

  const closeModal = () => {
    setDeleteModalState({
      isOpen: false,
      variables: {},
      resource: null,
    });
  };

  return (
    <Appshell>
      {(() => {
        if (organizationId && loading) {
          return (
            <div className="p-8">
              <Loader tip="Loading" />
            </div>
          );
        }

        if (organizationId && !organization) {
          return (
            <div className="page-main-container">Organization Not Found!</div>
          );
        }

        return (
          <div className="page-main-container">
            <div className="breadcrumb-holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/organizations">Organizations</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {organizationId ? organization?.name : "Create"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card>
              <Meta
                title="Details"
                description="Manage selected company details."
                className={styles["meta-info"]}
              />
              <br />
              <Form
                form={form}
                initialValues={{
                  name: organization?.name,
                  clientId: organization?.clientId,
                }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={true}
              >
                <Row gutter={45}>
                  <Column span={8}>
                    <Form.Item
                      name="name"
                      label="Organization name"
                      rules={[
                        {
                          required: true,
                          message: "Organization name can't be empty",
                        },
                      ]}
                    >
                      <Input placeholder="Enter organization name..." />
                    </Form.Item>
                  </Column>
                  <Column span={12}>
                    <Row align="middle" gutter={25}>
                      <Column>
                        <Avatar
                          style={{
                            width: "4rem",
                            height: "4rem",
                            background: "#7265e6",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: "2rem",
                          }}
                          src={imagePath}
                        >
                          {form.getFieldValue("name") && imagePath
                            ? form.getFieldValue("name")[0]
                            : null}
                        </Avatar>
                      </Column>
                      <Column>
                        <Form.Item
                          name="logo"
                          label="Change Profile Photo"
                          valuePropName="fileList"
                          getValueFromEvent={(event) => [event?.file]}
                        >
                          <Upload
                            name="logo"
                            beforeUpload={(_, __) => false}
                            onChange={onImageUpload}
                            multiple={false}
                            listType="picture"
                            showUploadList={false}
                          >
                            <Button className={styles["upload-button"]}>
                              <Icon
                                name="plus"
                                color="rgba(0,0,0,.5)"
                                size={12}
                                className="mr-1"
                              />
                              Change
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Column>
                    </Row>
                  </Column>
                  <Column span={8}>
                    <Form.Item
                      name="clientId"
                      label="Client ID"
                      rules={[
                        {
                          required: true,
                          message: "Client ID can't be empty",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Client ID..."
                        min={0}
                      />
                    </Form.Item>
                  </Column>
                  <Column span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          updateLoading ||
                          createLoading ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                        loading={createLoading || updateLoading}
                        className="gap-1"
                        icon={<Icon name="plus" size={14} color="white" />}
                      >
                        {organizationId ? "Update" : "Create"}
                      </Button>
                    </Form.Item>
                  </Column>
                </Row>
              </Form>
            </Card>
            {organizationId || organization?.id ? (
              <div>
                <div className={styles["subscription-holder"]}>
                  <Meta
                    title="Subscriptions"
                    description="Manage subscriptions"
                    className={styles["meta-info"]}
                  />
                  <Link
                    to={`/organizations/${organizationId}/subscriptions/create`}
                  >
                    <Button
                      type="primary"
                      className="theme-button gap-1"
                      icon={<Icon name="plus" size={14} color="white" />}
                    >
                      Add Subscription
                    </Button>
                  </Link>
                </div>
                <div className={styles.table}>
                  <ManagementTable
                    loading={loading}
                    columns={columns}
                    data={organization?.subscriptions}
                    currentPage={paginationState.currentPage}
                    pageSize={paginationState.limit}
                    listSize={organization?.subscriptions.length}
                    onPageChange={onPaginationChange}
                    bordered={false}
                    showSorterTooltip={false}
                    ExpandedSection={null}
                    isPaginated
                  />
                </div>
              </div>
            ) : null}
          </div>
        );
      })()}
      <DeleteModal
        isVisible={deleteModalState.isOpen}
        resource={deleteModalState.resource}
        variables={deleteModalState.variables}
        handleCancel={closeModal}
        handleOk={onDeleteSubscription}
        mutationName={DELETE_SUBSCRIPTION}
      />
    </Appshell>
  );
};

export default OrganizationDetails;
