export const sidebarConfig = {
  "safety-inspection": [
    {
      title: "Programs",
      link: "/safety-inspection",
      validations: [],
    },
    {
      title: "Subscription Management",
      link: "/safety-inspection/subscription",
      validations: [],
    },
    {
      title: "Help Center",
      link: "/safety-inspection/help-center",
      validations: [],
    },
    {
      title: "Settings",
      link: "/safety-inspection/settings",
      validations: [],
    },
  ],
  emp: [
    {
      title: "Dashboard",
      link: "/emp",
      validations: [],
    },
    {
      title: "Projects",
      link: "/emp/projects",
      validations: [],
    },
    {
      title: "Help / FAQ",
      link: "/emp/help",
      validations: [],
    },

    {
      title: "Feedback",
      link: "/emp/feedback",
      validations: [],
    },

    {
      title: "Subscription Management",
      link: "/emp/subscription",
      validations: [],
    },
  ],
  admin: [
    {
      title: "Help Management",
      link: "/emp/admin/help",
      validations: [],
    },
    {
      title: "Feedback Management",
      link: "/emp/admin/feedback",
      validations: [],
    },
    {
      title: "Service Management",
      link: "/emp/admin/service-management",
      validations: [],
    },
  ],
};

export const sortOptions = [
  { label: "Name Ascending", value: "name:asc" },
  { label: "Name Descending", value: "name:desc" },
  { label: "Date Oldest", value: "createDate:asc" },
  { label: "Date Newest", value: "createDate:desc" },
];

export const getOrderByObject = (orderBy) => {
  const orderByValues = orderBy?.split(":");
  let sortObject = {};

  if (orderByValues?.length) {
    sortObject = {
      [orderByValues[0]]: orderByValues[1],
    };
  }

  return sortObject;
};
