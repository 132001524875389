import PropTypes from "prop-types";

const Plus = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.205 3.53906H16.7943C16.58 3.53906 16.4729 3.64621 16.4729 3.86049V16.4766H4.50063C4.28634 16.4766 4.1792 16.5837 4.1792 16.798V19.2087C4.1792 19.423 4.28634 19.5301 4.50063 19.5301H16.4729V32.1462C16.4729 32.3605 16.58 32.4676 16.7943 32.4676H19.205C19.4193 32.4676 19.5265 32.3605 19.5265 32.1462V19.5301H31.5006C31.7149 19.5301 31.8221 19.423 31.8221 19.2087V16.798C31.8221 16.5837 31.7149 16.4766 31.5006 16.4766H19.5265V3.86049C19.5265 3.64621 19.4193 3.53906 19.205 3.53906Z"
      fill={fill}
    />
  </svg>
);

Plus.defaultProps = {
  size: 64,
  fill: "black",
};

Plus.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Plus;
