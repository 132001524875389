import ArrowDown from "./ArrowDown";
import ArrowRight from "./ArrowRight";
import ArrowSolidDown from "./ArrowSolidDown";
import ArrowSolidUp from "./ArrowSolidUp";
import ArrowUp from "./ArrowUp";
import Delete from "./Delete";
import Edit from "./Edit";
import LayoutGrid from "./LayoutGrid";
import LayoutList from "./LayoutList";
import MoreMenu from "./MoreMenu";
import Notification from "./Notification";
import Plus from "./Plus";
import SearchIcon from "./Search";
import Visibility from "./Visibility";
import Collapse from "./Collapse";
import Briefcase from "./Briefcase";
import Folder from "./Folder";
import FileDefault from "./FileDefault";
import FileDocx from "./FileDocx";
import FilePdf from "./FilePdf";
import MenuFold from "./MenuFold";
import MenuUnfold from "./MenuUnfold";
import User from "./User";
import Laptop from "./Laptop";
import Bulb from "./Bulb";
import Contact from "./Contact";
import Dashboard from "./Dashboard";
import Logs from "./Logs";
import ArrowLeft from "./ArrowLeft";
import ArrowSolidLeft from "./ArrowSolidLeft";
import ArrowSolidRight from "./ArrowSolidRight";
import ArrowLinedRight from "./ArrowLinedRight";
import CheckCircle from "./CheckCircle";
import IssuesChecked from "./IssuesChecked";
import IssuesCircle from "./IssuesCircle";
import Filter from "./Filter";
import SortAscending from "./SortAscending";
import SortDescending from "./SortDescending";
import Address from "./Address";
import BatteryCharging from "./BatteryCharging";
import Close from "./Close";
import Create from "./Create";
import Inbox from "./Inbox";
import Location from "./Location";
import Notes from "./Notes";
import Solar from "./Solar";
import Upload from "./Upload";
import Save from "./Save";
import BulbOutlined from "./BulbOutlined";
import UserOutlined from "./UserOutlined";
import FolderOutlined from "./FolderOutlined";
import FileDefaultOutlined from "./FileDefaultOutlined";
import FileDocxOutlined from "./FileDocxOutlined";
import CreateOutlined from "./CreateOutlined";
import LocationOutlined from "./LocationOutlined";
import NotesOutlined from "./NotesOutlined";
import FolderBack from "./FolderBack";
import Check from "./Check";
import CheckFilled from "./CheckFilled";
import BarChart from "./BarChart";
import SubMenuArrowUp from "./SubMenuArrowUp";
import SubMenuArrowDown from "./SubMenuArrowDown";

const filledIcons = {
  arrow_solid_up: ArrowSolidUp,
  arrow_solid_down: ArrowSolidDown,
  arrow_solid_left: ArrowSolidLeft,
  arrow_solid_right: ArrowSolidRight,
  arrow_lined_right: ArrowLinedRight,
  arrow_up: ArrowUp,
  arrow_down: ArrowDown,
  arrow_left: ArrowLeft,
  arrow_right: ArrowRight,
  collapse: Collapse,
  delete: Delete,
  edit: Edit,
  layout_grid: LayoutGrid,
  layout_list: LayoutList,
  more_menu: MoreMenu,
  notification: Notification,
  plus: Plus,
  search: SearchIcon,
  visibility: Visibility,
  menu_fold: MenuFold,
  menu_unfold: MenuUnfold,
  briefcase: Briefcase,
  folder: Folder,
  file: FileDefault,
  file_docx: FileDocx,
  file_pdf: FilePdf,
  user: User,
  laptop: Laptop,
  bulb: Bulb,
  contact: Contact,
  dashboard: Dashboard,
  logs: Logs,
  check: Check,
  check_outline: CheckCircle,
  check_filled: CheckFilled,
  issues_checked: IssuesChecked,
  issues_circle: IssuesCircle,
  address: Address,
  sort_ascending: SortAscending,
  sort_descending: SortDescending,
  filter: Filter,
  battery: BatteryCharging,
  close: Close,
  create: Create,
  inbox: Inbox,
  location: Location,
  notes: Notes,
  solar: Solar,
  upload: Upload,
  save: Save,
  folder_back: FolderBack,
  bar_chart: BarChart,
  sub_menu_arrow_up: SubMenuArrowUp,
  sub_menu_arrow_down: SubMenuArrowDown,
};

const outlinedIcons = {
  bulb_outlined: BulbOutlined,
  user_outlined: UserOutlined,
  folder_outlined: FolderOutlined,
  file_outlined: FileDefaultOutlined,
  file_docx_outlined: FileDocxOutlined,
  create_outlined: CreateOutlined,
  location_outlined: LocationOutlined,
  notes_outlined: NotesOutlined,
};

export default {
  ...filledIcons,
  ...outlinedIcons,
};
