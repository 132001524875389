import { useState } from "react";

const usePagination = ({ limit = 10, offset = 0, currentPage = 1 } = {}) => {
  const [paginationState, setPaginationState] = useState({
    limit,
    offset,
    currentPage,
  });

  const updatePaginationState = (updatedState) => {
    setPaginationState(updatedState);
  };

  const onPaginationChange = (page) => {
    if (paginationState.currentPage !== page) {
      updatePaginationState(
        {
          ...paginationState,
          offset: (page - 1) * paginationState.limit,
          currentPage: page,
        },
        page
      );
    }
  };

  return {
    pageLimit: paginationState.limit,
    offset: paginationState.offset,
    currentPage: paginationState.currentPage,
    onPaginationChange,
  };
};

export default usePagination;
