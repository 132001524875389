import PropTypes from "prop-types";

const Briefcase = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4 8.73683V5.36842H14.6V8.73683H21.4ZM4.4 12.1052V30.6316H31.6V12.1052H4.4ZM31.6 8.73683C33.487 8.73683 35 10.2358 35 12.1052V30.6316C35 32.5011 33.487 34 31.6 34H4.4C2.513 34 1 32.5011 1 30.6316L1.017 12.1052C1.017 10.2358 2.513 8.73683 4.4 8.73683H11.2V5.36842C11.2 3.49895 12.713 2 14.6 2H21.4C23.287 2 24.8 3.49895 24.8 5.36842V8.73683H31.6Z"
      fill={fill}
    />
  </svg>
);

Briefcase.defaultProps = {
  size: 64,
  fill: "black",
};

Briefcase.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Briefcase;
