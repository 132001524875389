import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  Button,
  Column,
  Form,
  Icon,
  Modal,
  Notification,
  Row,
  Upload,
} from "@cea/ui-kit";

import { CREATE_EBR_FILE } from "../../../queries/miServices";
import { getNotification, imageUploader } from "../../../utils";
import OrganizationContext from "../../../contexts/OrganizationContext";
import Alert from "../../../components/NavigationAlert/NavigationAlert";
import { useCallbackPrompt } from "../../../hooks";

const ReportUploadForm = ({ formInstance }) => (
  <Form
    form={formInstance}
    initialValues={{}}
    layout="vertical"
    requiredMark={true}
  >
    <Row>
      <Column span={24}>
        <Form.Item
          name="link"
          label="Upload File"
          rules={[{ required: true, message: "File is required" }]}
          valuePropName="fileList"
          getValueFromEvent={(event) => event?.fileList}
        >
          <Upload.Dragger
            name="link"
            beforeUpload={(_, __) => false}
            multiple={false}
            listType="text"
            maxCount={1}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: (
                <Icon
                  name="delete"
                  size={14}
                  onClick={(e) => console.log(e, "custom removeIcon event")}
                />
              ),
            }}
          >
            <div className="flex items-center justify-center w-full mb-4">
              <Icon name="upload" color="var(--primary-theme)" size={24} />
            </div>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Column>
    </Row>
  </Form>
);

export const EBRReportUploadModal = ({
  isVisible,
  onFileCreate,
  onFileUpdate,
  handleCancel,
  parentId,
}) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [uploadEBRFile] = useMutation(CREATE_EBR_FILE);
  const { organization } = useContext(OrganizationContext);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(loading);

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      setLoading(false);
    }
  }, [isVisible]);

  const onFormSubmit = async () => {
    const tempId = Date.now();

    try {
      setLoading(true);

      const { link } = form.getFieldsValue();
      let parentName = null;
      let parentIdToUse = parentId;

      onFileCreate({
        tempId,
        link,
        name: link[0].name,
        organization: organization.id,
        uploading: true,
      });

      form.resetFields();
      setLoading(false);

      const { newFile } = await imageUploader({ file: link[0] });

      const { data } = await uploadEBRFile({
        variables: {
          parentId: parentIdToUse,
          link: newFile.name,
          name: link[0].name,
          organization: organization.id,
        },
        notifyOnNetworkStatusChange: true,
      });

      const { id } = data.createEBRFile;

      if (!parentName) {
        parentName = data.createEBRFile.parentName;
      }

      if (!parentIdToUse) {
        parentIdToUse = data.createCRMIFile.parentId;
      }

      const uploadedFile = {
        link: newFile.name,
        name: link[0].name,
        parentId: parentIdToUse,
        id,
        tempId,
      };

      onFileUpdate(uploadedFile, { parentName, parentId: parentIdToUse });
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });

      handleCancel(tempId);
    }
  };

  return (
    <>
      <Modal
        title="Upload Report"
        visible={isVisible}
        onOk={onFormSubmit}
        onCancel={handleCancel}
        centered
        width={720}
        footer={[
          <Button
            key="submit"
            loading={loading}
            type="primary"
            onClick={onFormSubmit}
            className="ml-auto"
            icon={<Icon name="plus" size={14} color="#fff" />}
          >
            Save
          </Button>,
        ]}
      >
        <ReportUploadForm formInstance={form} />
      </Modal>
      <Alert
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </>
  );
};

ReportUploadForm.defaultProps = {
  formInstance: {
    setFieldsValue: () => {},
  },
  summaryReport: false,
};

ReportUploadForm.propTypes = {
  formInstance: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }),
  summaryReport: PropTypes.bool,
};

EBRReportUploadModal.defaultProps = {
  isVisible: false,
  onFileCreate: () => {},
  onFileUpdate: () => {},
  handleCancel: () => {},
  parentId: null,
};

EBRReportUploadModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onFileCreate: PropTypes.func.isRequired,
  onFileUpdate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};

export default EBRReportUploadModal;
