import PropTypes from "prop-types";

const FileDefaultOutlined = ({ size = 64, fill = "#000" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5972 8.87177H6.26041L12.5797 2.09793C12.8737 1.78761 13.2184 1.53581 13.5971 1.35416L13.5972 8.87177ZM30 3.51143V32.4884C30.0017 33.1515 29.7539 33.7884 29.311 34.2592C28.8677 34.7298 28.2657 34.9962 27.6366 35H7.36394C6.73487 34.9965 6.13254 34.7302 5.68927 34.2594C5.24634 33.7884 4.99834 33.1518 5.00001 32.4884V11.7565C5.00001 11.6624 5.01881 11.5728 5.02541 11.4802H14.8345V11.4799C15.1625 11.4802 15.4771 11.3428 15.7093 11.098C15.9413 10.8535 16.0716 10.5216 16.0716 10.1759V1H27.6367H27.6365C28.2655 1.00379 28.8675 1.27014 29.3108 1.74085C29.7537 2.21155 30.0015 2.84846 29.9998 3.51164L30 3.51143ZM18.5459 10.1759C18.5456 10.5218 18.6759 10.8536 18.9079 11.0981C19.1401 11.343 19.4547 11.4803 19.7831 11.48H23.4946C23.9364 11.48 24.3449 11.2314 24.5657 10.828C24.7869 10.4245 24.7869 9.92732 24.5657 9.52381C24.3449 9.12038 23.9364 8.87177 23.4946 8.87177H19.7831C19.4547 8.87177 19.1401 9.00918 18.9079 9.25371C18.6759 9.49824 18.5456 9.83009 18.5459 10.1759ZM24.7583 25.8969C24.7602 25.5511 24.6318 25.2184 24.4012 24.9725C24.1703 24.7265 23.8563 24.5873 23.5283 24.5853L11.1296 24.5114C10.7988 24.5 10.4782 24.6325 10.2429 24.8776C10.0076 25.1226 9.87807 25.4591 9.88527 25.8078C9.88307 26.1537 10.0117 26.4864 10.2423 26.7324C10.4729 26.9783 10.7869 27.1178 11.115 27.1195L23.5136 27.1935H23.5211C23.8477 27.1935 24.1615 27.0569 24.3932 26.8141C24.6251 26.5714 24.7563 26.2416 24.7583 25.8969ZM24.7583 18.0722C24.7602 17.7264 24.6318 17.3936 24.4012 17.1477C24.1703 16.9017 23.8563 16.7626 23.5283 16.7605L11.1296 16.6866H11.1225C10.6803 16.6855 10.2713 16.9326 10.049 17.3355C9.82701 17.7383 9.82561 18.2352 10.0454 18.6395C10.2653 19.0436 10.6731 19.2937 11.115 19.2948L23.5136 19.3688H23.5211C23.8477 19.3688 24.1615 19.2322 24.3931 18.9894C24.6251 18.7467 24.7563 18.4169 24.7582 18.0722H24.7583Z"
      fill="none"
      stroke={fill}
      strokeWidth={2}
    />
  </svg>
);

FileDefaultOutlined.defaultProps = {
  size: 64,
  fill: "black",
};

FileDefaultOutlined.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default FileDefaultOutlined;
