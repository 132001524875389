import { Modal, Notification } from "@cea/ui-kit";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import {
  DELETE_PV_PFT_FILE,
  DELETE_PV_PFT_FOLDER,
} from "../../queries/miServices";
import { getNotification } from "../../utils";

const useFileFolderDeleteDialog = ({ onFileDelete, onFolderDelete }) => {
  const [deletePVPFTFile] = useMutation(DELETE_PV_PFT_FILE);
  const [deletePVPFTFolder] = useMutation(DELETE_PV_PFT_FOLDER);

  const deleteFolder = async (id) => {
    try {
      await deletePVPFTFolder({
        variables: {
          id,
        },
        notifyOnNetworkStatusChange: true,
      });

      onFolderDelete(id);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const deleteFile = async (id) => {
    try {
      await deletePVPFTFile({
        variables: {
          id,
        },
        notifyOnNetworkStatusChange: true,
      });

      onFileDelete(id);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  const ShowDeleteDialog = (type, id, name) => {
    const isFile = type === "file";

    Modal.confirm({
      title: `Delete ${isFile ? "File" : "Folder"} :  ${name}`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => {
        if (isFile) {
          deleteFile(id);
        } else {
          deleteFolder(id);
        }
      },
    });
  };

  return { ShowDeleteDialog };
};

useFileFolderDeleteDialog.defaultProps = {
  onFolderDelete: () => {},
  onFileDelete: () => {},
};

useFileFolderDeleteDialog.propTypes = {
  onFolderCreate: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export default useFileFolderDeleteDialog;
