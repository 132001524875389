import React, { useContext } from "react";
import cx from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, Icon, Avatar } from "@cea/ui-kit";

import { getUserInitials } from "../../utils";
import AuthContext from "../../contexts/AuthContext";

import styles from "./navbar.module.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const logout = () => {
    localStorage.removeItem("admin-token");

    navigate("/login");
  };

  const getUserDesignation = () => {
    if (user?.isSuperAdmin) {
      return "Super Admin";
    }

    if (user?.isSalesAdmin) {
      return "Sales Admin";
    }

    return "CEA Member";
  };

  const menu = () => (
    <Menu className="pointer-events-auto">
      <Menu.Item key="0">
        <Link to="/user">
          <div>Manage Profile</div>
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <div onClick={logout}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.navbar)}>
      <div>
        <Icon name="notification" size={22} />
      </div>
      <div>
        <Dropdown overlay={menu} trigger={["click"]} arrow>
          <div className={styles["user-details-container"]}>
            <div className={styles["dropdown-info"]}>
              <Avatar shape="square" className="text-white bg-black">
                {user && getUserInitials(user.name)}
              </Avatar>
              <div>
                {user?.name}
                <div className={styles["user-designation"]}>
                  {getUserDesignation()}
                </div>
              </div>
            </div>
            <Icon name="arrow_down" size={16} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
