import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./string-label.module.css";

const IN_PROGRESS = "In-Progress";
const COMPLETE = "Complete";
const CLOSED = "Closed";
const HIGH_RISK = "High Risk";
const NONE = "None";
const LOW = "Low";
const MODERATE = "Moderate";

const getClassName = (value) => {
  switch (value) {
    case IN_PROGRESS:
      return "warning";

    case COMPLETE:
      return "success";

    case CLOSED:
      return "danger";

    case HIGH_RISK:
      return "danger";

    case NONE:
      return "warning";

    case LOW:
      return "warning";

    case MODERATE:
      return "success";

    default:
      return "";
  }
};

const StringLabel = ({ value }) => (
  <div className={cx(styles["item-container"], styles[getClassName(value)])}>
    <div className={cx(styles.dot, styles[getClassName(value)])} /> {value}
  </div>
);

StringLabel.defaultProps = {
  value: null,
};

StringLabel.propTypes = {
  value: PropTypes.string,
};

export default StringLabel;
