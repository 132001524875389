import PropTypes from "prop-types";

const MoreMenu = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 6.71484C15.75 7.31158 15.9871 7.88388 16.409 8.30583C16.831 8.72779 17.4033 8.96484 18 8.96484C18.5967 8.96484 19.169 8.72779 19.591 8.30583C20.0129 7.88388 20.25 7.31158 20.25 6.71484C20.25 6.11811 20.0129 5.54581 19.591 5.12385C19.169 4.7019 18.5967 4.46484 18 4.46484C17.4033 4.46484 16.831 4.7019 16.409 5.12385C15.9871 5.54581 15.75 6.11811 15.75 6.71484ZM15.75 17.9648C15.75 18.5616 15.9871 19.1339 16.409 19.5558C16.831 19.9778 17.4033 20.2148 18 20.2148C18.5967 20.2148 19.169 19.9778 19.591 19.5558C20.0129 19.1339 20.25 18.5616 20.25 17.9648C20.25 17.3681 20.0129 16.7958 19.591 16.3739C19.169 15.9519 18.5967 15.7148 18 15.7148C17.4033 15.7148 16.831 15.9519 16.409 16.3739C15.9871 16.7958 15.75 17.3681 15.75 17.9648ZM15.75 29.2148C15.75 29.8116 15.9871 30.3839 16.409 30.8058C16.831 31.2278 17.4033 31.4648 18 31.4648C18.5967 31.4648 19.169 31.2278 19.591 30.8058C20.0129 30.3839 20.25 29.8116 20.25 29.2148C20.25 28.6181 20.0129 28.0458 19.591 27.6239C19.169 27.2019 18.5967 26.9648 18 26.9648C17.4033 26.9648 16.831 27.2019 16.409 27.6239C15.9871 28.0458 15.75 28.6181 15.75 29.2148Z"
      fill={fill}
    />
  </svg>
);

MoreMenu.defaultProps = {
  size: 64,
  fill: "black",
  viewbox: "0 0 20 20",
};

MoreMenu.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  viewbox: PropTypes.string,
};

export default MoreMenu;
