import PropTypes from "prop-types";

const MenuUnfold = ({ size = 64, fill = "black" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8221 15.1897H33.1078C33.2846 15.1897 33.4292 15.0451 33.4292 14.8683V12.6183C33.4292 12.4415 33.2846 12.2969 33.1078 12.2969H13.8221C13.6453 12.2969 13.5006 12.4415 13.5006 12.6183V14.8683C13.5006 15.0451 13.6453 15.1897 13.8221 15.1897ZM13.5006 23.3862C13.5006 23.5629 13.6453 23.7076 13.8221 23.7076H33.1078C33.2846 23.7076 33.4292 23.5629 33.4292 23.3862V21.1362C33.4292 20.9594 33.2846 20.8147 33.1078 20.8147H13.8221C13.6453 20.8147 13.5006 20.9594 13.5006 21.1362V23.3862ZM33.7506 3.85938H2.25063C2.07384 3.85938 1.9292 4.00402 1.9292 4.1808V6.4308C1.9292 6.60759 2.07384 6.75223 2.25063 6.75223H33.7506C33.9274 6.75223 34.0721 6.60759 34.0721 6.4308V4.1808C34.0721 4.00402 33.9274 3.85938 33.7506 3.85938ZM33.7506 29.2522H2.25063C2.07384 29.2522 1.9292 29.3969 1.9292 29.5737V31.8237C1.9292 32.0004 2.07384 32.1451 2.25063 32.1451H33.7506C33.9274 32.1451 34.0721 32.0004 34.0721 31.8237V29.5737C34.0721 29.3969 33.9274 29.2522 33.7506 29.2522ZM3.15063 23.2295L9.43054 18.2835C9.47282 18.2502 9.507 18.2078 9.53051 18.1595C9.55401 18.1111 9.56623 18.058 9.56623 18.0042C9.56623 17.9505 9.55401 17.8974 9.53051 17.849C9.507 17.8006 9.47282 17.7582 9.43054 17.725L3.15063 12.775C2.91759 12.5902 2.57206 12.7549 2.57206 13.0522V22.9482C2.57203 23.0153 2.5909 23.0811 2.62649 23.138C2.66208 23.1949 2.71296 23.2406 2.77331 23.2699C2.83366 23.2993 2.90106 23.311 2.96778 23.3039C3.0345 23.2967 3.09787 23.2709 3.15063 23.2295Z"
      fill={fill}
    />
  </svg>
);

MenuUnfold.defaultProps = {
  size: 64,
  fill: "black",
};

MenuUnfold.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default MenuUnfold;
