import PropTypes from "prop-types";

const SubMenuArrowDown = ({ size = 14, fill = "none" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91427 3.05551C2.52374 2.66499 1.96972 2.58584 1.67683 2.87873C1.38394 3.17163 1.46308 3.72565 1.85361 4.11617L4.68203 6.9446C4.92536 7.18792 5.23216 7.31036 5.49971 7.29725C5.76737 7.31051 6.07436 7.18807 6.31782 6.94462L9.14624 4.11619C9.53677 3.72567 9.61591 3.17165 9.32302 2.87875C9.03013 2.58586 8.47611 2.66501 8.08558 3.05553L5.49993 5.64118L2.91427 3.05551Z"
      fill={fill}
      fillOpacity="0.85"
    />
  </svg>
);

SubMenuArrowDown.defaultProps = {
  size: 14,
  fill: "black",
};

SubMenuArrowDown.prototype = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default SubMenuArrowDown;
