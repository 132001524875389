import { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Empty, File, Notification, StatusLabel } from "@cea/ui-kit";

import { getFilePath, getNotification } from "../../../utils";
import RenameFolderModal from "../../../components/RenameFolderModal/RenameFolderModal";
import { DOWNLOAD_QA_FOLDER } from "../../../queries/qaServices";

import styles from "./reports-list.module.css";
import DownloadNotification from "./components/DownloadNotification";
import useFileFolderDeleteDialog from "./components/useFileFolderDeleteDialog";

const ReportsList = ({
  header,
  navHolder,
  folderData,
  setFolderData,
  loading,
  onRename,
}) => {
  const [renameModalState, setRenameModalState] = useState({
    isVisible: false,
    details: null,
  });
  const [activeDownloads, setActiveDownloads] = useState({});

  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const onFolderClick = (type, item) => {
    if (type === "folder") {
      const { id } = item;

      navigate({
        search: `?folderId=${id}`,
      });
    }
  };

  const onFolderDelete = (id) => {
    setFolderData((prevState) => ({
      ...prevState,
      folders: prevState.folders.filter((folder) => folder.id !== id),
    }));
  };

  const onFileDelete = (id) => {
    setFolderData((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file) => file.id !== id),
    }));
  };

  const { ShowDeleteDialog } = useFileFolderDeleteDialog({
    onFileDelete,
    onFolderDelete,
  });

  const initiateFolderDownload = async (folderId) => {
    try {
      await apolloClient.query({
        query: DOWNLOAD_QA_FOLDER,
        variables: {
          id: folderId,
        },
        fetchPolicy: "network-only",
      });

      setActiveDownloads((prev) => ({
        ...prev,
        [folderId]: { isActive: true, initiated: true },
      }));
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
        placement: "bottomRight",
      });
    }
  };

  const removeActiveDownload = (folderId) => {
    setActiveDownloads((prevState) => ({
      ...prevState,
      [folderId]: {
        ...prevState[folderId],
        isActive: false,
      },
    }));
  };

  const renderDownloadNotification = () =>
    // eslint-disable-next-line array-callback-return
    Object.keys(activeDownloads).map((folderId) => {
      if (activeDownloads[folderId].initiated) {
        return (
          <DownloadNotification
            key={folderId}
            folderId={folderId}
            isActive={activeDownloads[folderId].isActive}
            removeActiveDownload={removeActiveDownload}
          />
        );
      }
    });

  const renderFileContextMenu = (type, item) => {
    const permissionedActions = [
      {
        title: "Download",
        action: () => {
          if (type === "folder") {
            initiateFolderDownload(item.id);
          } else {
            window.open(getFilePath(item.link), "_blank");
          }
        },
      },
      {
        title: "Rename",
        action: () => {
          setRenameModalState({
            isVisible: true,
            details: item,
          });
        },
      },
    ];

    // - Role-wise permissions or type-wise actions can be here.
    permissionedActions.push({
      title: "Delete",
      action: () => {
        ShowDeleteDialog(type, item?.id, item?.name);
      },
    });

    return [...permissionedActions];
  };

  const renderFileColumns = (type, item) => [
    {
      span: 15,
      heading: item?.name,
    },
    {
      span: 5,
      heading: (
        <StatusLabel
          type="date"
          value={{
            date: dayjs(item.createDate).format("MMM D, YYYY HH:mm"),
            username: item?.createdBy?.name,
          }}
        />
      ),
    },
  ];

  const renderFiles = () => {
    const filesAndFolders = [...folderData?.folders, ...folderData?.files];

    return filesAndFolders?.map((item) => {
      const itemType = item.link ? "file" : "folder";

      return (
        <div key={item.id}>
          <File
            file={{
              type: itemType,
              columns: [...renderFileColumns(itemType, item)],
            }}
            onClick={() => onFolderClick(itemType, item)}
            contextMenu={[...renderFileContextMenu(itemType, item)]}
          />
        </div>
      );
    });
  };

  const renderBackButton = () => (
    <div>
      {folderData?.parentId ? (
        <div className={styles["previous-page-link-container"]}>
          <div
            className={styles["previous-page-link"]}
            onClick={() => {
              navigate({
                search: `?folderId=${folderData.parentId}`,
              });
            }}
          >
            ...
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <div>
        {header}
        <div className={styles.holder}>
          {navHolder}
          <div className="mt-8">
            {(() => {
              if (loading === "loading_data") {
                return <File.FileSkeleton />;
              }

              if (!folderData?.files?.length && !folderData?.folders?.length) {
                return (
                  <>
                    {renderBackButton()}
                    <Empty description={<p level={3}>No Reports added!</p>} />
                  </>
                );
              }

              return (
                <div>
                  {renderBackButton()}
                  {renderFiles()}
                  {renderDownloadNotification()}
                </div>
              );
            })()}
          </div>
        </div>
      </div>
      {renameModalState.isVisible && (
        <RenameFolderModal
          {...renameModalState}
          handleOk={async (...rest) => {
            await onRename(...rest);

            setRenameModalState({
              isVisible: false,
              details: null,
            });
          }}
          loading={loading === "loading_rename"}
          handleCancel={() => {
            setRenameModalState({
              isVisible: false,
              details: null,
            });
          }}
        />
      )}
    </>
  );
};

ReportsList.defaultProps = {
  header: null,
  navHolder: null,
  folderData: {
    files: [],
    folders: [],
    parentId: null,
  },
  loading: "loading_data",
  setFolderData: () => {},
  onRename: () => {},
  downloadFolder: () => {},
};

ReportsList.propTypes = {
  header: PropTypes.node,
  navHolder: PropTypes.node,
  folderData: PropTypes.shape({
    files: PropTypes.array,
    folders: PropTypes.array,
    parentId: PropTypes.string,
  }),
  loading: PropTypes.oneOf(["loading_data", "loading_rename"]),
  setFolderData: PropTypes.func,
  onRename: PropTypes.func,
  downloadFolder: PropTypes.func,
};

export default ReportsList;
