import { Notification } from "@cea/ui-kit";

import {
  FETCH_PROJECT_FACTORIES,
  FETCH_PROJECT_SUPPLIERS,
  FETCH_PROJECT_WORKSHOP,
  FETCH_QA_PROJECT,
  FETCH_SUB_PROJECTS,
} from "../../../queries/qaServices";
import { getNotification } from "../../../utils";

const fetchProject = async ({ apolloClient, projectId }) => {
  try {
    const { data } = await apolloClient.query({
      query: FETCH_QA_PROJECT,
      variables: {
        PID: +projectId,
      },
    });

    return {
      ...data,
    };
  } catch (error) {
    const message = getNotification(error);

    Notification.error({
      message,
    });
  }
};

export const fetchSuppliers = async ({ apolloClient, variables = {} }) => {
  try {
    const { data } = await apolloClient.query({
      query: FETCH_PROJECT_SUPPLIERS,
      variables,
    });

    if (data?.getSuppliers?.length) {
      return data.getSuppliers;
    }
  } catch (error) {
    const message = getNotification(error);

    Notification.error({
      message,
    });
  }
};

export const fetchFactories = async ({ apolloClient, variables = {} }) => {
  try {
    const { data } = await apolloClient.query({
      query: FETCH_PROJECT_FACTORIES,
      variables,
      fetchPolicy: "network-only",
    });

    if (data?.getFactories?.length) {
      return data.getFactories;
    }
  } catch (error) {
    const message = getNotification(error);

    Notification.error({
      message,
    });
  }
};

export const fetchWorkshops = async ({ apolloClient, variables = {} }) => {
  try {
    const { data } = await apolloClient.query({
      query: FETCH_PROJECT_WORKSHOP,
      variables,
      fetchPolicy: "network-only",
    });

    if (data?.getWorkshops?.length) {
      return data.getWorkshops;
    }
  } catch (error) {
    const message = getNotification(error);

    Notification.error({
      message,
    });
  }
};

export const fetchSubProjects = async ({ apolloClient, variables = {} }) => {
  try {
    const { data } = await apolloClient.query({
      query: FETCH_SUB_PROJECTS,
      variables,
    });

    if (data?.qaSubProjects?.data.length) {
      return data.qaSubProjects.data;
    }
  } catch (error) {
    const message = getNotification(error);

    Notification.error({
      message,
    });
  }
};

export default fetchProject;
