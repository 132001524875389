import "./App.css";

import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Login from "./views/Login";
import Auth from "./components/Auth";
import Services from "./views/Services";
import Appshell from "./components/Appshell";
import UserDetails from "./views/UserDetails";
import ComingSoon from "./components/ComingSoon";
import CreateService from "./views/CreateService";
import Organizations from "./views/Organizations";
import ProtectedRoute from "./components/ProtectedRoute";
import CreateSubscription from "./views/CreateSubscription";
import OrganizationDetails from "./views/OrganizationDetails";
import GenericServiceDetails from "./views/GenericServiceDetails";
import ThemeContext from "./contexts/ThemeContext";
import SelectOrganization from "./views/SelectOrganization";
// QA Routes
import QAReportsList from "./views/QAService/QAReportsList";
import QAServiceDetail from "./views/QAService/QAServiceDetail";
import QAProjectDetails from "./views/QAService/QAProjectDetails";
import OrganizationHolder from "./components/OrganizationHolder/OrganizationHolder";
import QASummaryReportsListView from "./views/QAService/QAReportsList/QASummaryReportsListView";
// MI Routes
import PVSMIPServiceDetail from "./views/PVSMIPService/PVSMIPServiceDetail";
import ESSSMIPServiceDetail from "./views/ESSSMIPService/ESSSMIPServiceDetail";
import PVPFTServiceDetail from "./views/PVPFTService/PVPFTServiceDetail";
import EBRServiceDetail from "./views/EBRService/EBRServiceDetail";
import SCMServiceDetail from "./views/SCMService/SCMServiceDetail/SCMServiceDetail";
import CRMIServiceDetail from "./views/CRMIService/CRMIServiceDetail/CRMIServiceDetail";
import QAClientFileShare from "./views/QAService/QAReportsList/QAClientFileShare";
import ESSPFRServiceDetail from "./views/ESSPFRService/ESSPFRServiceDetail";
import ESSSTPRServiceDetail from "./views/ESSSTPRService/ESSSTPRServiceDetail";
import GH2SMIPServiceDetail from "./views/GH2SMIPService/GH2SMIPServiceDetail";
import GH2STPRServiceDetail from "./views/GH2STPRService/GH2STPRServiceDetail";
import PVSTPRServiceDetail from "./views/PVSTPRService/PVSTPRServiceDetail";
// EL Routes
import ELInspectionsServiceDetail from "./views/ELInspectionsService/ELInspectionsServiceDetail";

const HomePage = () => <Appshell>Home</Appshell>;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const apolloClient = useApolloClient();

  return (
    <Auth client={apolloClient}>
      <AuthenticatedTemplate>
        <ThemeContext.Provider value={{ collapsed, setCollapsed }}>
          <OrganizationHolder client={apolloClient}>
            <Routes>
              <Route exact path="login" element={<Login />} />
              <Route exact path="user" element={<UserDetails />} />

              <Route element={<ProtectedRoute />}>
                <Route
                  exact
                  path="contact-team"
                  element={
                    <Appshell>
                      <ComingSoon />
                    </Appshell>
                  }
                />
                <Route
                  exact
                  path="version-changelog"
                  element={
                    <Appshell>
                      <ComingSoon />
                    </Appshell>
                  }
                />
                <Route index exact path="/" element={<HomePage />} />
                <Route exact path="organizations" element={<Organizations />} />
                <Route
                  exact
                  path="organizations/create"
                  element={<OrganizationDetails />}
                />
                <Route exact path="services" element={<Services />} />
                <Route
                  exact
                  path="services/:serviceId/select-organization"
                  element={<SelectOrganization />}
                />
                <Route
                  exact
                  path="organizations/:organizationId"
                  element={<OrganizationDetails />}
                />
                <Route
                  exact
                  path="organizations/:organizationId/services/:serviceId"
                  element={<GenericServiceDetails />}
                />
                <Route
                  exact
                  path="organizations/:organizationId/services/:serviceId/:serviceLine"
                  element={<QAClientFileShare />}
                />
                <Route
                  path="organizations/:organizationId/services/qa"
                  element={<QAServiceDetail />}
                />
                <Route
                  path="organizations/:organizationId/services/qa/projects/:projectId"
                  element={<QAProjectDetails />}
                />
                <Route
                  exact
                  path="organizations/:organizationId/services/qa/projects/:projectId/subProjects/:subProjectId/reports"
                  element={<QAReportsList />}
                />
                <Route
                  exact
                  path="organizations/:organizationId/services/qa/projects/:projectId/reports/:serviceLine"
                  element={<QASummaryReportsListView />}
                />
                <Route
                  path="services/pvSmip"
                  element={<PVSMIPServiceDetail />}
                />
                <Route
                  path="services/essSmip"
                  element={<ESSSMIPServiceDetail />}
                />
                <Route path="services/pvPft" element={<PVPFTServiceDetail />} />
                <Route
                  path="organizations/:organizationId/services/ebr"
                  element={<EBRServiceDetail />}
                />
                <Route
                  path="organizations/:organizationId/services/scm"
                  element={<SCMServiceDetail />}
                />
                <Route
                  path="organizations/:organizationId/services/crmi"
                  element={<CRMIServiceDetail />}
                />
                <Route
                  path="organizations/:id/subscriptions/create"
                  element={<CreateSubscription />}
                />
                <Route
                  path="organizations/:organizationId/subscriptions/:subscriptionId"
                  element={<CreateSubscription />}
                />
                <Route path="services/create" element={<CreateService />} />
                <Route path="services/:serviceId" element={<CreateService />} />
                <Route
                  path="services/essPfr"
                  element={<ESSPFRServiceDetail />}
                />
                <Route
                  path="services/essStpr"
                  element={<ESSSTPRServiceDetail />}
                />
                <Route
                  path="services/gh2Smip"
                  element={<GH2SMIPServiceDetail />}
                />
                <Route
                  path="services/gh2Stpr"
                  element={<GH2STPRServiceDetail />}
                />
                <Route
                  path="services/pvStpr"
                  element={<PVSTPRServiceDetail />}
                />
                <Route
                  path="services/elInspections"
                  element={<ELInspectionsServiceDetail />}
                />
              </Route>
            </Routes>
          </OrganizationHolder>
        </ThemeContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route exact path="login" element={<Login />} />
        </Routes>
      </UnauthenticatedTemplate>
    </Auth>
  );
};

export default App;
