import PropTypes from "prop-types";

const BarChart = ({ size = 36, fill = "none" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1065 29.2503H5.46366V4.17885C5.46366 4.00206 5.31902 3.85742 5.14223 3.85742H2.89223C2.71544 3.85742 2.5708 4.00206 2.5708 4.17885V31.8217C2.5708 31.9985 2.71544 32.1431 2.89223 32.1431H33.1065C33.2833 32.1431 33.4279 31.9985 33.4279 31.8217V29.5717C33.4279 29.3949 33.2833 29.2503 33.1065 29.2503ZM8.99937 26.036H11.2494C11.4262 26.036 11.5708 25.8914 11.5708 25.7146V19.9289C11.5708 19.7521 11.4262 19.6074 11.2494 19.6074H8.99937C8.82259 19.6074 8.67794 19.7521 8.67794 19.9289V25.7146C8.67794 25.8914 8.82259 26.036 8.99937 26.036ZM15.1065 26.036H17.3565C17.5333 26.036 17.6779 25.8914 17.6779 25.7146V12.8574C17.6779 12.6806 17.5333 12.536 17.3565 12.536H15.1065C14.9297 12.536 14.7851 12.6806 14.7851 12.8574V25.7146C14.7851 25.8914 14.9297 26.036 15.1065 26.036ZM21.2137 26.036H23.4637C23.6404 26.036 23.7851 25.8914 23.7851 25.7146V15.9914C23.7851 15.8146 23.6404 15.6699 23.4637 15.6699H21.2137C21.0369 15.6699 20.8922 15.8146 20.8922 15.9914V25.7146C20.8922 25.8914 21.0369 26.036 21.2137 26.036ZM27.3208 26.036H29.5708C29.7476 26.036 29.8922 25.8914 29.8922 25.7146V9.64314C29.8922 9.46635 29.7476 9.32171 29.5708 9.32171H27.3208C27.144 9.32171 26.9994 9.46635 26.9994 9.64314V25.7146C26.9994 25.8914 27.144 26.036 27.3208 26.036Z"
      fill={fill}
      fillOpacity="0.85"
    />
  </svg>
);

BarChart.defaultProps = {
  size: 36,
  fill: "black",
};

BarChart.prototype = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default BarChart;
